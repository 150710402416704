import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Sidebar, Sidenav, Nav, Dropdown } from 'rsuite'

import {
    logout,
    setCurrentUser,
    setModalOpen,
    setSessionStage,
} from '../../../Redux/Actions'

import './Sidebar.css'
import './Lines.css'

const SideDropDown = ({ evKey, mainRoute, title, options, id }) => {
    return (
        <Dropdown
            id={id}
            eventKey={evKey}
            toggleClassName={`sidenav-toggle ${
                location.pathname.split('/')[1] === mainRoute
                    ? 'active-toggle'
                    : ''
            }`}
            className="sidenav-group-dropdown"
            title={title}
        >
            <Nav>
                {options.map((item) => (
                    <Nav.Item
                        key={item.route}
                        href={'/' + mainRoute + '/' + item.route}
                        className={`nav-item ${
                            location.pathname.split('/')[2] &&
                            location.pathname.split('/')[2] === item.route
                                ? 'active-item'
                                : ''
                        }`}
                        onClick={(ev) => {
                            ev.preventDefault()
                            item.onClick()
                        }}
                    >
                        <p className="nav-item-label">{item.title}</p>
                    </Nav.Item>
                ))}
            </Nav>
        </Dropdown>
    )
}

const SidenavNew = ({
    setCurrentUser,
    isAdmin,
    setModalOpen,
    history,
    dispatch,
    location,
    states,
}) => {
    const [openKeys, setOpenKeys] = useState([])

    useEffect(() => {
        const mainRoute = location.pathname.split('/')[1]
        if (mainRoute === 'system_monitoring') {
            setOpenKeys(['1'])
        } else if (mainRoute === 'retraining') {
            setOpenKeys(['4'])
        } else {
            setOpenKeys([])
        }
    }, [location])

    useEffect(() => {
        setCurrentUser()
    }, [])

    const handleRedirect = (pathname) => {
        const curr = location.pathname.split('/')[1]

        if (pathname === curr) {
            return
        } else if (pathname === 'robots') {
            const { robot } = states

            robot
                ? history.push('/' + pathname + '/' + robot)
                : history.push('/' + pathname)
        } else if (pathname === 'web_operators') {
            const { webOperator } = states

            webOperator
                ? history.push('/' + pathname + '/' + webOperator)
                : history.push('/' + pathname)
        } else if (pathname === 'watchtower') {
            let toPush = '/' + pathname

            const { watchtower } = states
            const { robot, file } = watchtower

            if (robot) {
                toPush += '/' + robot
                file && (toPush += '/' + file)
            }

            history.push(toPush)
        } else if (pathname === 'retraining/data_session') {
            let toPush = '/' + pathname

            const { dataSession } = states
            const { file, robot, session } = dataSession

            robot && (toPush += '/robot=' + robot)
            session && (toPush += '/id=' + session)
            file && (toPush += '/file=' + file.replace(/\//g, '%2f'))

            history.push(toPush)
        } else {
            history.push('/' + pathname)
        }
    }

    return (
        <div>
            <Sidebar className="sidenav-sidebar">
                <Sidenav
                    expanded={true}
                    appearance="subtle"
                    openKeys={openKeys}
                    onOpenChange={(ev) => {
                        setOpenKeys(ev)
                    }}
                >
                    <Sidenav.Body
                        className="sidenav-body custom-scrollbar fixed"
                        style={{
                            direction: 'rtl',
                            zIndex: 10,
                            width: 245,
                        }}
                    >
                        <Nav>
                            <Nav.Item
                                id="NavRobots"
                                href={'/robots'}
                                className={`nav-item ${
                                    location.pathname.split('/')[1] === 'robots'
                                        ? 'active-item'
                                        : ''
                                }`}
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    handleRedirect('robots')
                                }}
                                children={
                                    <p className="nav-item-label">Robots</p>
                                }
                            />
                            <Nav.Item
                                id="NavTasks"
                                href={'/tasks'}
                                className={`nav-item ${
                                    location.pathname.split('/')[1] === 'tasks'
                                        ? 'active-item'
                                        : ''
                                }`}
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    handleRedirect('tasks')
                                }}
                                children={
                                    <p className="nav-item-label">Tasks</p>
                                }
                            />
                            <Nav.Item
                                id="NavWebOperators"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    handleRedirect('web_operators')
                                }}
                                href={'/web_operators'}
                                className={`nav-item ${
                                    location.pathname.split('/')[1] ===
                                    'web_operators'
                                        ? 'active-item'
                                        : ''
                                }`}
                                children={
                                    <p className="nav-item-label">
                                        Web Operators
                                    </p>
                                }
                            />

                            <Nav.Item
                                id="NavWatchtower"
                                href={'/watchtower'}
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    handleRedirect('watchtower')
                                }}
                                className={`nav-item ${
                                    location.pathname.split('/')[1] ===
                                    'watchtower'
                                        ? 'active-item'
                                        : ''
                                }`}
                                children={
                                    <p className="nav-item-label">Watchtower</p>
                                }
                            />
                            <Nav.Item
                                id="RobotSubscription"
                                className="nav-item"
                                href={'/robot_subscription'}
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    setModalOpen()
                                }}
                                children={
                                    <p className="nav-item-label">
                                        Robot Subscriptions
                                    </p>
                                }
                            />
                            {isAdmin && (
                                <SideDropDown
                                    id="NavSystemMonitoring"
                                    evKey="1"
                                    mainRoute="system_monitoring"
                                    title="System Monitoring"
                                    options={[
                                        {
                                            route: 'processors',
                                            title: 'Processors',
                                            onClick: () => {
                                                handleRedirect(
                                                    'system_monitoring/processors'
                                                )
                                                dispatch({
                                                    type: 'SET_SYSTEM_MONITORING_ROUTE',
                                                    payload: 'processors',
                                                })
                                            },
                                        },
                                        {
                                            route: 'connections',
                                            title: 'Connections',
                                            onClick: () => {
                                                handleRedirect(
                                                    'system_monitoring/connections'
                                                )
                                                dispatch({
                                                    type: 'SET_SYSTEM_MONITORING_ROUTE',
                                                    payload: 'connections',
                                                })
                                            },
                                        },
                                    ]}
                                />
                            )}

                            {isAdmin && (
                                <SideDropDown
                                    id="NavRetraining"
                                    evKey="4"
                                    mainRoute="retraining"
                                    title="Retraining"
                                    options={[
                                        {
                                            route: 'data_session',
                                            title: 'Data Sessions',
                                            onClick: () => {
                                                handleRedirect(
                                                    'retraining/data_session'
                                                )
                                                dispatch(
                                                    setSessionStage(
                                                        'data_session'
                                                    )
                                                )
                                            },
                                        },
                                        {
                                            route: 'retraining_session',
                                            title: 'Retraining Session',
                                            onClick: () => {
                                                handleRedirect(
                                                    'retraining/retraining_session'
                                                )
                                                dispatch(
                                                    setSessionStage(
                                                        'retraining_session'
                                                    )
                                                )
                                            },
                                        },
                                    ]}
                                />
                            )}
                            {isAdmin && (
                                <Nav.Item
                                    id="NavUsers"
                                    href="/users"
                                    onClick={(ev) => {
                                        ev.preventDefault()
                                        handleRedirect('users')
                                    }}
                                    className={`nav-item ${
                                        location.pathname.split('/')[1] ===
                                        'users'
                                            ? 'active-item'
                                            : ''
                                    }`}
                                    children={
                                        <p className="nav-item-label">
                                            User Managment
                                        </p>
                                    }
                                />
                            )}
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </Sidebar>
        </div>
    )
}

function mapStateToProps(state) {
    const { fileToView, retrainingRobot } = state.data_session

    const { currentSession } = state.retraining_session
    const { selected, operator } = state.webOperator
    const { currentPlayable, robot } = state.playlist

    return {
        trainStage: state.data_session.sessionStage,
        system_route: state.processor.systemMonitoringRoute,
        states: {
            robot: state.robot.robot,
            webOperator:
                selected && operator ? operator + '&robot=' + selected : null,
            watchtower: {
                robot: robot,
                file:
                    currentPlayable && currentPlayable.path
                        ? currentPlayable.path.replace(/\//g, '%2f')
                        : null,
            },
            dataSession: {
                robot: retrainingRobot,
                file: fileToView ? fileToView.path : null,
                session: currentSession
                    ? currentSession.training_session
                    : null,
            },
        },
        isAdmin: state.user.isAdmin,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout()),
        setCurrentUser: () => dispatch(setCurrentUser()),
        setModalOpen: () => dispatch(setModalOpen(true)),
        dispatch: (f) => dispatch(f),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SidenavNew))
