import axios from 'axios'
import { endpoints, urls } from '../../Utils'

export const authAuthorizationAndRefresh = (access_token, refresh_token) => ({
    type: 'AUTH_AND_REFRESH',
    payload: { access_token, refresh_token },
})

export const logout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    return {
        type: 'LOGOUT',
    }
}

export const autoLogin = () => (dispatch) => {
    const access_token = localStorage.getItem('access_token')
    const refresh_token = localStorage.getItem('refresh_token')
    if (!access_token && !refresh_token) {
        dispatch(logout())
        return false
    } else {
        dispatch(authAuthorizationAndRefresh(access_token, refresh_token))
        return { Authorization: access_token, Refersh: refresh_token }
    }
}

export const auth = (username, password) => (dispatch) =>
    axios
        .post(urls.apiHost + endpoints.login, { username, password })
        .then(({ data }) => {
            const { refresh_token, access_token } = data
            localStorage.setItem('access_token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            dispatch(authAuthorizationAndRefresh(access_token, refresh_token))
        })

export const checkLoginAndRefreshOrLogout = (headers) => (dispatch) =>
    axios
        .post(urls.apiHost + endpoints.refresh, null, { headers })
        .then(({ data }) => {
            const { access_token, refresh_token } = data
            if (access_token && refresh_token) {
                localStorage.setItem('access_token', access_token)
                localStorage.setItem('refresh_token', refresh_token)
                dispatch(
                    authAuthorizationAndRefresh(access_token, refresh_token)
                )
                return data
            } else {
                dispatch(logout())
            }
        })
        .catch(() => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            dispatch(logout())
            throw new Error('Failed refresh')
        })
