import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    Modal,
    Button,
    Table,
    Toggle,
    Tooltip,
    Whisper,
    IconButton,
    Icon,
    Notification,
} from 'rsuite'

import { get, post, put, remove } from './../../../Axios/index'
import { CustomCell, CustomHeaderRow } from '../../UtilComponents'
import DagRunsModal from './DagRunsModal'
const { Column, HeaderCell, Cell } = Table

const AirflowBody = ({
    currentSession,
    dispatch,
    headers,
    setMode,
    onClose,
}) => {
    const [address, setAddress] = useState(localStorage.getItem('address'))
    const [dagList, setDagList] = useState([])
    const [loading, setLoading] = useState(true)
    const [viewDagRuns, setViewDagRuns] = useState(null)

    const getDagList = () =>
        get(
            '/sessions/train',
            { url: address + '/api/v1/dags' },
            headers,
            dispatch
        ).then(({ data }) => {
            setDagList(data.answer.dags)
            setLoading(false)
        })

    useEffect(() => {
        getDagList()
        let interval = setInterval(() => getDagList(), 2000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const dagPauseHandler = (paused, id) => {
        setLoading(true)
        const url = address + '/api/v1/dags/' + id + '?update_mask=is_paused'
        const data = {
            is_paused: paused,
        }
        put(
            '/sessions/train',
            data,
            { url },
            headers,
            dispatch,
            true,
            true
        ).then(() => {
            getDagList()
        })
    }

    const DagRunButton = ({ rowData }) => {
        return (
            <div className="table-cell-wrapper">
                <Whisper
                    placement="left"
                    trigger="hover"
                    delayHide={3}
                    speaker={<Tooltip>{`Run DAG`}</Tooltip>}
                >
                    <IconButton
                        disabled={rowData.is_paused}
                        size="sm"
                        circle
                        onClick={() => {
                            const url =
                                address +
                                '/api/v1/dags/' +
                                rowData.dag_id +
                                '/dagRuns'
                            const data = {
                                conf: currentSession,
                            }

                            post(
                                '/sessions/train',
                                data,
                                { url },
                                headers,
                                dispatch
                            ).then(() => {
                                Notification.success({
                                    title: 'Dag run successfully started',
                                    placement: 'bottomStart',
                                })
                            })
                        }}
                        icon={<Icon icon="play" />}
                    />
                </Whisper>
            </div>
        )
    }

    const DagViewRunsBtn = ({ rowData }) => {
        return (
            <div className="table-cell-wrapper">
                <Whisper
                    placement="left"
                    trigger="hover"
                    delayHide={3}
                    speaker={<Tooltip>{`View DAG runs`}</Tooltip>}
                >
                    <IconButton
                        size="sm"
                        circle
                        onClick={() => setViewDagRuns(rowData.dag_id)}
                        icon={<Icon icon="search" />}
                    />
                </Whisper>
            </div>
        )
    }

    const DagPauseToggle = ({ rowData }) => {
        return (
            <div className="table-cell-wrapper">
                <Whisper
                    placement="left"
                    trigger="hover"
                    delayHide={3}
                    speaker={
                        <Tooltip>
                            {`Click to ${
                                rowData.is_paused
                                    ? 'unpause dag'
                                    : 'put dag on pause'
                            }`}
                        </Tooltip>
                    }
                >
                    <Toggle
                        onChange={() =>
                            dagPauseHandler(!rowData.is_paused, rowData.dag_id)
                        }
                        checked={!rowData.is_paused}
                    />
                </Whisper>
            </div>
        )
    }

    return (
        <>
            <Modal.Header closeButton={false}>
                <h4>Retraining</h4>
            </Modal.Header>
            <Modal.Body>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex', padding: 7 }}>
                        <h6>{'Address: ' + address}</h6>
                        <div className="spacer" />

                        <Button
                            className="dag-link"
                            href={'http://airflow.rembrain.ai:6006'}
                            target="_blank"
                        >
                            Tensor Board
                        </Button>
                    </div>
                    <CustomHeaderRow
                        paddingStyle={{ paddingRight: 105 }}
                        cells={[
                            { label: 'ID' },
                            { label: 'Active', width: 50 },
                            { label: 'Pause/Unpause' },
                        ]}
                    />
                    <div
                        className="custom-scrollbar"
                        style={{ maxHeight: '40vh', overflowY: 'auto' }}
                    >
                        <Table
                            loading={loading}
                            autoHeight
                            showHeader={false}
                            data={dagList}
                        >
                            <Column align="center" flexGrow={1}>
                                <HeaderCell></HeaderCell>
                                <CustomCell dataKey="dag_id" />
                            </Column>
                            <Column align="center" width={50}>
                                <HeaderCell></HeaderCell>
                                <CustomCell dataKey="is_active" />
                            </Column>

                            <Column align="center" flexGrow={1}>
                                <HeaderCell></HeaderCell>
                                <Cell dataKey="datetime">
                                    {(rowData) => (
                                        <DagPauseToggle rowData={rowData} />
                                    )}
                                </Cell>
                            </Column>
                            <Column align="center" width={50}>
                                <HeaderCell></HeaderCell>
                                <Cell dataKey="datetime">
                                    {(rowData) => (
                                        <DagRunButton rowData={rowData} />
                                    )}
                                </Cell>
                            </Column>
                            <Column align="center" width={50}>
                                <HeaderCell></HeaderCell>
                                <Cell dataKey="datetime">
                                    {(rowData) => (
                                        <DagViewRunsBtn rowData={rowData} />
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                    </div>
                </div>
                <DagRunsModal
                    viewDagRuns={viewDagRuns}
                    onClose={() => setViewDagRuns(null)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        remove('/sessions/train_user', null, headers, dispatch)
                        setMode('session')
                    }}
                >
                    Retraining Logout
                </Button>
                <Button onClick={() => setMode('session')}>
                    Back to session
                </Button>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        currentSession: state.retraining_session.currentSession,
        headers: state.auth,
    }
}

export default connect(mapStateToProps)(AirflowBody)
/*
<Button
                            className="dag-link"
                            href={
                                'https://airflow.rembrain.ai:8080/graph?dag_id=' +
                                dagId
                            }
                            target="_blank"
                        >
                            Airflow graph
                        </Button>

                        const startDag = async (dagId) => {
        try {
            if (
                address === '' ||
                username === '' ||
                password === '' ||
                dagId === ''
            ) {
                notifyWithOutError(
                    'Some data is not filled in',
                    'Some data is not filled in'
                )
                return false
            }
            const getDags = await axios.get(address + '/api/v1/dags', {
                auth: {
                    username: username,
                    password: password,
                },
            })
            const listDag = getDags.data.dags
            for (let i = 0; i < listDag.length; i++) {
                if (listDag[i].dag_id === dagId) {
                    if (listDag[i].is_paused === false) {
                        await axios.post(
                            address + '/api/v1/dags/' + dagId + '/dagRuns',
                            {
                                conf: currentSession,
                            },
                            {
                                auth: {
                                    username: username,
                                    password: password,
                                },
                            }
                        )
                        return true
                    } else {
                        await axios.patch(
                            address +
                                '/api/v1/dags/' +
                                dagId +
                                '?update_mask=is_paused',
                            {
                                is_paused: false,
                            },
                            {
                                auth: {
                                    username: username,
                                    password: password,
                                },
                            }
                        )
                        await axios.post(
                            address + '/api/v1/dags/' + dagId + '/dagRuns',
                            {
                                conf: currentSession,
                            },
                            {
                                auth: {
                                    username: username,
                                    password: password,
                                },
                            }
                        )
                        return true
                    }
                }
            }
            notifyWithOutError('dag_id not found', `dag_id not found`)
            return false
        } catch (error) {
            if (!error) {
                if (error.response.status === 401) {
                    notifyWithOutError(
                        401,
                        `GET request to ${address} failed. UNAUTHORIZED`
                    )
                } else {
                    notifyWithOutError(
                        error.response.status,
                        `GET request to ${address} failed.`
                    )
                }
            }
        }
    }
*/
