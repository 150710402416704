import { connect } from 'react-redux'

import './EventItem.css'
import { formTime } from '../Slider'
import { JsonEditor } from '../../../UtilComponents'

const EventItem = ({ position, item, layout, leftPartWidth }) => {
    return (
        <div className="watcher-event-item watcher-container" id={item.time}>
            <div
                className={`watcher-event-item-header ${
                    item.time <= position
                        ? 'watcher-event-item-active'
                        : 'watcher-event-item-inactive'
                }`}
            >
                {formTime(item.time.toFixed(0))}
            </div>
            <div className="watcher-event-item-body">
                <JsonEditor
                    switcher={item}
                    style={{
                        width:
                            layout !== 'mobile' ? leftPartWidth - 170 : '60vw',
                    }}
                    readonly={true}
                    value={item.val.status}
                />
            </div>
        </div>
    )
}
/*

*/

const mapStateToProps = (state) => {
    return {
        position: state.slider.position,
    }
}

export default connect(mapStateToProps)(EventItem)
