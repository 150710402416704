import _ from 'lodash'
import numbers from 'numbers'

const { dot, cross, multiply, norm, divide, add, inv, subtract, sqrt } =
    numbers.calculus

export const drawBox = (box, context, color) => {
    drawLine(box[0], box[1], context, color)
    drawLine(box[1], box[2], context, color)
    drawLine(box[2], box[3], context, color)
    drawLine(box[0], box[3], context, color)

    drawLine(box[4], box[5], context, color)
    drawLine(box[5], box[6], context, color)
    drawLine(box[6], box[7], context, color)
    drawLine(box[4], box[7], context, color)

    drawLine(box[0], box[4], context, color)
    drawLine(box[1], box[5], context, color)
    drawLine(box[2], box[6], context, color)
    drawLine(box[3], box[7], context, color)

    box.forEach((point) => {
        drawPoint(point, context, color, 3)
    })
}

export const drawPoint = (point, ctx, color = 'black', size = 3) => {
    if (!point) {
        return
    }
    const [x, y] = point
    ctx.beginPath()
    ctx.arc(x, y, size, Math.PI / 2, true)
    ctx.fillStyle = color
    ctx.fill()
}

export const drawPolygon = (polygon, context, color) => {
    polygon.forEach((point) => {
        if (point) {
            const nextPoint =
                polygon[polygon.findIndex((pt) => _.isEqual(pt, point)) + 1]
            if (nextPoint) {
                drawLine(point, nextPoint, context, color)
            } else {
                polygon.findIndex((pt) => _.isEqual(pt, point)) === 3 &&
                    drawLine(point, polygon[0], context, color)
            }
            drawPoint(point, context, color)
        }
    })
}

export const drawLine = (p1, p2, ctx, color = 'crimson') => {
    if (!p1 || !p2) {
        return
    }
    const [x1, y1] = p1
    const [x2, y2] = p2
    ctx.beginPath()
    ctx.lineWidth = 3
    ctx.strokeStyle = color
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
}

export const cameraToRobot = (point, calibration) =>
    multiply(calibration, [...point, 1.0])

export const vectorLength = (V) => sqrt(V[0] ** 2 + V[1] ** 2 + V[2] ** 2)

export const pictureToCamera = (point, status) => {
    const { ppx, ppy, fx, fy } = status
    const [x, y, z] = point
    return [((x - ppx) / fx) * z, ((y - ppy) / fy) * z, z]
}
export const cameraToPicture = (point, status, normal, depth = 0) => {
    const { fx, fy, ppx, ppy } = status
    const [x, y, z] = point
    const zNew = depth ? z + normal[2] * depth : z
    const xNew = ((x + normal[0] * depth) * fx) / zNew + ppx
    const yNew = ((y + normal[1] * depth) * fy) / zNew + ppy
    return [xNew, yNew, zNew]
}

export const normalizeToRectangle = (polygon, normal) => {
    const [p1, p2, p3] = polygon
    let oy = cross(subtract(p2, p1), normal)
    oy = divide(oy, norm(oy))
    const l1 = Math.abs(dot(subtract(p3, p2), oy))
    let newP3
    if (dot(subtract(p3, p2), oy) > 0) {
        newP3 = add(p2, multiply(l1, oy))
    } else {
        newP3 = add(p2, multiply(-l1, oy))
    }

    const newP4 = add(newP3, subtract(p1, p2))
    return [p1, p2, newP3, newP4]
}

export const getAverageDepth = (point, depth, cropRadius, wh) => {
    const [x, y] = point
    if (x > wh[0] || y > wh[1] || x < 0 || y < 0) {
        return 'err'
    }
    let cropX = x - cropRadius / 2
    if (cropX < 0) cropX = 0
    let cropY = y - cropRadius / 2
    if (cropY < 0) cropY = 0
    let cropW = cropX + cropRadius < wh[0] ? cropRadius : wh[0] - cropX
    let cropH = cropY + cropRadius < wh[1] ? cropRadius : wh[1] - cropY
    let depthCrop = depth.crop({
        x: cropX,
        y: cropY,
        width: cropW,
        height: cropH,
    })
    let size = 0
    let res = 0
    for (let i = 0; i < depthCrop.size; i++) {
        const value = depthCrop.getPixel(i)[0]
        if (value !== 0) {
            res += depthCrop.getPixel(i)[0]
            size++
        }
    }
    if (size > 0) return res / size
    else return 'err'
}

export const completeBin = (binPoinst) => {
    const [p0, p1, p2, p3] = binPoinst
    return [
        p0,
        p1,
        add(p1, subtract(p2, p0)),
        p2,
        p3,
        add(p3, subtract(p1, p0)),
        add(add(p3, subtract(p1, p0)), subtract(p2, p0)),
        add(p3, subtract(p2, p0)),
    ]
}

export const pictureToRobot = (point, status, calibration) => {
    const { fx, ppx, fy, ppy } = status
    const x = ((point[0] - ppx) * point[2]) / fx
    const y = ((point[1] - ppy) * point[2]) / fy
    const p = [x, y, point[2], 1.0]
    const converted = multiply(calibration, p)
    return converted
}

export const robotToCamera = (point, calibration) => {
    let newCalibration = inv([...calibration, [0, 0, 0, 1]])
    const p = [...point, 1.0]
    return multiply(newCalibration, p).slice(0, 3)
}

export const robotToPicture = (point, status, calibration) => {
    const { fx, ppx, fy, ppy } = status
    let newCalibration = inv([...calibration, [0, 0, 0, 1]])
    const p = [...point, 1.0]
    const converted = multiply(newCalibration, p)
    if (converted[2] === 0) return [ppx, ppy]
    const x = (converted[0] / converted[2]) * fx + ppx
    const y = (converted[1] / converted[2]) * fy + ppy
    return [x, y]
}
