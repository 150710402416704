import React from 'react'
import { Row, Grid } from 'rsuite'
import { connect } from 'react-redux'

import DataSession from './DataSession'
import RetrainingSession from './RetrainingSession'

const Retraining = ({ sessionStage, isAdmin }) => {
    return (
        <>
            {isAdmin ? (
                <>
                    {sessionStage === 'retraining_session' ? (
                        <RetrainingSession />
                    ) : (
                        <DataSession />
                    )}
                </>
            ) : (
                <Grid className="main-layout-grid" fluid>
                    <Row>
                        <div className="not-allowed-wrapper margin-center-h">
                            <h3>Not enough rights for this path</h3>
                        </div>
                    </Row>
                </Grid>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    const { sessionStage } = state.data_session
    return {
        sessionStage,
        isAdmin: state.user.isAdmin,
    }
}

export default connect(mapStateToProps)(Retraining)
