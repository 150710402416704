import React from 'react'
import { connect } from 'react-redux'
import { Button, Checkbox, Modal } from 'rsuite'
import {
    setAllowSliding,
    setPlaybackSpeed,
    setSettingsModalOpen,
    setShowCameraDebug,
} from '../../actions'

import './SettingsModal.css'

const SettingsModal = ({
    settingsModal,
    dispatch,
    playbackSpeed,
    allowSliding,
    showCameraDebug,
}) => {
    return (
        <Modal
            show={settingsModal}
            onHide={() => dispatch(setSettingsModalOpen(false))}
        >
            <Modal.Header closeButton={false}>
                <h4>Settings</h4>
            </Modal.Header>
            <Modal.Body>
                <div className="watcher-settings-header">
                    <h3>Playback Speed</h3>
                    <div className="watcher-speed-container">
                        {[0.2, 0.5, 1, 1.5, 2].map((opt) => (
                            <div
                                key={'speed_' + opt}
                                style={{ cursor: 'pointer' }}
                                className={`non-selectable watcher-speed-option ${
                                    opt === playbackSpeed
                                        ? 'watcher-active-speed'
                                        : ''
                                }`}
                                onClick={() => {
                                    dispatch(setPlaybackSpeed(opt))
                                }}
                            >
                                {opt}
                            </div>
                        ))}
                    </div>
                    <div className="watcher-settings-separator" />
                </div>
                <div className="watcher-settings-header">
                    <div className="watcher-setting-container">
                        <h4>Allow command auto-scrolling</h4>
                        <Checkbox
                            checked={!!allowSliding}
                            onChange={() => {
                                dispatch(setAllowSliding(!allowSliding))
                            }}
                        />
                    </div>
                    <div className="watcher-settings-separator" />
                </div>

                <div className="watcher-settings-header">
                    <div className="watcher-setting-container">
                        <h4>Show camera debug info</h4>
                        <Checkbox
                            checked={!!showCameraDebug}
                            onChange={() => {
                                dispatch(setShowCameraDebug(!showCameraDebug))
                            }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => dispatch(setSettingsModalOpen(false))}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { settingsModal, playbackSpeed, allowSliding, showCameraDebug } =
        state.utils
    return {
        settingsModal,
        playbackSpeed,
        allowSliding,
        showCameraDebug,
    }
}

export default connect(mapStateToProps)(SettingsModal)
