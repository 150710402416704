import axios from 'axios'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Loader } from 'rsuite'

import { get } from '../../../../Axios'
import { endpoints } from '../../../../Utils'
import {
    setCommands,
    setFrames,
    setIsLoading,
    setLayoutType,
    setPlayerState,
    setCameraDebug,
} from '../../actions'
import {
    Player,
    Info,
    EventList,
    SideBar,
    SettingsModal,
} from '../../components'

import './MainLayout.css'

const handleData = (data) => {
    const len = new Uint32Array(data.slice(0, 12))[0]
    const jsonStart = 4
    const jsonEnd = 4 + len

    let frames = JSON.parse(
        new TextDecoder().decode(new Uint8Array(data.slice(jsonStart, jsonEnd)))
    )

    let byteCursor = jsonEnd
    frames.forEach((frame, i) => {
        const { length: frameLen } = frame
        let buf = data.slice(byteCursor, byteCursor + frameLen)
        frame.val = parseFrameValue(frame, buf)
        byteCursor += frame.length
    })
    return frames
}

const parseFrameValue = (frame, buf) => {
    if (frame.exchange === 'state' || frame.exchange === 'command') {
        return { status: JSON.parse(new TextDecoder().decode(buf)) }
    } else if (frame.exchange === 'camera0') {
        const dataType = new Uint8Array(buf)[0]
        switch (dataType) {
            case 1:
                const [lenImg, lenDepth, lenStatus] = new Uint32Array(
                    buf.slice(1, 13)
                )
                const imageBuffer = buf.slice(13, 13 + lenImg)
                const depthBuffer = buf.slice(
                    13 + lenImg,
                    13 + lenImg + lenDepth
                )
                const statusBuffer = buf.slice(
                    13 + lenImg + lenDepth,
                    13 + lenImg + lenDepth + lenStatus
                )
                let parsedStatus = null
                try {
                    parsedStatus = JSON.parse(
                        new TextDecoder().decode(statusBuffer)
                    )
                } catch {}
                return {
                    img: imageBuffer,
                    depth: depthBuffer,
                    status: parsedStatus,
                }
            case 2:
                const [lenImg2, lenStatus2] = new Uint32Array(buf.slice(1, 9))
                let jpgBuf = buf.slice(9, 9 + lenImg2)
                let status = buf.slice(9 + lenImg2, 9 + lenImg2 + lenStatus2)
                let parsed = null
                try {
                    parsed = JSON.parse(new TextDecoder().decode(status))
                } catch {}
                return {
                    img: jpgBuf,
                    status: parsed,
                }
        }
    }
}

const localTest = false

const Watchtower = ({
    dispatch,
    layout,
    headers,
    currentPlayable,
    isLoading,
}) => {
    const [leftPartWidth, setLeftPartWidth] = useState(null)
    const [controller, setController] = useState(new AbortController())

    const onResize = () => {
        const leftPart = document.getElementById('fixed-left-watchtower')
        if (leftPart) {
            setLeftPartWidth(leftPart.offsetWidth)
        }
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            dispatch(setLayoutType('mobile'))
        } else {
            if (window.innerWidth <= 1200) {
                dispatch(setLayoutType('mobile'))
            }
            if (window.innerWidth > 1200) {
                dispatch(setLayoutType('default'))
            }
        }
    }

    useEffect(() => {
        onResize()
    }, [])

    useEffect(() => {
        dispatch(setFrames([]))
        dispatch(setCommands([]))
        dispatch(setPlayerState('empty'))

        !localTest && currentPlayable && dispatch(setIsLoading(true))

        currentPlayable &&
            get(
                endpoints.stream.archiveStream,
                { path: currentPlayable },
                headers,
                dispatch
            )
                .then((response) => {
                    let url = response.data
                        .replace(/:9000/, '-api.rembrain.ai')
                        .replace(/http/, 'https')

                    fetch(url, {
                        method: 'get',
                        signal: controller.signal,
                    })
                        .then((resp) =>
                            resp.arrayBuffer().then((buffResp) => {
                                const data = buffResp
                                const frames = handleData(data)
                                let startTime = frames[0].time
                                const framesWithTime = frames.map((frame) => ({
                                    ...frame,
                                    time: frame.time - startTime,
                                }))
                                dispatch(
                                    setFrames(
                                        [...framesWithTime].filter(
                                            (fr) => fr.exchange !== 'command'
                                        )
                                    )
                                )
                                const camera = framesWithTime.filter(
                                    (frame) => frame.exchange === 'camera0'
                                )
                                if (camera.length > 0) {
                                    const fps =
                                        camera[camera.length - 1].time /
                                        camera.length

                                    let timeIntArr = []
                                    for (let i = 1; i < camera.length; i++) {
                                        timeIntArr.push(
                                            camera[i].time -
                                                camera[i - 1].time -
                                                fps
                                        )
                                    }
                                    dispatch(
                                        setCameraDebug({
                                            fps: (1 / fps).toFixed(2),
                                            avDelay: (
                                                [...timeIntArr].reduce(
                                                    (a, b) => a + b
                                                ) / timeIntArr.length
                                            ).toFixed(6),
                                        })
                                    )
                                }

                                const commands = [...framesWithTime].filter(
                                    (fr) => fr.exchange === 'command'
                                )

                                dispatch(
                                    setCommands(
                                        commands.length > 0 ? commands : []
                                    )
                                )
                                dispatch(setPlayerState('play'))
                                dispatch(setIsLoading(false))
                            })
                        )
                        .catch((err) => {
                            console.log({ err })
                        })
                })
                .catch((err) => {
                    console.log({ err })
                    dispatch(setIsLoading(false))
                })
    }, [currentPlayable])

    useEffect(() => {
        window.addEventListener('resize', onResize)

        return () => {
            controller.abort()
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return (
        <div>
            {localTest && (
                <input
                    style={{ zIndex: 1000000, position: 'absolute' }}
                    type="file"
                    onChange={(e) => {
                        let reader = new FileReader()
                        reader.onload = (e) => {
                            const data = e.currentTarget.result
                            const frames = handleData(data)
                            let startTime = frames[0].time
                            const framesWithTime = frames.map((frame) => ({
                                ...frame,
                                time: frame.time - startTime,
                            }))
                            dispatch(
                                setFrames(
                                    [...framesWithTime].filter(
                                        (fr) => fr.exchange !== 'command'
                                    )
                                )
                            )

                            const camera = framesWithTime.filter(
                                (frame) => frame.exchange === 'camera0'
                            )
                            const camera1 = framesWithTime.filter(
                                (frame) =>
                                    frame.exchange !== 'camera0' &&
                                    frame.exchange !== 'state'
                            )
                            console.log({ camera1 })
                            if (camera.length > 0) {
                                const fps =
                                    camera[camera.length - 1].time /
                                    camera.length

                                let timeIntArr = []
                                for (let i = 1; i < camera.length; i++) {
                                    timeIntArr.push(
                                        camera[i].time -
                                            camera[i - 1].time -
                                            fps
                                    )
                                }
                                dispatch(
                                    setCameraDebug({
                                        fps: (1 / fps).toFixed(2),
                                        avDelay: (
                                            [...timeIntArr].reduce(
                                                (a, b) => a + b
                                            ) / timeIntArr.length
                                        ).toFixed(6),
                                    })
                                )
                            }

                            const commands = [...framesWithTime].filter(
                                (fr) => fr.exchange === 'command'
                            )
                            dispatch(setCommands(commands))
                            dispatch(setPlayerState('play'))
                            dispatch(setIsLoading(false))
                        }
                        reader.readAsArrayBuffer(e.target.files[0])
                    }}
                />
            )}
            <SettingsModal />
            <SideBar />
            {isLoading && (
                <Loader
                    center
                    vertical
                    backdrop={false}
                    content="Loading..."
                    style={{ zIndex: 1, marginLeft: 245, position: 'fixed' }}
                    size="lg"
                />
            )}
            {layout !== 'default' ? (
                <div className="watcher-mobile-layout margin-center-h">
                    <Player />
                    <Info />
                    <EventList />
                </div>
            ) : (
                <div className="watchtower-wrapper-container">
                    <div className="watcher-main-layout-left custom-scrollbar">
                        <div
                            id="fixed-left-watchtower"
                            className="watcher-fixed-container"
                        >
                            <Player />
                            <Info />
                        </div>
                    </div>

                    <div className="watchtower-main-spacer" />
                    <div className="watcher-main-layout-right">
                        <div
                            className="watcher-fixed-container"
                            style={{ width: leftPartWidth - 150 }}
                        >
                            <EventList leftPartWidth={leftPartWidth} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { startDate, endDate, robot, page, currentPlayable, isLoading } =
        state.playlist
    return {
        layout: state.utils.layout,
        headers: state.auth,
        startDate,
        endDate,
        robot,
        page,
        currentPlayable:
            currentPlayable.path && currentPlayable.path
                ? currentPlayable.path
                : null,
        isLoading,
    }
}

export default connect(mapStateToProps)(Watchtower)
