import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'rsuite'
import { post } from '../../../Axios'
import { getTaskList, pushRobotCommand } from '../../../Redux/Actions'
import { endpoints } from '../../../Utils'

import './TaskRunner.css'

const TaskRunnerModal = ({
    isOpen,
    onClose,
    dispatch,
    taskList,
    robot,
    headers,
}) => {
    useEffect(() => {
        dispatch(getTaskList())
    }, [isOpen])

    const runTask = (task) => {
        post(
            endpoints.web_api.taskToRobot(task.name, robot),
            null,
            null,
            headers,
            dispatch
        ).then(() => {
            dispatch(pushRobotCommand(robot, { op: 'ask_for_ml_continual' }))
            onClose()
        })
    }

    return (
        <Modal
            backdrop={false}
            show={isOpen}
            onHide={onClose}
            onExited={() => {}}
        >
            <Modal.Header closeButton={false}>
                <h4>Select task from list below</h4>
            </Modal.Header>
            <Modal.Body>
                <div
                    className="custom-scrollbar"
                    style={{
                        maxHeight: '50vh',
                        gap: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                    }}
                >
                    {taskList.map((task) => {
                        return (
                            <div
                                onClick={() => runTask(task)}
                                className="task-runner-task-item"
                            >
                                <h5 className="non-selectable">{task.name}</h5>

                                <p className="non-selectable">
                                    {task.task.description}
                                </p>
                            </div>
                        )
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        taskList: state.task.tasks,
        robot: state.robot.robot,
        headers: state.auth,
    }
}

export default connect(mapStateToProps)(TaskRunnerModal)
