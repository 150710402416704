import { get, notifyError, post, put, remove } from '../../Axios'
import { formatDate, endpoints } from '../../Utils'

export const setCurrentUser = () => (dispatch, getState) =>
    get(endpoints.api.currentUser, null, getState().auth, dispatch).then(
        ({ data }) => {
            const currentTheme = localStorage.getItem('theme')
            const { vendor } = data.answer

            if (currentTheme === 'aivero' && vendor !== 'aivero') {
                localStorage.setItem('theme', 'rembrain')
                window.location.reload()
            } else if (currentTheme !== 'aivero' && vendor === 'aivero') {
                localStorage.setItem('theme', 'aivero')
                window.location.reload()
            }
            dispatch({
                type: 'USER_INFO',
                payload: data.answer,
            })
        }
    )

export const getUserList = () => (dispatch, getState) =>
    get(endpoints.api.users, null, getState().auth, dispatch).then(
        ({ data }) => {
            dispatch({
                type: 'GET_USER_LIST',
                payload: data.answer.map((el) => {
                    let date = new Date(el.createdTimestamp)
                    let loc = -new Date().getTimezoneOffset() / 60
                    date = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        date.getHours() + (window.SERVER_OFFSET - loc),
                        date.getMinutes(),
                        date.getSeconds()
                    )
                    return {
                        ...el,
                        createdTime: formatDate(date),
                    }
                }),
            })
        }
    )

export const removeUser = (id) => (dispatch, getState) => {
    remove(endpoints.deleteUser(id), null, getState().auth, dispatch).then(() =>
        dispatch(getUserList())
    )
}

export const editUser = (user) => (dispatch, getState) => {
    const headers = getState().auth
    const postObj = {
        role: user.role ? user.role : 'default',
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        vendor: user.vendor ? user.vendor[0] : '',
    }

    return put(
        endpoints.updateUser(user.id),
        postObj,
        null,
        headers,
        dispatch,
        false
    )
        .then(() => {
            dispatch(getUserList())
            return true
        })
        .catch((err) => {
            const { response } = err
            if (response && response.data && response.data.detail) {
                const { msg } = response.data.detail[0]
                if (msg === 'value is not a valid email address') {
                    notifyError({}, `Invalid Email format`)
                    return false
                } else {
                    notifyError(err, `POST request failed.`)
                }
            } else {
                notifyError(err, `POST request failed.`)
            }
        })
}

export const getUserById = (id) => (dispatch, getState) =>
    get(endpoints.user, { user_id: id }, getState().auth, dispatch).then(
        (resp) =>
            dispatch({
                type: 'SET_USER_TO_EDIT',
                payload: resp.data.answer,
            })
    )

export const createUser = (user) => (dispatch, getState) => {
    return post(
        endpoints.register,
        user,
        null,
        getState().auth,
        dispatch,
        false
    )
        .then(() => {
            dispatch(getUserList())
            return true
        })
        .catch((err) => {
            const { response } = err
            if (response && response.data && response.data.detail) {
                const { msg } = response.data.detail[0]
                if (msg === 'value is not a valid email address') {
                    notifyError({}, `Invalid Email format`)
                    return false
                } else {
                    notifyError(err, `POST request failed.`)
                }
            } else {
                notifyError(err, `POST request to failed.`)
            }
        })
}
