import { get, remove, post, put } from '../../Axios'
import { endpoints } from '../../Utils'

export const getRetrainingSessions = () => (dispatch, getState) =>
    get(endpoints.sessions.sessionsTrain(), null, getState().auth).then(
        ({ data }) => {
            dispatch({
                type: 'GET_RETRAINING_SESSIONS',
                payload: data.answer,
            })
        }
    )

export const deleteRetrainingSession = (sessionID) => (dispatch, getState) =>
    remove(
        endpoints.sessions.sessionsTrain(sessionID),
        null,
        getState().auth,
        dispatch
    ).then(() => {
        dispatch(getRetrainingSessions())
    })

export const removeDataSession = (session) => (dispatch, getState) => {
    const { addedDataSessions } = getState().retraining_session
    const idx = addedDataSessions.findIndex((el) => el.id === session.id)
    dispatch({
        type: 'SET_ADDED_DATA_SESSIONS',
        payload: [
            ...addedDataSessions.slice(0, idx),
            ...addedDataSessions.slice(idx + 1),
        ],
    })
}

export const addDataSession = (session) => (dispatch, getState) => {
    const { addedDataSessions } = getState().retraining_session
    dispatch({
        type: 'SET_ADDED_DATA_SESSIONS',
        payload: [...addedDataSessions, { ...session, weight: null }],
    })
}

export const editDataSessionWeight = (id, weight) => ({
    type: 'EDIT_DATA_SESSION_WEIGHT',
    payload: { id, weight },
})

export const editSessionConfig = (field, value) => ({
    type: 'EDIT_SESSION_CONFIG',
    payload: { field, value },
})

export const createRetrainingSession = () => (dispatch, getState) => {
    const { addedDataSessions, sessionConfig } = getState().retraining_session

    let data = { ...sessionConfig, sessions: addedDataSessions }
    Object.keys(data).forEach((key) => {
        if (!data[key]) {
            data[key] = 0
        }
    })

    post(
        endpoints.sessions.sessionsTrain(),
        data,
        null,
        getState().auth,
        dispatch
    ).then(({ data }) => {
        dispatch({ type: 'RESET_CREATION' })
        dispatch(getRetrainingSessions())
        dispatch(getRetrainigSessionByID(data.answer))
    })
}

export const getRetrainigSessionByID = (id) => (dispatch, getState) =>
    get(
        endpoints.sessions.sessionsTrain(id),
        null,
        getState().auth,
        dispatch
    ).then(({ data }) => {
        dispatch({
            type: 'GET_RETRAINING_SESSION_BY_ID',
            payload: data.answer,
        })
    })

export const editRetrainingSession = () => (dispatch, getState) => {
    const { addedDataSessions, sessionConfig, editSessionId } =
        getState().retraining_session

    let data = { ...sessionConfig, sessions: addedDataSessions }
    Object.keys(data).forEach((key) => {
        if (!data[key]) {
            data[key] = 0
        }
    })
    return put(
        endpoints.sessions.sessionsTrain(editSessionId),
        data,
        null,
        getState().auth,
        dispatch
    ).then(({ data }) => {
        dispatch({ type: 'RESET_CREATION' })
        dispatch(getRetrainingSessions())
        dispatch(getRetrainigSessionByID(data.answer.training_session))
    })
}
