const initialState = {
    tasks: [],
    task: null,
    isCreateOpen: false,
}

export default function TaskReducer(state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case 'SET_TASK_CREATE_MODAL_OPEN':
            return {
                ...state,
                isCreateOpen: payload,
            }
        case 'TASKS':
            return {
                ...state,
                tasks: payload,
            }
        case 'TASK':
            return {
                ...state,
                task: payload,
            }

        case 'DELETE_TASK':
            return {
                ...state,
                tasks: payload,
            }
        default:
            return state
    }
}
