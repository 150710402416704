import { SET_CAMERA_DEBUG, SET_COMMANDS, SET_FRAMES } from '../actions'

const initialState = {
    frames: [],
    commands: [],
    cameraDebugInfo: { fps: 0, avDelay: 0 },
}

export const data = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SET_CAMERA_DEBUG:
            return {
                ...state,
                cameraDebugInfo: payload,
            }
        case SET_COMMANDS:
            return {
                ...state,
                commands: payload,
            }
        case SET_FRAMES:
            return {
                ...state,
                frames: payload,
            }
        default:
            return state
    }
}
