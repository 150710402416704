import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Panel, Grid, Row, Col, Button, InputPicker } from 'rsuite'

import RobotStream from './RobotStream'
import {
    getRobotConfig,
    updateRobotConfig,
    pushRobotCommand,
    getRobotSensorData,
} from '../../../Redux/Actions'
import { JsonEditor } from '../../UtilComponents'
import { urls } from '../../../Utils'

import './Robot.css'
import { notifyError } from '../../../Axios'
import TaskRunner from '../TaskRunner'

const cameraOptions = [
    {
        value: 'camera0',
        label: 'camera0',
    },
    {
        value: 'camera1',
        label: 'camera1',
    },
    {
        value: 'rgbjpeg',
        label: 'rgbjpeg',
    },
]
let stateInterval

class RobotDetail extends React.Component {
    state = {
        newRobotConfig: this.props.robotConfig,
        robot_command_to_post: { op: '', use_source: true },
        command_error: false,
        config_error: false,
        robot: this.props.robot,
        reset: false,
        resetSwitcher: false,
        robot_exchange: 'camera0',
        stateHeight: 100,
        isRunnerOpen: false,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.robotConfig !== this.props.robotConfig) {
            this.setState(() => ({
                newRobotConfig: this.props.robotConfig,
                resetSwitcher: !this.state.resetSwitcher,
            }))
        }
    }

    refresh = () => {
        this.props.getRobotConfig(this.props.robot)
        this.setState(() => ({
            config_error: false,
            command_error: false,
            robot_command_to_post: { op: '', use_source: true },
            resetSwitcher: !this.state.resetSwitcher,
        }))
    }

    setRobotConfig = () => {
        this.props.updateRobotConfig(
            this.props.robot,
            JSON.stringify(this.state.newRobotConfig)
        )
    }

    pushCommand = () => {
        this.props.pushRobotCommand(
            this.props.robot,
            this.state.robot_command_to_post
        )
    }

    onResize = () => {
        const el = document.getElementById('robotStreamPannel')

        el &&
            this.setState({
                stateHeight: el.offsetHeight,
            })
    }

    componentDidMount() {
        const el = document.getElementById('robotStreamPannel')
        window.addEventListener('resize', this.onResize, false)
        stateInterval = setInterval(() => {
            this.props.get_all_sensors(this.props.robot)
        }, 2000)
        this.props.get_all_sensors(this.props.robot)
        this.setState({
            robot_exchange: 'camera0',
            stateHeight: el.offsetHeight,
        })
        setTimeout(() => {
            this.onResize()
        }, 500)
    }

    gotToVR = () => {
        const url = `${urls.apiHost}/vr/?robot=${this.props.robot}&refresh=${this.props.headers.Refresh}&authorization=${this.props.headers.Authorization}`
        axios
            .get(url)
            .then(() => {
                window.location.href = url
            })
            .catch((VrError) => {
                notifyError(
                    { VrError },
                    'VR is unavailable for this robot for now'
                )
            })
    }

    componentWillUnmount() {
        clearInterval(stateInterval)
        window.removeEventListener('resize', this.onResize, false)
    }

    render() {
        return (
            <div className="robot-details-container">
                <TaskRunner
                    isOpen={this.state.isRunnerOpen}
                    onClose={() => this.setState({ isRunnerOpen: false })}
                />
                <Grid fluid>
                    <Row>
                        <Col className="robot-details-column" sm={24} md={14}>
                            <Panel
                                id="robotStreamPannel"
                                className="robot-pannel"
                                header={
                                    <div className="robot-stream-header">
                                        <h2>{this.props.robot}</h2>
                                        <div
                                            style={{
                                                minWidth: 'max-content',
                                                marginBottom: 'auto',
                                                marginTop: 'auto',
                                            }}
                                        >
                                            <InputPicker
                                                menuClassName="input-picker-menu"
                                                className="robot-camera-input"
                                                cleanable={false}
                                                data={cameraOptions}
                                                onChange={(value) =>
                                                    this.setState({
                                                        robot_exchange: value,
                                                    })
                                                }
                                                value={
                                                    this.state.robot_exchange
                                                }
                                            />

                                            <Button
                                                onClick={this.gotToVR}
                                                className="robot-to-vr-btn"
                                            >
                                                <p className="negative-text">
                                                    VR
                                                </p>
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        isRunnerOpen: true,
                                                    })
                                                }
                                                className="robot-to-vr-btn"
                                            >
                                                <p className="negative-text">
                                                    Run Task
                                                </p>
                                            </Button>
                                        </div>
                                    </div>
                                }
                            >
                                <div className="responsive-stream-container">
                                    <RobotStream
                                        minWidth={300}
                                        maxWidth={1500}
                                        aspectRatio={16 / 9}
                                        exchange={this.state.robot_exchange}
                                        token={this.props.headers.Authorization}
                                        placeholderText="No Image"
                                        websocketURL={urls.webSocketHost + '/'}
                                        robotName={this.props.robot}
                                        handleError={(ev) => {
                                            console.log(
                                                'Oh, no! Websocket error occured ',
                                                ev
                                            )
                                        }}
                                        canDownload={true}
                                    />
                                </div>
                            </Panel>
                            <div
                                style={{ marginBottom: 10 }}
                                className="robot-config-wrapper"
                            >
                                <h5>Robot Config</h5>
                                <JsonEditor
                                    style={{ maxHeight: 600 }}
                                    switcher={this.state.resetSwitcher}
                                    value={this.state.newRobotConfig}
                                    onChange={(val) => {
                                        this.setState({
                                            newRobotConfig: val,
                                        })
                                    }}
                                    onError={(err) => {
                                        this.setState({ config_error: err })
                                    }}
                                />
                            </div>
                            <Button
                                className="robot-push-button"
                                disabled={
                                    _.isEqual(
                                        this.state.newRobotConfig,
                                        this.props.robotConfig
                                    ) || this.state.config_error
                                }
                                onClick={() => {
                                    this.setRobotConfig()
                                }}
                            >
                                Set Config
                            </Button>
                            <Button
                                className="robot-push-button"
                                onClick={this.refresh}
                            >
                                Reset Config
                            </Button>
                        </Col>
                        <Col sm={24} md={10}>
                            <div
                                style={{
                                    height: this.state.stateHeight,
                                }}
                                className="robot-config-wrapper"
                            >
                                <h5>All sensors</h5>
                                <div
                                    style={{
                                        height: this.state.stateHeight - 48,
                                    }}
                                >
                                    <JsonEditor
                                        style={{
                                            maxHeight:
                                                this.state.stateHeight - 50,
                                        }}
                                        switcher={this.props.robotAllSensors}
                                        readonly={true}
                                        value={this.props.robotAllSensors}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <div className="robot-config-wrapper">
                                    <h5>Command input</h5>
                                    <JsonEditor
                                        switcher={this.state.resetSwitcher}
                                        value={this.state.robot_command_to_post}
                                        onChange={(val) => {
                                            this.setState({
                                                robot_command_to_post: val,
                                            })
                                        }}
                                        maxHeight={500}
                                        onError={(err) =>
                                            this.setState({
                                                command_error: err,
                                            })
                                        }
                                    />
                                </div>
                                <Button
                                    className="robot-push-button"
                                    disabled={
                                        !this.state.robot_command_to_post ||
                                        this.state.command_error
                                    }
                                    onClick={this.pushCommand}
                                >
                                    Push Command
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { robot } = state

    return {
        headers: state.auth,
        ...robot,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRobotConfig: (robot) => dispatch(getRobotConfig(robot)),
        updateRobotConfig: (robot, content) =>
            dispatch(updateRobotConfig(robot, content)),
        pushRobotCommand: (robot, command) =>
            dispatch(pushRobotCommand(robot, command)),
        get_all_sensors: (robot) => dispatch(getRobotSensorData(robot)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RobotDetail))
