import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, IconButton, Icon, Button } from 'rsuite'

import {
    deleteRetrainingSession,
    getRetrainigSessionByID,
    getRetrainingSessions,
} from '../../../Redux/Actions'
import { sortTableData } from '../../../Utils'
import {
    CustomCell,
    StickyHeaderRow,
    TableDeleteElement,
} from '../../UtilComponents'

const { Column, HeaderCell, Cell } = Table

const hrefObj = {
    base: '/retraining/retraining_session/',
    items: [
        {
            fromData: true,
            addition: 'id=',
            content: 'id',
        },
    ],
}

const RetrainingSessionList = ({ dispatch, retrainingSessions }) => {
    useEffect(() => {
        let interval
        dispatch(getRetrainingSessions())
        interval = setInterval(() => {
            dispatch(getRetrainingSessions())
        }, 3000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const [deleteOpen, setDeleteOpen] = useState(null)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')
    const [loading, setLoading] = useState(false)

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSortColumn(sortColumn)
            setSortType(sortType)
        }, 500)
    }

    const getData = () => {
        const data = retrainingSessions
        if (sortColumn && sortType) {
            return sortTableData(data, sortColumn, sortType)
        }
        return data
    }

    return (
        <>
            <StickyHeaderRow
                {...{ sortColumn, sortType, setSortColumn, setSortType }}
                sortableCells={[
                    { label: 'Name', name: 'name' },
                    { label: 'ID', name: 'id' },
                ]}
                nonSortableCells={[{ label: 'Delete' }]}
            >
                <h5 className="text">Retraining Sessions</h5>
                <div>
                    <Button
                        style={{ margin: 5 }}
                        href="/retraining/retraining_session/create_session"
                        className="green-button"
                        onClick={(ev) => {
                            ev.preventDefault()
                            dispatch({
                                type: 'SET_CREATE_SESSION_OPEN',
                                payload: true,
                            })
                        }}
                    >
                        Create new session
                    </Button>
                </div>
            </StickyHeaderRow>
            <Table
                showHeader={false}
                onRowClick={({ id }) => dispatch(getRetrainigSessionByID(id))}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                autoHeight
                data={getData()}
            >
                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell href={hrefObj} dataKey="name" />
                </Column>
                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell href={hrefObj} dataKey="id" />
                </Column>
                <Column fixed width={100} align="center">
                    <HeaderCell />
                    <Cell>
                        {(rowData) => (
                            <TableDeleteElement
                                label={`Delete session?`}
                                id={rowData.id}
                                onAccept={() => {
                                    dispatch(
                                        deleteRetrainingSession(rowData.id)
                                    )
                                    setDeleteOpen(null)
                                }}
                                onDecline={() => setDeleteOpen(null)}
                                onIconClick={() => setDeleteOpen(rowData.id)}
                                deleteOpen={deleteOpen}
                            />
                        )}
                    </Cell>
                </Column>
            </Table>
        </>
    )
}

function mapStateToProps(state) {
    return {
        retrainingSessions: state.retraining_session.retrainingSessions,
    }
}

export default connect(mapStateToProps)(RetrainingSessionList)
