import { get, notifyError } from '../../../../Axios'
import {
    urls,
    convertDate,
    formTimeString,
    getTimeWithOffset,
    endpoints,
} from '../../../../Utils'
import {
    SET_PLAYLIST,
    SET_PLAYLIST_END_DATE,
    SET_CURRENT_PLAYABLE,
    SET_PLAYLIST_START_DATE,
    SET_WATCHER_ROBOT,
    SET_TOTAL_PAGES,
    SET_WATCHER_PAGE,
    SET_IS_LOADING,
} from './action-types'

export const setPlaylistStartDate = (date) => ({
    type: SET_PLAYLIST_START_DATE,
    payload: date,
})

export const setPlaylistEndDate = (date) => ({
    type: SET_PLAYLIST_END_DATE,
    payload: date,
})

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: isLoading,
})

export const next = () => (dispatch, getState) => {
    const { playlist, currentPlayable, page } = getState().playlist
    const idx = playlist.findIndex((file) => file.path === currentPlayable.path)

    if (idx === playlist.length - 1) {
        dispatch({
            type: SET_WATCHER_PAGE,
            payload: { page: page.page + 1, play: true },
        })
    } else {
        dispatch(setCurrentPlayable(playlist[idx + 1]))
    }
}

export const previous = () => (dispatch, getState) => {
    const { playlist, currentPlayable, page } = getState().playlist
    const idx = playlist.findIndex((file) => file.path === currentPlayable.path)
    if (idx === 0) {
        dispatch({
            type: SET_WATCHER_PAGE,
            payload: { page: page.page - 1, play: true },
        })
    } else {
        dispatch(setCurrentPlayable(playlist[idx - 1]))
    }
}

export const setPlayList =
    (headers, robot, startDate, endDate, page, sortColumn, sortType) =>
    (dispatch) => {
        let url = endpoints.stream.listArchiveStream
        let params = { page_num: !page.page ? 1 : page.page, robot }
        startDate &&
            (params.start = formTimeString(
                getTimeWithOffset(startDate, parseInt(window.SERVER_OFFSET))
            ))
        endDate &&
            (params.stop = formTimeString(
                getTimeWithOffset(endDate, parseInt(window.SERVER_OFFSET))
            ))
        if (sortType && sortColumn) {
            params.sort = sortColumn
            params.sort_direction = sortType === 'asc' ? 1 : -1
        }

        get(url, params, headers, dispatch, true).then((resp) => {
            dispatch({
                type: SET_PLAYLIST,
                payload: resp.data.answer.files.map((el) => {
                    return {
                        ...el,
                        datetime: formTimeString(
                            convertDate(
                                el.datetime,
                                -(new Date().getTimezoneOffset() / 60) -
                                    parseInt(window.SERVER_OFFSET)
                            ),
                            true
                        ),
                    }
                }),
            })
            dispatch({
                type: SET_TOTAL_PAGES,
                payload: resp.data.answer.total_pages,
            })
            resp.data.answer.files.length > 0
                ? page.play &&
                  dispatch(setCurrentPlayable(resp.data.answer.files[0]))
                : notifyError({}, 'No files for this robot')
        })
    }

export const setCurrentPlayable = (playable) => ({
    type: SET_CURRENT_PLAYABLE,
    payload: playable,
})

export const setWatcherRobot = (robot) => ({
    type: SET_WATCHER_ROBOT,
    payload: robot,
})
