import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, InputGroup, Input, Modal } from 'rsuite'

import { createRobot } from '../../../Redux/Actions'

const CreateRobot = ({ isCreateOpen, dispatch }) => {
    const [newRobotName, setNewRobotName] = useState('')
    const [newRobotPassword, setNewRobotPassword] = useState('')

    useEffect(() => {
        setNewRobotPassword('')
        setNewRobotName('')
    }, [isCreateOpen])

    return (
        <Modal
            size="xs"
            show={isCreateOpen}
            onHide={() =>
                dispatch({
                    type: 'CREATE_ROBOT_MODAL_OPEN',
                    payload: false,
                })
            }
        >
            <Modal.Header closeButton={false}>
                <h5>Create Robot</h5>
            </Modal.Header>
            <Modal.Body>
                <InputGroup>
                    <InputGroup.Addon style={{ width: '6em' }}>
                        Name
                    </InputGroup.Addon>
                    <Input
                        id="createRobotName"
                        value={newRobotName}
                        onChange={setNewRobotName}
                    />
                </InputGroup>
                <InputGroup style={{ marginTop: 10 }}>
                    <InputGroup.Addon style={{ width: '6em' }}>
                        Password
                    </InputGroup.Addon>
                    <Input
                        id="createRobotPassword"
                        value={newRobotPassword}
                        onChange={setNewRobotPassword}
                    />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        dispatch(createRobot(newRobotName, newRobotPassword))
                        dispatch({
                            type: 'CREATE_ROBOT_MODAL_OPEN',
                            payload: false,
                        })
                    }}
                    className="green-button"
                >
                    Create
                </Button>
                <Button
                    onClick={() =>
                        dispatch({
                            type: 'CREATE_ROBOT_MODAL_OPEN',
                            payload: false,
                        })
                    }
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        isCreateOpen: state.robot.isCreateOpen,
    }
}

export default connect(mapStateToProps)(CreateRobot)
