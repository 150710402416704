import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Button, Input } from 'rsuite'

import {
    getRobotList,
    getRobotConfig,
    deleteRobot,
    getWatcthowerRobotList,
} from '../../../Redux/Actions'

import { setWatcherRobot } from '../../Watchtower/actions'
import { sortTableData } from '../../../Utils'
import {
    CustomCell,
    StickyHeaderRow,
    TableDeleteElement,
} from '../../UtilComponents'
import CreateRobot from '../CreateRobot'

const { Column, HeaderCell, Cell } = Table

const hrefObj = {
    base: '/robots/',
    items: [
        {
            fromData: true,
            content: 'name',
        },
    ],
}

const hrefWatchtowerObj = {
    base: '/watchtower/',
    items: [
        {
            fromData: true,
            content: 'name',
        },
    ],
}

const RobotList = ({
    dispatch,
    robots,
    watchtowerRobots,
    isAdmin,
    isWatchtower = false,
}) => {
    const [nameFilter, setNameFilter] = useState('')
    const [deleteOpen, setDeleteOpen] = useState(null)

    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')
    const [loading, setLoading] = useState(false)

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSortColumn(sortColumn)
            setSortType(sortType)
        }, 500)
    }

    const getData = () => {
        let data = isWatchtower ? watchtowerRobots : robots
        data = data.filter((el) => {
            if (!nameFilter) {
                return true
            } else {
                return el.name.includes(nameFilter)
            }
        })
        if (sortColumn && sortType) {
            return sortTableData(data, sortColumn, sortType)
        }
        return data
    }

    const handleDeleteRobot = (data) => {
        setDeleteOpen(null)
        dispatch(deleteRobot(data.name))
    }

    useEffect(() => {
        if (isWatchtower) {
            dispatch(getWatcthowerRobotList())
            const interval = setInterval(() => {
                dispatch(getWatcthowerRobotList())
            }, 5000)
            return () => {
                clearInterval(interval)
            }
        } else {
            dispatch(getRobotList())
            const interval = setInterval(() => {
                dispatch(getRobotList())
            }, 5000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [])

    const getRobotData = (robot) => {
        dispatch(getRobotConfig(robot))
    }

    return (
        <div style={{ width: '90%' }} className="margin-center-h">
            <CreateRobot />
            <StickyHeaderRow
                {...{ sortColumn, sortType, setSortColumn, setSortType }}
                sortableCells={[{ label: 'Name', name: 'name' }]}
                nonSortableCells={isWatchtower ? [] : [{ label: 'Delete' }]}
                top={84}
            >
                <Input
                    placeholder="Filter by robot name..."
                    className="list-filter-input"
                    value={nameFilter}
                    onChange={setNameFilter}
                />
                {!isWatchtower && (
                    <div>
                        <Button
                            href="/robots/create_robot"
                            className="green-button"
                            onClick={(ev) => {
                                ev.preventDefault()
                                dispatch({
                                    type: 'CREATE_ROBOT_MODAL_OPEN',
                                    payload: true,
                                })
                            }}
                        >
                            {isAdmin ? 'Create robot' : 'Add robot'}
                        </Button>
                    </div>
                )}
            </StickyHeaderRow>

            <Table
                showHeader={false}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                autoHeight
                rowClassName="non-selectable table-row"
                data={getData()}
                onRowClick={(rowData) => {
                    if (isWatchtower) {
                        dispatch(setWatcherRobot(rowData.name))
                    } else {
                        getRobotData(rowData.name)
                    }
                }}
            >
                <Column sortable flexGrow={1} align="center" fixed>
                    <HeaderCell />
                    <CustomCell
                        href={isWatchtower ? hrefWatchtowerObj : hrefObj}
                        dataKey="name"
                    />
                </Column>

                {!isWatchtower && (
                    <Column width={100} align="center" fixed>
                        <HeaderCell />
                        <Cell>
                            {(rowData) => (
                                <TableDeleteElement
                                    label={`Delete ${rowData.name} robot?`}
                                    id={rowData.id}
                                    onAccept={() => handleDeleteRobot(rowData)}
                                    onDecline={() => setDeleteOpen(null)}
                                    onIconClick={() =>
                                        setDeleteOpen(rowData.id)
                                    }
                                    deleteOpen={deleteOpen}
                                />
                            )}
                        </Cell>
                    </Column>
                )}
            </Table>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        robots: state.robot.robots,
        watchtowerRobots: state.robot.watchtowerRobots,
        isAdmin: state.user.isAdmin,
    }
}

export default connect(mapStateToProps)(RobotList)
