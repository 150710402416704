import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { urls } from '../../../Utils'
import Stream from './Stream'
import StreamWrapper from '../Utils/StreamWrapper'

const PointMarker = ({ markerRobot, markerRobotConfig, headers }) => {
    const [dimensions, setDimensions] = useState([1280, 720])
    useEffect(() => {
        if (markerRobotConfig) {
            const { width, height } = markerRobotConfig
            width && height
                ? setDimensions([width, height])
                : setDimensions([1280, 720])
        } else {
            setDimensions([1280, 720])
        }
    }, [markerRobotConfig])

    return (
        <div className="box-marker-stream margin-center-h">
            <StreamWrapper
                exchange="camera0"
                token={headers.Authorization}
                placeholderText="No Image"
                websocketURL={urls.webSocketHost + '/'}
                robotName={markerRobot}
                handleError={(ev) => {
                    console.log('Oh, no! Websocket error occured ', ev)
                }}
                canDownload={true}
                width={dimensions[0]}
                height={dimensions[1]}
                stream={<Stream />}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { markerRobot, markerRobotConfig } = state.marker
    return {
        headers: state.auth,
        markerRobotConfig,
        markerRobot,
    }
}

export default connect(mapStateToProps)(PointMarker)
