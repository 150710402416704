import { connect } from 'react-redux'

import { EventItem, EventGroup } from '../../components'

import './EventList.css'

const EventList = ({ commands, layout, robot, file, leftPartWidth }) => {
    return (
        <div className="watcher-event-list-container">
            {!robot && (
                <h2
                    style={{
                        marginTop: 40,
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        textAlign: 'center',
                    }}
                    className="on-empty non-selectable"
                >
                    Robot not selected
                </h2>
            )}
            {!file && (
                <h2
                    style={{
                        marginTop: 40,
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        textAlign: 'center',
                    }}
                    className="on-empty non-selectable"
                >
                    File not selected
                </h2>
            )}
            {commands.map((item) => {
                if (typeof item.group === 'object') {
                    return (
                        <EventGroup
                            leftPartWidth={leftPartWidth}
                            item={item}
                            layout={layout}
                        />
                    )
                } else {
                    return (
                        <EventItem
                            leftPartWidth={leftPartWidth}
                            item={item}
                            layout={layout}
                        />
                    )
                }
            })}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        commands: state.data.commands,
        layout: state.utils.layout,
        robot: state.playlist.robot,
        file: state.playlist.currentPlayable,
    }
}

export default connect(mapStateToProps)(EventList)
