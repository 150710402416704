import { get, remove, put } from '../../Axios'
import { endpoints } from '../../Utils'

export const getTaskList = () => (dispatch, getState) =>
    get(endpoints.api.tasks, null, getState().auth, dispatch).then(
        ({ data }) => {
            dispatch({
                type: 'TASKS',
                payload: data.answer,
            })
        }
    )

export const getTask = (name, author) => (dispatch, getState) =>
    get(
        endpoints.api.task,
        { name: name, author: author },
        getState().auth,
        dispatch
    ).then(({ data }) => {
        dispatch({
            type: 'TASK',
            payload: data.answer,
        })
        return data
    })

export const deleteTask = (author, name) => (dispatch, getState) =>
    remove(
        endpoints.api.task,
        { author: author, name: name },
        getState().auth,
        dispatch
    ).then(() => {
        dispatch(getTaskList())
        dispatch({
            type: 'TASK',
            payload: null,
        })
    })

export const updateTask = (name, author, task) => (dispatch, getState) =>
    put(
        endpoints.api.task,
        task ? task : {},
        { author: author, name: name },
        getState().auth
    ).then(() => dispatch(getTaskList()))

export const createTask = (name, author) => (dispatch, getState) =>
    put(
        endpoints.api.task,
        {},
        { author: author, name: name },
        getState().auth
    ).then(() => dispatch(getTaskList()))
