import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, InputGroup, Modal, Button } from 'rsuite'

import { editSessionConfig } from '../../../../Redux/Actions'

const config = [
    { label: 'Name', value: '', field: 'name' },
    { label: 'Learning Rate', value: 0.001, field: 'learning_rate' },
    { label: 'Max iterations', value: 16000, field: 'max_iterations' },
    { label: 'Batch size', value: 16, field: 'batch_size' },
    { label: 'Weight', value: 'zero', field: 'weight' },
]

const SetConfigurations = ({
    dispatch,
    sessionConfig,
    onNextStep,
    isFirstComplete,
    handleCreateSession,
    editSessionId,
}) => {
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        const isDisabled =
            Object.keys(sessionConfig).findIndex(
                (key) => !sessionConfig[key]
            ) !== -1
        setDisabled(isDisabled)
    }, [sessionConfig])

    return (
        <>
            <Modal.Body>
                <div className="w-max-content margin-center-h">
                    {config.map((el) => (
                        <InputGroup className="retraining-session-input-group">
                            <InputGroup.Addon className="retraining-session-input-addon">
                                {el.label}
                            </InputGroup.Addon>
                            <Input
                                id={
                                    el.field === 'name'
                                        ? 'CreateRetrainingSessionName'
                                        : el.field
                                }
                                onChange={(v) => {
                                    if (
                                        el.field === 'weight' ||
                                        el.field === 'name'
                                    ) {
                                        dispatch(editSessionConfig(el.field, v))
                                    } else {
                                        if (!isNaN(v)) {
                                            dispatch(
                                                editSessionConfig(el.field, v)
                                            )
                                        }
                                    }
                                }}
                                value={sessionConfig[el.field]}
                            />
                        </InputGroup>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onNextStep(-1)}>Previous Step</Button>
                <Button
                    onClick={() => {
                        dispatch({
                            type: 'SET_CREATE_SESSION_OPEN',
                            payload: false,
                        })
                    }}
                >
                    Close
                </Button>
                <Button
                    onClick={() => handleCreateSession()}
                    disabled={disabled || !isFirstComplete}
                    className={'green-button'}
                >
                    {editSessionId ? 'Edit Session' : 'Create Session'}
                </Button>
            </Modal.Footer>
        </>
    )
}

const mapStateToProps = (state) => {
    const { sessionConfig, editSessionId } = state.retraining_session
    return { sessionConfig, editSessionId }
}

export default connect(mapStateToProps)(SetConfigurations)
