import {
    SET_CURRENT_CAMERA,
    SET_PLAYER_STATE,
    SET_SLIDER_MAX,
    SET_SLIDER_POSITION,
    SET_CURRENT_STATE,
    SET_SLIDER_MIN,
    SET_CURRENT_COMMAND,
    CLEAN_SLIDER,
} from '../actions'

const initialState = {
    position: 0,
    player_state: 'pause',
    sliderMax: 0,
    sliderMin: 0,
    currentCamera: { time: 0 },
    currentState: { time: 0 },
    currentCommand: {},
}

export const slider = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case CLEAN_SLIDER:
            return {
                position: 0,
                player_state: 'pause',
                sliderMax: 0,
                sliderMin: 0,
                currentCamera: { time: 0 },
                currentState: { time: 0 },
                currentCommand: {},
            }
        case SET_CURRENT_COMMAND:
            return {
                ...state,
                currentCommand: payload,
            }
        case SET_SLIDER_MIN:
            return {
                ...state,
                sliderMin: payload,
            }
        case SET_CURRENT_CAMERA:
            return {
                ...state,
                currentCamera: payload,
            }
        case SET_CURRENT_STATE:
            return {
                ...state,
                currentState: payload,
            }
        case SET_SLIDER_POSITION:
            return {
                ...state,
                position: payload,
            }
        case SET_PLAYER_STATE:
            return {
                ...state,
                player_state: payload,
            }
        case SET_SLIDER_MAX:
            return {
                ...state,
                sliderMax: payload,
            }
        default:
            return state
    }
}
