const initialState = {
    markerRobot: null,
    markerRobotConfig: null,
    markerTask: null,
    markerRes: { text: '', id: '' },
    userBin: null,
    userBinCreation: false,
    userBinParams: { top: 0, bottom: 0 },
}

export default function MarkerReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case 'SET_USER_BIN_PARAMS':
            return {
                ...state,
                userBinParams: payload,
            }
        case 'SET_USER_BIN':
            return {
                ...state,
                userBin: payload,
            }
        case 'SET_USER_BIN_CREATION':
            return {
                ...state,
                userBinCreation: payload,
            }
        case 'SET_MARKER_ROBOT':
            return {
                ...state,
                markerRobot: payload,
            }
        case 'SET_MARKER_ROBOT_CONFIG':
            return {
                ...state,
                markerRobotConfig: payload,
            }
        case 'SET_MARKER_TASK':
            return {
                ...state,
                markerTask: payload,
            }
        case 'MARKER_PUSH_RESULT':
            return {
                ...state,
                markerRes: payload,
            }
        default:
            return state
    }
}
