import { connect } from 'react-redux'
import { Grid, Row, Col } from 'rsuite'

import Connections from './Connections'
import Processors from './Processors'

const SystemMonitoring = ({ route, isAdmin }) => {
    return (
        <>
            <div
                style={{ marginLeft: '0px' }}
                className="test-line system-line fixed"
            />
            {isAdmin ? (
                <Grid fluid>
                    <Row className="fixed"></Row>
                    <Row className="main-layout-row">
                        <Col xs={1}></Col>
                        <Col xs={22}>
                            {route === 'connections' ? (
                                <Connections />
                            ) : (
                                <Processors />
                            )}
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Grid>
            ) : (
                <Grid className="main-layout-grid" fluid>
                    <Row>
                        <div className="not-allowed-wrapper margin-center-h">
                            <h3>Not enough rights for this path</h3>
                        </div>
                    </Row>
                </Grid>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        route: state.processor.systemMonitoringRoute,
        isAdmin: state.user.isAdmin,
    }
}

export default connect(mapStateToProps)(SystemMonitoring)
