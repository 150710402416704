import {
    SET_LAYOUT_TYPE,
    SET_SETTINGS_MODAL_OPEN,
    SET_PLAYBACK_SPEED,
    SET_ALLOW_SLIDING,
    SET_SHOW_CAMERA_DEBUG,
} from '../actions'

const storedShowDebug = localStorage.getItem('showCameraDebug')
const storedAllowSliding = localStorage.getItem('allowSliding')
const storedSpeed = localStorage.getItem('playbackSpeed')

const initialState = {
    layout: 'default',
    showCameraDebug: storedShowDebug ? storedShowDebug : false,
    settingsModal: false,
    playbackSpeed: storedSpeed ? parseFloat(storedSpeed) : 1,
    allowSliding: storedAllowSliding ? storedAllowSliding : true,
}

export const utils = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SET_SHOW_CAMERA_DEBUG:
            return {
                ...state,
                showCameraDebug: payload,
            }
        case SET_ALLOW_SLIDING:
            return {
                ...state,
                allowSliding: payload,
            }
        case SET_PLAYBACK_SPEED:
            return {
                ...state,
                playbackSpeed: payload,
            }
        case SET_SETTINGS_MODAL_OPEN:
            return {
                ...state,
                settingsModal: payload,
            }
        case SET_LAYOUT_TYPE:
            return {
                ...state,
                layout: payload,
            }

        default:
            return state
    }
}
