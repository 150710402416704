import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Checkbox, Table, Icon, IconButton, Loader } from 'rsuite'
import { connect } from 'react-redux'

import {
    getDataSessionFilesByRobot,
    postDataSessionFiles,
    updateDataSessionFIles,
    setFileToView,
} from '../../../../Redux/Actions'
import DataTableHeader from './DataTableHeader'
import DataTableFooter from './DataTableFooter'
import DeleteSession from '../DeleteSession'
import { convertDate, formTimeString, sortTableData } from '../../../../Utils'
import {
    CustomCell,
    CustomHeaderRow,
    headerStyle,
} from '../../../UtilComponents'

import './DataTable.css'

const { Column, HeaderCell, Cell } = Table

const FileViewModalBtn = ({ rowData, handleGetImage }) => {
    return (
        <IconButton
            href={
                rowData.path !== undefined && rowData.path
                    ? `/retraining/data_session/file=${rowData.path.replace(
                          /\//g,
                          '%2f'
                      )}`
                    : rowData.file !== undefined && rowData.file
                    ? `/retraining/data_session/file=${rowData.file.replace(
                          /\//g,
                          '%2f'
                      )}`
                    : undefined
            }
            onClick={(ev) => {
                ev.preventDefault()
                handleGetImage(rowData)
            }}
            style={{
                width: 'min-content',
            }}
            icon={<Icon icon="image" />}
        />
    )
}

const HeaderCheckbox = ({
    addedBySession,
    checkboxState,
    handleCheckAll,
    sessions,
}) => {
    return (
        <div style={{ width: '100%' }}>
            {!addedBySession.id && addedBySession.files.length > 0
                ? addedBySession.files.length > 0
                : sessions.length > 0 && (
                      <Checkbox
                          disabled={addedBySession.id ? true : false}
                          className="checkbox-header margin-center-h checkbox-bg"
                          indeterminate={checkboxState.indeterminate}
                          checked={checkboxState.checkAll}
                          onChange={handleCheckAll}
                      ></Checkbox>
                  )}
        </div>
    )
}

const SessionTable = ({
    robot,
    handleDelete,
    setShowWarning,
    sessions,
    sessionPage,
    sessionPagesTotal,
    checkedFiles,
    start,
    addedBySession,
    end,
    dispatch,
    commandFilter,
    fileToView,
    showWarning,
    sessionName,
    setSessionName,
}) => {
    const [checkboxState, setCheckboxState] = useState({
        indeterminate: false,
        checkAll: false,
    })

    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState([])
    const [isImageLoading, setIsImageLoading] = useState(false)
    const [filesBySession, setFilesBySession] = useState(null)

    useEffect(() => {
        if (addedBySession.files.length > 0) {
            if (sortColumn && sortType) {
                setFilesBySession(
                    sortTableData(addedBySession.files, sortColumn, sortType)
                )
            } else {
                setFilesBySession(addedBySession.files)
            }
        } else {
            setFilesBySession(null)
        }
    }, [addedBySession.files, sortType, sortColumn])

    useEffect(() => {
        const newData = filesBySession ? filesBySession : sessions
        if (!_.isEqual(newData, tableData)) {
            setTableData(newData)
        }
    }, [sessions, filesBySession])

    useEffect(() => {
        if (robot) {
            dispatch(
                getDataSessionFilesByRobot(
                    sessionPage,
                    robot,
                    sortType,
                    sortColumn
                )
            )
        }
    }, [robot, start, end, commandFilter, sessionPage, sortType, sortColumn])

    useEffect(() => {
        const pageValue = checkedFiles.filter(
            (file) => sessions.map((val) => val.path).indexOf(file) !== -1
        )
        setCheckboxState({
            indeterminate:
                pageValue.length > 0 && pageValue.length < sessions.length,
            checkAll:
                pageValue.length > 0 && pageValue.length === sessions.length,
        })
    }, [sessions])

    useEffect(() => {
        if (addedBySession && addedBySession.name) {
            setSessionName(addedBySession.name)
        } else {
            setSessionName('')
        }
    }, [addedBySession])

    const handleSortColumn = (sortCol, type) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSortColumn(sortCol)
            setSortType(type)
        }, 500)
    }

    const handleCheckAll = (value, checked) => {
        if (!checked) {
            let newValue = checkedFiles
            sessions.forEach((file) => {
                const idx = newValue.findIndex((el) => el.path === file.path)
                newValue = [
                    ...newValue.slice(0, idx),
                    ...newValue.slice(idx + 1),
                ]
            })
            dispatch({
                type: 'CHECKED_FILES',
                payload: newValue,
            })
        } else {
            let newValue = checkedFiles
            sessions.forEach((file) => {
                if (newValue.findIndex((el) => el.path === file.path) === -1) {
                    newValue.push(file)
                }
            })
            dispatch({
                type: 'CHECKED_FILES',
                payload: newValue,
            })
        }
        setCheckboxState({
            indeterminate: false,
            checkAll: checked,
        })
    }

    const handleGetImage = (value) => {
        if (value.path === fileToView.path) {
            setIsImageLoading(false)
            dispatch({
                type: 'SET_FILE_TO_VIEW',
                payload: { path: null, image: null },
            })
        } else {
            setIsImageLoading(true)
            if (value.path !== undefined) {
                dispatch(setFileToView(value.path)).then(() =>
                    setIsImageLoading(false)
                )
            } else if (value.file !== undefined) {
                dispatch(setFileToView(value.file)).then(() =>
                    setIsImageLoading(false)
                )
            }
        }
    }

    const handleChange = (value, checked) => {
        let newValue = checkedFiles

        if (checked) {
            newValue.push(value)
        } else {
            const idx = newValue.findIndex((el) => el.path === value.path)
            newValue = [
                ...newValue.slice(0, idx),
                ...newValue.slice(idx + 1, newValue.length),
            ]
        }
        const pageValue = checkedFiles.filter(
            (file) => sessions.findIndex((el) => el.path === file.path) !== -1
        )
        setCheckboxState({
            indeterminate:
                pageValue.length > 0 && pageValue.length < sessions.length,
            checkAll: pageValue.length === sessions.length,
        })

        dispatch({ type: 'CHECKED_FILES', payload: newValue })
    }

    const onPost = () => {
        dispatch(postDataSessionFiles(checkedFiles, sessionName))
        dispatch(
            getDataSessionFilesByRobot(sessionPage, robot, sortType, sortColumn)
        )
        setCheckboxState({
            indeterminate: false,
            checkAll: false,
        })
        setSessionName('')
        dispatch({ type: 'CHECKED_FILES', payload: [] })
    }

    const onUpdate = () => {
        dispatch(updateDataSessionFIles(addedBySession.id, sessionName))
        dispatch(
            getDataSessionFilesByRobot(sessionPage, robot, sortType, sortColumn)
        )
        setCheckboxState({
            indeterminate: false,
            checkAll: false,
        })
        setSessionName('')
        dispatch({ type: 'CHECKED_FILES', payload: [] })
    }

    return (
        <div className="session-table">
            {isImageLoading && (
                <Loader
                    className="data-session-loader"
                    center
                    size="lg"
                    content="loading..."
                    vertical
                />
            )}

            <DeleteSession
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                handleDelete={handleDelete}
            />
            <DataTableHeader
                onRefresh={() =>
                    dispatch(
                        getDataSessionFilesByRobot(
                            sessionPage,
                            robot,
                            sortType,
                            sortColumn
                        )
                    )
                }
                onPost={onPost}
                sessionName={sessionName}
                onUpdate={onUpdate}
                setSessionName={setSessionName}
            />

            <CustomHeaderRow
                {...{ sortColumn, sortType, setSortColumn, setSortType }}
                cells={[
                    { label: 'Robot' },
                    { label: 'Date/Time', name: 'datetime' },
                    { label: 'Command', name: 'command' },
                ]}
                paddingStyle={{ paddingRight: 175 }}
                checkBox={
                    <HeaderCheckbox
                        addedBySession={addedBySession}
                        checkboxState={checkboxState}
                        handleCheckAll={handleCheckAll}
                        sessions={sessions}
                    />
                }
            />
            <div
                className="custom-scrollbar"
                style={{
                    height: 500,
                    overflowY: 'auto',
                    backgroundColor: 'var(--primary)',
                }}
            >
                <Table
                    autoHeight
                    showHeader={false}
                    rowClassName="table-row"
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    loading={loading}
                    renderEmpty={() => (
                        <div className="empty-table-holder">
                            <h3>
                                {robot ? 'No data found' : 'No robot selected'}
                            </h3>
                        </div>
                    )}
                    data={tableData}
                >
                    <Column
                        align="center"
                        className="non-selectable"
                        flexGrow={1}
                    >
                        <HeaderCell style={headerStyle}>Robot</HeaderCell>
                        <CustomCell dataKey="robot" />
                    </Column>
                    <Column
                        align="center"
                        sortable
                        className="non-selectable"
                        flexGrow={1}
                    >
                        <HeaderCell style={headerStyle}>Date/Time</HeaderCell>
                        <Cell dataKey="datetime">
                            {(rowData) => (
                                <div className="table-cell-wrapper">
                                    {formTimeString(
                                        convertDate(
                                            rowData.datetime,
                                            -(
                                                new Date().getTimezoneOffset() /
                                                60
                                            ) - parseInt(window.SERVER_OFFSET)
                                        ),
                                        true
                                    )}
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column
                        sortable
                        align="center"
                        className="selectable-column"
                        flexGrow={1}
                    >
                        <HeaderCell style={headerStyle}>Command</HeaderCell>
                        <CustomCell dataKey="command" />
                    </Column>
                    <Column
                        align="center"
                        flexGrow={1}
                        className="non-selectable"
                    >
                        <HeaderCell></HeaderCell>
                        <Cell className="checkbox-container">
                            {(rowData) => (
                                <div className="checkbox-div">
                                    {!addedBySession.id && (
                                        <Checkbox
                                            disabled={
                                                addedBySession.id ? true : false
                                            }
                                            checked={
                                                checkedFiles.filter(
                                                    (val) =>
                                                        val.path ===
                                                        rowData.path
                                                ).length > 0
                                            }
                                            value={rowData}
                                            className={
                                                checkedFiles.filter(
                                                    (val) =>
                                                        val.path ===
                                                        rowData.path
                                                ).length === 0
                                                    ? 'checkbox-header margin-center-h'
                                                    : 'checkbox-header margin-center-h checkbox-bg'
                                            }
                                            onChange={handleChange}
                                        />
                                    )}
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column
                        className="non-selectable"
                        align="center"
                        width={155}
                    >
                        <HeaderCell style={headerStyle} />
                        <Cell className="checkbox-container">
                            {(rowData) => (
                                <div className="checkbox-div">
                                    <div className="table-cell-wrapper">
                                        <FileViewModalBtn
                                            rowData={rowData}
                                            handleGetImage={handleGetImage}
                                        />
                                    </div>
                                </div>
                            )}
                        </Cell>
                    </Column>
                </Table>
            </div>
            <DataTableFooter
                dispatch={dispatch}
                addedBySession={addedBySession}
                sessionPage={sessionPage}
                sessionPagesTotal={sessionPagesTotal}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        files: sessions,
        checkedFiles,
        sessionPage,
        sessionPagesTotal,
        start,
        end,
        retrainingRobot,
        fileToView,
        addedBySession,
        commandFilter,
    } = state.data_session
    return {
        addedBySession,
        commandFilter,
        sessions,
        start,
        end,
        fileToView,
        sessionPage,
        sessionPagesTotal,
        checkedFiles,
        robot: retrainingRobot,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => dispatch(action),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionTable)
