import axios from 'axios'
import { Notification } from 'rsuite'
import './Error.css'

import { checkLoginAndRefreshOrLogout } from '../Redux/Actions'
import { urls } from '../Utils'

export const notifyError = (error, text) => {
    let resp =
        error.response && error.response !== undefined
            ? error.response
            : { status: '' }
    if (resp.status !== 401) {
        Notification.error({
            title: `Error ${resp.status}`,
            placement: 'bottomStart',
            description: (
                <>
                    <p className="error-p">{text}</p>
                    {error.response && error.response.data && (
                        <p className="error-p">{`Details: ${JSON.stringify(
                            error.response.data.detail
                        )}`}</p>
                    )}
                </>
            ),
        })
    }
}

export const notifyWithOutError = (status, text) => {
    Notification.error({
        title: `Error ${status}`,
        placement: 'bottomStart',
        description: (
            <>
                <p className="error-p">{text}</p>
            </>
        ),
    })
}

export const get = (
    endpoint,
    params = null,
    headers = null,
    dispatch = null,
    notify = true
) => {
    let url = endpoint
    if (url[0] === '/') {
        url = urls.apiHost + endpoint
    }
    const headerAndParams = {}
    headers &&
        (headerAndParams.headers = {
            Authorization: headers.Authorization,
            Refresh: headers.Refresh,
        })
    params && (headerAndParams.params = params)

    return new Promise((resolve, reject) => {
        axios
            .get(url, headerAndParams)
            .then((resp) => {
                resolve(resp)
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    checkLoginAndRefreshOrLogout(headers)(dispatch)
                        .then((resp) => {
                            resolve(
                                get(
                                    url,
                                    params,
                                    {
                                        ...headers,
                                        Authorization: resp.access_token,
                                        Refresh: resp.refresh_token,
                                    },
                                    dispatch
                                )
                            )
                        })
                        .catch(() => {
                            console.log('Authorization error')
                        })
                } else {
                    reject(error)

                    notify &&
                        notifyError(error, `GET request to ${url} failed.`)
                }
            })
    })
}

export const post = (
    endpoint,
    data = null,
    params = null,
    headers = null,
    dispatch = null,
    notify = true
) => {
    let url = endpoint
    if (url[0] === '/') {
        url = urls.apiHost + endpoint
    }
    const headerAndParams = {}
    headers &&
        (headerAndParams.headers = {
            Authorization: headers.Authorization,
            Refresh: headers.Refresh,
        })
    params && (headerAndParams.params = params)

    return new Promise((resolve, reject) => {
        axios
            .post(url, data, headerAndParams)
            .then((resp) => {
                resolve(resp)
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    checkLoginAndRefreshOrLogout(headers)(dispatch)
                        .then((resp) => {
                            resolve(
                                post(
                                    url,
                                    data,
                                    params,
                                    {
                                        ...headers,
                                        Authorization: resp.access_token,
                                        Refresh: resp.refresh_token,
                                    },
                                    dispatch
                                )
                            )
                        })
                        .catch(() => {
                            console.log('Authorization error')
                        })
                } else {
                    reject(error)

                    notify &&
                        notifyError(error, `POST request to ${url} failed.`)
                }
            })
    })
}

export const put = (
    endpoint,
    data = null,
    params = null,
    headers = null,
    dispatch = null,
    notify = true,
    patch = false
) => {
    let url = endpoint
    if (url[0] === '/') {
        url = urls.apiHost + endpoint
    }
    const headerAndParams = {}
    headers &&
        (headerAndParams.headers = {
            Authorization: headers.Authorization,
            Refresh: headers.Refresh,
        })
    params && (headerAndParams.params = params)

    return new Promise((resolve, reject) => {
        axios[!patch ? 'put' : 'patch'](url, data, headerAndParams)
            .then((resp) => {
                resolve(resp)
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    checkLoginAndRefreshOrLogout(headers)(dispatch)
                        .then((resp) => {
                            resolve(
                                put(
                                    url,
                                    data,
                                    params,
                                    {
                                        ...headers,
                                        Authorization: resp.access_token,
                                        Refresh: resp.refresh_token,
                                    },
                                    dispatch,
                                    notify,
                                    patch
                                )
                            )
                        })
                        .catch(() => {
                            console.log('Authorization error')
                        })
                } else {
                    reject(error)

                    notify &&
                        notifyError(error, `PUT request to ${url} failed.`)
                }
            })
    })
}

export const remove = (
    endpoint,
    params = null,
    headers = null,
    dispatch = null,
    notify = true
) => {
    let url = endpoint
    if (url[0] === '/') {
        url = urls.apiHost + endpoint
    }
    const headerAndParams = {}
    headers &&
        (headerAndParams.headers = {
            Authorization: headers.Authorization,
            Refresh: headers.Refresh,
        })
    params && (headerAndParams.params = params)

    return new Promise((resolve, reject) => {
        axios
            .delete(url, headerAndParams)
            .then((resp) => {
                resolve(resp)
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    checkLoginAndRefreshOrLogout(headers)(dispatch)
                        .then((resp) => {
                            resolve(
                                remove(
                                    url,
                                    params,
                                    {
                                        ...headers,
                                        Authorization: resp.access_token,
                                        Refresh: resp.refresh_token,
                                    },
                                    dispatch
                                )
                            )
                        })
                        .catch(() => {
                            console.log('Authorization error')
                        })
                } else {
                    reject(error)

                    notify &&
                        notifyError(error, `DELETE request to ${url} failed.`)
                }
            })
    })
}
