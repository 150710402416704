import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Icon } from 'rsuite'

import placeholder from './placeholder.jpg'
import './Player.css'

const getImgUrl = (arrBuf) => {
    const urlCreator = window.URL || window.webkitURL
    return urlCreator.createObjectURL(
        new Blob([new Uint8Array(arrBuf)], { type: 'image/jpeg' })
    )
}

const Player = ({
    currentCamera,
    cameraDebugInfo,
    showCameraDebug,
    currentPlayable,
    playerState,
}) => {
    useEffect(() => {
        if (currentCamera.val) {
            document.getElementById('cameraImg').src = getImgUrl(
                currentCamera.val.img
            )
        } else {
            document.getElementById('cameraImg').src = placeholder
        }
    }, [currentCamera])

    return (
        <div
            style={{ marginTop: 0 }}
            className="watcher-player-container watcher-container"
        >
            {showCameraDebug && (
                <div className="watcher-debug-info">
                    {`FPS: ${cameraDebugInfo.fps} | Average frame delay: ${cameraDebugInfo.avDelay}`}
                </div>
            )}
            {playerState === 'pause' && currentCamera.val !== undefined && (
                <div
                    className="download-frame-btn"
                    onClick={() => {
                        const { time } = currentCamera
                        const { path } = currentPlayable
                        let name =
                            path.slice(0, path.length - 4) +
                            '_' +
                            time.toString()

                        const initJpeg = currentCamera.val.img
                        const initPng = currentCamera.val.depth
                        const initJSON = currentCamera.val.status

                        const urlCreator = window.URL || window.webkitURL

                        const jpegUrl = urlCreator.createObjectURL(
                            new Blob([new Uint8Array(initJpeg)], {
                                type: 'image/jpeg',
                            })
                        )
                        const link = document.createElement('a')
                        link.href = jpegUrl
                        link.download = name + '.jpeg'
                        link.click()

                        const dataStr =
                            'data:text/json;charset=utf-8,' +
                            encodeURIComponent(JSON.stringify(initJSON))
                        link.href = dataStr
                        link.download = name + '.json'
                        link.click()

                        if (initPng && initPng !== undefined) {
                            const pngUrl = urlCreator.createObjectURL(
                                new Blob([new Uint8Array(initPng)], {
                                    type: 'image/png',
                                })
                            )
                            link.href = pngUrl
                            link.download = name + '.png'
                            link.click()
                        }
                    }}
                >
                    <Icon icon="download" size="lg" />
                </div>
            )}
            <img
                className="watcher-player-image"
                alt="placeholder"
                id="cameraImg"
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        currentCamera: state.slider.currentCamera,
        cameraDebugInfo: state.data.cameraDebugInfo,
        showCameraDebug: state.utils.showCameraDebug,
        playerState: state.slider.player_state,
        currentPlayable: state.playlist.currentPlayable,
    }
}

export default connect(mapStateToProps)(Player)
