import { endpoints } from '../../Utils'
import { get, post, remove } from '../../Axios'

export const setModalOpen = (bool) => ({
    type: 'SET_NOTIFICATIONS_MODAL_OPEN',
    payload: bool,
})

export const setRobotsToLightUp = (payload) => ({
    type: 'SET_ROBOTS_TO_LIGHT_UP',
    payload,
})

export const setRobotsToFollow = (list, prevList) => (dispatch, getState) => {
    const toAdd = list.filter((el) => prevList.indexOf(el) === -1)
    toAdd.forEach((robot_name) => {
        post(
            endpoints.api.subscriptionRobot,
            null,
            { robot_name },
            getState().auth,
            dispatch
        )
    })

    const toRemove = prevList.filter((el) => list.indexOf(el) === -1)
    toRemove.forEach((robot_name) => {
        remove(
            endpoints.api.subscriptionRobot,
            { robot_name },
            getState().auth,
            dispatch
        )
    })
}
export const getCurrentUser = () => (dispatch, getState) => {
    get(endpoints.api.currentUser, null, getState().auth, dispatch).then(
        ({ data }) => {
            dispatch({
                type: 'SET_ROBOTS_TO_FOLLOW',
                payload: data.answer.subscription_robot,
            })
        }
    )
}
