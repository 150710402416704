import {
    Route,
    Switch,
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom'
import { Container, Content } from 'rsuite'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
    Auth,
    SystemMonitoring,
    Robots,
    Tasks,
    Retraining,
    WebOperators,
    Users,
    RobotSubscriptions,
    Watchtower,
    Header,
    Sidebar,
    PasswordModal,
} from './Components'
import { autoLogin, setModalOpen } from './Redux/Actions'
import {
    appMapDispatchToProps,
    appMapStateToProps,
    setEnvs,
    validPathList,
} from './Utils/AppUtils'

import './App.css'

let prevPush = ''
console.log('hello II')
const App = ({
    dispatch,
    isAuthenticated,
    actions,
    robot,
    task,
    operator,
    trainStage,
    trainFile,
    trainRobot,
    trainSession,
    trainDataSet,
    watchtowerRobot,
    watchtowerFile,
    systemMonitoringRoute,
    createUser,
    editUser,
    isCreateOpenRobot,
    isCreateOpenTask,
    isCreateOpenRetraining,
    isAdmin,
}) => {
    const [redirectTo, setRedirectTo] = useState('/auth')

    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const { pathname } = location
        const path = pathname.split('/')[1]
        let taskstring = ''
        if (task && task.name) {
            taskstring += 'name=' + task.name
            if (task.author) {
                taskstring += '&author=' + task.author
            }
        }

        let toPush

        switch (path) {
            case 'robots':
                toPush = robot ? '/robots/' + robot : '/robots'
                if (isCreateOpenRobot) {
                    toPush += '/create_robot'
                }
                break

            case 'tasks':
                toPush = taskstring ? '/tasks/' + taskstring : '/tasks'
                if (isCreateOpenTask) {
                    toPush += '/create_task'
                }
                break

            case 'web_operators':
                if (operator) {
                    toPush = '/web_operators/' + operator
                } else {
                    toPush = '/web_operators'
                }
                break

            case 'watchtower':
                toPush = '/watchtower'
                if (watchtowerRobot) {
                    toPush += '/' + watchtowerRobot

                    if (watchtowerFile) {
                        toPush += '/' + watchtowerFile
                    }
                }
                break

            case 'users':
                toPush = '/users'
                if (createUser) {
                    toPush += '/create_user'
                } else if (editUser) {
                    toPush += '/edit_user&' + editUser.id
                }
                break

            case 'retraining':
                toPush = '/retraining/' + trainStage
                if (trainStage === 'data_session') {
                    if (trainRobot) {
                        toPush += '/robot=' + trainRobot
                    }
                    if (trainDataSet) {
                        toPush += '/id=' + trainDataSet
                    }
                    if (trainFile) {
                        toPush += '/file=' + trainFile.replace(/\//g, '%2f')
                    }
                } else {
                    if (isCreateOpenRetraining) {
                        toPush += '/create_session'
                    } else if (trainSession) {
                        toPush += '/id=' + trainSession
                    }
                }
                break

            case 'system_monitoring':
                if (systemMonitoringRoute) {
                    toPush = '/system_monitoring/' + systemMonitoringRoute
                }
                break

            default:
                toPush = null
                break
        }

        if (isAuthenticated && toPush && toPush !== pathname) {
            if (toPush !== prevPush || !prevPush) {
                history.push(toPush)
                prevPush = toPush
            }
        }
    }, [
        systemMonitoringRoute,
        operator,
        watchtowerFile,
        watchtowerRobot,
        task,
        robot,
        isCreateOpenTask,
        isCreateOpenRobot,
        createUser,
        editUser,
        trainFile,
        isCreateOpenRetraining,
        trainRobot,
        trainSession,
        trainStage,
        trainDataSet,
        location.pathname.split('/')[1],
    ])

    useEffect(() => {
        setEnvs()
        const { pathname } = location
        const headers = dispatch(autoLogin())
        if (headers) {
            const path = pathname ? pathname.split('/')[1] : null
            if (validPathList.indexOf(path) !== -1) {
                if (path === 'robot_subscription') {
                    dispatch(setModalOpen(true))
                } else if (pathname === '/auth' || pathname === '/') {
                    setRedirectTo(isAdmin ? '/system_monitoring' : '/robots')
                } else {
                    actions['get_' + path](pathname.split('/'))
                    setRedirectTo(pathname)
                }
            } else {
                setRedirectTo(isAdmin ? '/system_monitoring' : '/robots')
            }
        } else {
            setRedirectTo('/auth')
        }
    }, [])

    useEffect(() => {
        window.onpopstate = (ev) => {
            if (isAuthenticated) {
                const pathname = ev.target.location.pathname.split('/')[1]
                if (
                    validPathList.indexOf(pathname) !== -1 &&
                    pathname !== 'auth'
                ) {
                    actions['get_' + pathname](
                        ev.target.location.pathname.split('/')
                    )
                }
            }
        }
        if (isAuthenticated && location.pathname === '/auth') {
            setRedirectTo(isAdmin ? '/system_monitoring' : '/robots')
        }
    }, [isAuthenticated])

    const [isReady, setIsReady] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setIsReady(true)
        }, 100)
    }, [])

    return (
        <div>
            {isReady && (
                <div className="app-wrapper">
                    {isAuthenticated ? (
                        <div className="app-container">
                            <Header
                                history={history}
                                isWatchtower={
                                    location.pathname.split('/')[1] ===
                                    'watchtower'
                                }
                            />
                            <Container>
                                <Sidebar />
                                <RobotSubscriptions />
                                <PasswordModal />
                                <Content>
                                    <Switch>
                                        <Route
                                            path="/robots"
                                            component={Robots}
                                        />
                                        <Route
                                            path="/tasks"
                                            component={Tasks}
                                        />

                                        <Route
                                            path="/retraining"
                                            component={Retraining}
                                        />

                                        <Route
                                            path="/watchtower"
                                            component={Watchtower}
                                        />
                                        <Route
                                            path="/web_operators"
                                            component={WebOperators}
                                        />

                                        <Route
                                            path="/users"
                                            component={Users}
                                        />

                                        <Route
                                            path="/system_monitoring"
                                            component={SystemMonitoring}
                                        />

                                        <Redirect to={redirectTo} />
                                    </Switch>
                                </Content>
                            </Container>
                        </div>
                    ) : (
                        <Switch>
                            <Route path="/auth" component={Auth} />
                            <Redirect to={'/auth'} />
                        </Switch>
                    )}
                </div>
            )}
        </div>
    )
}

export default connect(appMapStateToProps, appMapDispatchToProps)(App)
