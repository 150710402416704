import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import fit from 'canvas-fit'
import { Icon } from 'rsuite'
import { WsHOC } from 'rembrain_streams'
import { Buffer } from 'buffer/'

const RobotStream = ({
    maxWidth,
    minWidth,
    aspectRatio,
    placeholderText = 'No Image',
    robot,
    data,
}) => {
    let resizeTimeout

    const [drawing, setDrawing] = useState(false)
    const [imageExists, setImageExists] = useState(false)
    const [resizeImage, setResizeImage] = useState('')
    const [placeholderImage, setPlaceholderImage] = useState('')

    const newImg = useRef(new Image(100, 100))
    const canvasRef = useRef(null)

    const handleDownload = () => {
        const name = robot + '-' + new Date().toISOString()
        const urlCreator = window.URL || window.webkitURL
        const link = document.createElement('a')
        link.href = newImg.current.src
        link.target = '_blank'
        link.download = name + '.jpg'
        link.click()
        if (data[2]) {
            const dataStr =
                'data:text/json;charset=utf-8,' + encodeURIComponent(data[2])
            link.href = dataStr
            link.download = name + '.json'
            link.target = '_blank'
            link.click()
        }
        if (data[1]) {
            const pngUrl = urlCreator.createObjectURL(
                new Blob([new Uint8Array(data[1])], {
                    type: 'image/png',
                })
            )
            link.href = pngUrl
            link.download = name + '.png'
            link.target = '_blank'
            link.click()
        }
    }

    const draw = () => {
        const canvas = canvasRef.current
        if (canvas) {
            const ctx = canvas.getContext('2d')
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                if (imageExists) {
                    ctx.drawImage(
                        newImg.current,
                        0,
                        0,
                        canvas.width,
                        canvas.height
                    )
                }
            }
        }
    }

    const handleResize = () => {
        clearTimeout(resizeTimeout)
        setDrawing(false)
        resizeTimeout = setTimeout(() => {
            const canvas = canvasRef.current
            if (canvas) {
                fit(canvas)
                setDrawing(true)
                draw()
            }
        }, 350)
    }

    useEffect(() => {
        if (imageExists) {
            dispatchEvent(new Event('resize'))
            const canvas = canvasRef.current
            if (canvas) {
                fit(canvas)
                setDrawing(true)
                window.addEventListener('resize', handleResize, false)
            }
            return () => {
                window.removeEventListener('resize', handleResize, false)
            }
        }
    }, [imageExists])

    useEffect(() => {
        if (data && data[0]) {
            const buf = Buffer.from(data[0]).toString('base64')
            newImg.current.src = 'data:image/jpg;base64,' + buf
        } else if (data && !data.image) {
            setImageExists(false)
        }
    }, [data])

    useEffect(() => {
        newImg.current.onload = () => {
            setImageExists(true)
            draw()
        }
    }, [imageExists])

    useEffect(() => {
        if (imageExists) {
            if (drawing) {
                setTimeout(() => {
                    setResizeImage('')
                }, 300)
            } else {
                setResizeImage(newImg.current.src)
            }
        }
    }, [drawing, imageExists])

    useEffect(() => {
        const holderCanvas = document.createElement('canvas')
        const ctx = holderCanvas.getContext('2d')
        ctx.fillStyle = '#d3d3d3'
        ctx.textAlign = 'center'
        ctx.font = '3em Arial'
        ctx.textBaseline = 'middle'
        ctx.fillText(
            placeholderText,
            holderCanvas.width / 2,
            holderCanvas.height / 2
        )
        setPlaceholderImage(holderCanvas.toDataURL())
    }, [placeholderText])

    return (
        <div
            style={{
                backgroundColor: 'var(--primary)',
                aspectRatio: aspectRatio.toString(),
                maxWidth,
                minWidth,
                padding: 0,
                margin: 0,
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            {data && data[0] && (
                <div onClick={handleDownload} className="stream-download-btn">
                    <Icon icon="download" />
                </div>
            )}

            <canvas
                style={resizeImage || !imageExists ? { display: 'none' } : {}}
                ref={canvasRef}
            />
            <img
                style={{ position: 'absolute', top: 0, left: 0 }}
                width="100%"
                height="100%"
                src={imageExists ? resizeImage : placeholderImage}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return { robot: state.robot.robot }
}

export default WsHOC(connect(mapStateToProps)(RobotStream))
