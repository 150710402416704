import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Pagination, DatePicker } from 'rsuite'

import { CustomCell, StickyHeaderRow } from '../../../UtilComponents'
import {
    setPlayList,
    SET_WATCHER_PAGE,
    setPlaylistEndDate,
    setPlaylistStartDate,
    setCurrentPlayable,
} from '../../actions'

import './FileList.css'

const { Column, HeaderCell } = Table

const hrefWatchtowerObj = {
    base: '/watchtower/',
    items: [
        {
            fromData: true,
            content: 'name',
        },
    ],
}

const FileList = ({
    dispatch,
    headers,
    files,
    robot,
    pages_total,
    page,
    start,
    end,
}) => {
    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')

    useEffect(() => {
        dispatch(
            setPlayList(headers, robot, start, end, page, sortColumn, sortType)
        )
    }, [page, end, start, sortColumn, sortType])

    return (
        <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
            <StickyHeaderRow
                {...{ sortColumn, sortType, setSortColumn, setSortType }}
                sortableCells={[
                    { label: 'datetime', name: 'datetime' },
                    { label: 'Path', name: 'path' },
                ]}
                top={84}
            >
                <div style={{ padding: 5 }} className="watchtower-time-picker">
                    <p>From </p>
                    <DatePicker
                        style={{ marginLeft: 10 }}
                        className="watcher-time-input"
                        value={start}
                        onChange={(value) => {
                            dispatch({
                                type: SET_WATCHER_PAGE,
                                payload: { page: 1, play: false },
                            })
                            dispatch(setPlaylistStartDate(value))
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                    />
                    <p style={{ marginLeft: 15 }}>To </p>
                    <DatePicker
                        style={{ marginLeft: 10 }}
                        className="watcher-time-input"
                        onChange={(value) => {
                            dispatch({
                                type: SET_WATCHER_PAGE,
                                payload: { page: 1, play: false },
                            })
                            dispatch(setPlaylistEndDate(value))
                        }}
                        value={end}
                        format="YYYY-MM-DD HH:mm:ss"
                    />
                </div>
                <div className="spacer" />
                <Pagination
                    activePage={page.page}
                    pages={pages_total}
                    maxButtons={5}
                    ellipsis={true}
                    boundaryLinks={true}
                    onSelect={(ev) => {
                        dispatch({
                            type: SET_WATCHER_PAGE,
                            payload: { page: ev, play: false },
                        })
                    }}
                />
            </StickyHeaderRow>

            <Table
                autoHeight
                showHeader={false}
                rowClassName="non-selectable table-row"
                data={files}
                onRowClick={(rowData) => dispatch(setCurrentPlayable(rowData))}
            >
                <Column flexGrow={1} align="center" fixed>
                    <HeaderCell />
                    <CustomCell href={hrefWatchtowerObj} dataKey="datetime" />
                </Column>
                <Column flexGrow={1} align="center" fixed>
                    <HeaderCell />
                    <CustomCell href={hrefWatchtowerObj} dataKey="path" />
                </Column>
            </Table>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        headers: state.auth,
        robot: state.playlist.robot,
        files: state.playlist.playlist,
        pages_total: state.playlist.total_pages,
        page: state.playlist.page,
        start: state.playlist.startDate,
        end: state.playlist.endDate,
    }
}

export default connect(mapStateToProps)(FileList)
