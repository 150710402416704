import { Table } from 'rsuite'

export const CustomCell = ({ rowData, dataKey, href, ...rest }) => {
    const createHref = () => {
        let toRedirect = href.base
        href.items.map((el) => {
            let toAdd = ''
            if (el.fromData) {
                if (rowData[el.content] && rowData[el.content] !== undefined) {
                    toAdd = rowData[el.content]
                }
            } else {
                toAdd = el.content
            }
            if (toAdd && el.addition && el.addition !== undefined) {
                toAdd = el.addition + toAdd
            }
            toAdd && (toRedirect += toAdd + '&')
        })
        return toRedirect.slice(0, toRedirect.length - 1)
    }

    let content = rowData[dataKey]
    let style = {}
    if (typeof content === 'boolean') {
        style = { color: content ? 'green' : 'crimson', fontWeight: 'bold' }
        content = content ? 'True' : 'False'
    }

    if (href) {
        return (
            <Table.Cell style={{ paddingLeft: 3, paddingRight: 3 }} {...rest}>
                <a
                    className="no-text-decor"
                    href={createHref()}
                    onClick={(ev) => ev.preventDefault()}
                >
                    <div style={style} className="table-cell-wrapper">
                        {content}
                    </div>
                </a>
            </Table.Cell>
        )
    }
    return (
        <Table.Cell {...rest}>
            <div style={style} className="table-cell-wrapper">
                {content}
            </div>
        </Table.Cell>
    )
}
