import React from 'react'
import { connect } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

const MobileControls = ({ currentCommand }) => {
    const handleToTimeStamp = () => {
        const element = document.getElementById(currentCommand.time)
        element &&
            element.scrollIntoView({ block: 'center', inline: 'nearest' })
    }
    const handleToTop = () => {
        window.scrollTo({ top: 0 })
    }
    return (
        <>
            <IconButton
                icon={<Icon icon="toggle-up" />}
                onClick={handleToTop}
            />
            <IconButton
                icon={<Icon icon="map-marker" />}
                onClick={handleToTimeStamp}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        position: state.slider.position,
        currentCommand: state.slider.currentCommand,
    }
}

export default connect(mapStateToProps)(MobileControls)
