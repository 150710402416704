import { connect } from 'react-redux'
import { IconButton, Icon } from 'rsuite'
import { setSettingsModalOpen } from '../../actions'
import { PlayerControls, Slider, MobileControls } from '../../components'

import './SideBar.css'

const SideBar = ({ layout, dispatch }) => {
    return (
        <>
            {layout !== 'default' ? (
                <div className="watcher-side-bar-mobile">
                    <div>
                        <Slider />
                    </div>
                    <div className="watcher-mobile-bar-controls">
                        <div className="watcher-control-container-item">
                            <MobileControls />
                        </div>
                        <div className="watcher-control-container-item main-control">
                            <PlayerControls />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="watcher-side-bar">
                    <div className="watcher-left-sidebar-btn-container watcher-sidebar-btn-container">
                        <PlayerControls />
                    </div>
                    <Slider />
                    <div className="watcher-right-sidebar-btn-container watcher-sidebar-btn-container">
                        <IconButton
                            className="watchtower-player-button"
                            style={{ marginRight: 10 }}
                            icon={<Icon icon="cog" />}
                            onClick={() => {
                                dispatch(setSettingsModalOpen(true))
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
/*
 <IconButton
                className="watchtower-player-button"
                style={{ marginRight: 10 }}
                icon={<Icon icon="cog" />}
                onClick={() => {
                    dispatch(setSettingsModalOpen(true))
                }}
            />
*/
const mapStateToProps = (state) => {
    return {
        layout: state.utils.layout,
    }
}

export default connect(mapStateToProps)(SideBar)
