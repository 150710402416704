import {
    SET_ALLOW_SLIDING,
    SET_LAYOUT_TYPE,
    SET_PLAYBACK_SPEED,
    SET_SETTINGS_MODAL_OPEN,
    SET_SHOW_CAMERA_DEBUG,
} from './action-types'

export const setShowCameraDebug = (payload) => (dispatch) => {
    localStorage.setItem('showCameraDebug', payload)
    dispatch({
        type: SET_SHOW_CAMERA_DEBUG,
        payload,
    })
}

export const setLayoutType = (payload) => ({
    type: SET_LAYOUT_TYPE,
    payload,
})

export const setSettingsModalOpen = (payload) => ({
    type: SET_SETTINGS_MODAL_OPEN,
    payload,
})

export const setPlaybackSpeed = (payload) => (dispatch) => {
    localStorage.setItem('playbackSpeed', payload)
    dispatch({
        type: SET_PLAYBACK_SPEED,
        payload,
    })
}

export const setAllowSliding = (payload) => (dispatch) => {
    localStorage.setItem('allowSliding', payload)
    dispatch({
        type: SET_ALLOW_SLIDING,
        payload,
    })
}
