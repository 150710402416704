import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import combineReducers from './Redux/Reducers/combineReducers'

import App from './App'
import './Main.css'
import reportWebVitals from './reportWebVitals'

const locTheme = localStorage.getItem('theme')
export let isRembrain = !(locTheme && locTheme === 'aivero')
import(`./Themes/${isRembrain ? 'rembrain' : 'aivero'}`)

const loggerMiddleware = (store) => (next) => (action) => {
    return next(action)
}

const link = document.createElement('link')

link.rel = 'icon'

const setRebrainColors = () => {
    document.documentElement.style.setProperty('--primaryDark', '#f6f7f7')
    document.documentElement.style.setProperty('--primary', 'white')
    document.documentElement.style.setProperty('--secondary', '#f1f5f6')
    document.documentElement.style.setProperty('--secondaryLight', '#52859e')
    document.documentElement.style.setProperty('--scroll', '#6da0b9')

    document.documentElement.style.setProperty('--text', 'black')
    document.documentElement.style.setProperty('--textNegative', 'white')
    document.documentElement.style.setProperty('--bg', '#e4e4e4')
    document.documentElement.style.setProperty('--border-color', 'white')
    document.documentElement.style.setProperty('--border-width', '0px')
    link.href = '/rembrain.ico'
    document.title = 'Rembrain Console'
}

const setAiveroColors = () => {
    document.documentElement.style.setProperty('--primaryDark', '#f5efef')
    document.documentElement.style.setProperty('--primary', 'white')
    document.documentElement.style.setProperty('--secondary', '#f5efef')
    document.documentElement.style.setProperty('--secondaryLight', '#d4005f')
    document.documentElement.style.setProperty('--scroll', '#bb4f7f')
    document.documentElement.style.setProperty('--text', 'black')
    document.documentElement.style.setProperty('--textNegative', 'white')
    document.documentElement.style.setProperty('--bg', '#e4e4e4')
    document.documentElement.style.setProperty('--border-color', 'white')
    document.documentElement.style.setProperty('--border-width', '0px')
    link.href = '/aivero.ico'
    document.title = 'Aivero Console'
}

if (isRembrain) {
    setRebrainColors()
} else {
    setAiveroColors()
}

document.getElementsByTagName('head')[0].appendChild(link)

export const store = createStore(
    combineReducers,
    applyMiddleware(thunk, loggerMiddleware)
)

const app = (
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'))

reportWebVitals()
