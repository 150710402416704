import { Grid, Row, Breadcrumb } from 'rsuite'
import { connect } from 'react-redux'

import RobotList from './RobotList'
import RobotDetail from './RobotDetails'

const Robots = ({ dispatch, robot }) => {
    return (
        <Grid fluid>
            <div className="breadcrumb-spacer-wrapper">
                <div className="breadcrumb-fixed-wrapper">
                    <div className="test-line new" />
                    <Row className="breadcrumb-row">
                        <div className="test-line breadline" />
                        <Breadcrumb className="breadcrumb">
                            <Breadcrumb.Item
                                onClick={() => {
                                    dispatch({
                                        type: 'ROBOT_CONFIG',
                                        payload: {
                                            robot: '',
                                            robotConfig: {},
                                        },
                                    })
                                }}
                                active={!robot}
                            >
                                <p
                                    className={`breadcrumb-item ${
                                        !robot ? 'breadcrumb-active' : ''
                                    }`}
                                >
                                    Robot List
                                </p>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active={true}>
                                <p
                                    className={`breadcrumb-item ${
                                        true ? 'breadcrumb-active' : ''
                                    }`}
                                >
                                    {robot}
                                </p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                </div>
            </div>

            <Row className="main-layout-row">
                {robot ? <RobotDetail /> : <RobotList />}
            </Row>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        robot: state.robot.robot,
    }
}

export default connect(mapStateToProps)(Robots)
