import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Table, Input } from 'rsuite'

import { getUserList, getUserById, removeUser } from '../../../Redux/Actions'
import { sortTableData } from '../../../Utils'
import {
    CustomCell,
    StickyHeaderRow,
    TableDeleteElement,
} from '../../UtilComponents'

const { Column, HeaderCell, Cell } = Table

const cellList = [
    { name: 'username', label: 'Username' },
    {
        name: 'firstName',
        label: 'First name',
    },
    { name: 'lastName', label: 'Last name' },
    { name: 'email', label: 'Email' },
    {
        name: 'createdTime',
        label: 'Created at',
    },
]

const hrefObj = {
    base: '/users/',
    items: [
        {
            fromData: false,
            addition: '',
            content: 'edit_user',
        },
        {
            fromData: true,
            addition: '',
            content: 'id',
        },
    ],
}

const UserTable = ({ dispatch, userList }) => {
    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')
    const [loading, setLoading] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(null)
    const [nameFilter, setNameFilter] = useState('')

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSortColumn(sortColumn)
            setSortType(sortType)
        }, 500)
    }

    const getData = () => {
        const data = nameFilter
            ? userList.filter((el) => {
                  const filter = nameFilter.toLowerCase()
                  return (
                      el.username.toLowerCase().includes(filter) ||
                      (el.firstName &&
                          el.firstName.toLowerCase().includes(filter)) ||
                      (el.lastName &&
                          el.lastName.toLowerCase().includes(filter)) ||
                      (el.email && el.email.toLowerCase().includes(filter))
                  )
              })
            : userList
        if (sortColumn && sortType) {
            return sortTableData(data, sortColumn, sortType)
        }
        return data
    }

    useEffect(() => {
        dispatch(getUserList())
        const interval = setInterval(() => {
            dispatch(getUserList())
        }, 5000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <>
            <StickyHeaderRow
                {...{ sortColumn, sortType, setSortColumn, setSortType }}
                sortableCells={cellList}
                nonSortableCells={[{ label: 'Delete' }]}
            >
                <Input
                    placeholder="Filter users..."
                    className="list-filter-input"
                    value={nameFilter}
                    onChange={setNameFilter}
                />
                <div className="spacer" />
                <Button
                    className="green-button"
                    href="/users/create_user"
                    onClick={(ev) => {
                        ev.preventDefault()
                        dispatch({
                            type: 'CREATE_USER_OPEN',
                            payload: true,
                        })
                    }}
                >
                    Create User
                </Button>
            </StickyHeaderRow>
            <Table
                showHeader={false}
                autoHeight
                data={getData()}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                autoHeight
                data={getData()}
                onRowClick={(rowData) => dispatch(getUserById(rowData.id))}
            >
                <Column flexGrow={1} sortable align="center" fixed>
                    <HeaderCell />
                    <CustomCell href={hrefObj} dataKey="username" />
                </Column>
                <Column flexGrow={1} sortable align="center" fixed>
                    <HeaderCell />
                    <CustomCell href={hrefObj} dataKey="firstName" />
                </Column>
                <Column flexGrow={1} sortable align="center" fixed>
                    <HeaderCell />
                    <CustomCell href={hrefObj} dataKey="lastName" />
                </Column>
                <Column flexGrow={1} sortable align="center" fixed>
                    <HeaderCell />
                    <CustomCell href={hrefObj} dataKey="email" />
                </Column>
                <Column flexGrow={1} sortable align="center" fixed>
                    <HeaderCell />
                    <CustomCell href={hrefObj} dataKey="createdTime" />
                </Column>
                <Column width={100} align="center" fixed>
                    <HeaderCell />
                    <Cell>
                        {(rowData) => (
                            <TableDeleteElement
                                label={`Delete ${rowData.username} user?`}
                                id={rowData.id}
                                onAccept={() =>
                                    dispatch(removeUser(rowData.id))
                                }
                                onDecline={() => setDeleteOpen(null)}
                                onIconClick={() => setDeleteOpen(rowData.id)}
                                deleteOpen={deleteOpen}
                            />
                        )}
                    </Cell>
                </Column>
            </Table>
        </>
    )
}

const mapStateToProps = (state) => {
    return { userList: state.user.userList }
}

export default connect(mapStateToProps)(UserTable)
