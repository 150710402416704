import _ from 'lodash'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, DatePicker, Input, TagPicker } from 'rsuite'

import { get } from '../../../../Axios'
import { endpoints } from '../../../../Utils'
import { setDataSessionFilter } from '../../../../Redux/Actions'

const DataTableHeader = ({
    dispatch,
    start,
    end,
    filesToPost,
    onPost,
    onRefresh,
    onUpdate,
    sessionName,
    addedBySession,
    setSessionName,
    commandFilter,
    headers,
}) => {
    const [options, setOptions] = useState([])
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        get(endpoints.sessions.getListCommand, null, headers).then((resp) => {
            let newOptions = []
            resp.data.forEach((el) => {
                if (el) {
                    newOptions.push({ value: el, label: el })
                }
            })
            setOptions(newOptions)
        })
    }, [refresh])

    useEffect(() => {
        onRefresh()
        setRefresh(!refresh)
    }, [])

    const numToPost = filesToPost.length
    const disabled = addedBySession.files.length !== 0
    return (
        <div>
            <div className="table-addition table-header">
                <p>From:</p>
                <DatePicker
                    disabled={disabled}
                    value={start}
                    onChange={(date) =>
                        dispatch(setDataSessionFilter('start', date))
                    }
                    format="YYYY-MM-DD HH:mm"
                    size="sm"
                />
                <p>To:</p>
                <DatePicker
                    disabled={disabled}
                    onClose={() => {}}
                    value={end}
                    onChange={(date) =>
                        dispatch(setDataSessionFilter('end', date))
                    }
                    format="YYYY-MM-DD HH:mm"
                    size="sm"
                />
                <div className="spacer" />
            </div>
            <div className="command-filter-container">
                <TagPicker
                    preventOverflow={true}
                    disabled={disabled}
                    data={options}
                    placeholder="Filter by command list"
                    value={commandFilter}
                    onChange={(value) => {
                        dispatch(setDataSessionFilter('command', value))
                    }}
                    renderMenuItem={(label) => {
                        return <div className="tag-picker-item">{label}</div>
                    }}
                    className="data-table-tag-picker"
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Input
                        className="session-name-input"
                        value={sessionName}
                        onChange={setSessionName}
                        placeholder={'Set session name...'}
                    />

                    <Button
                        style={{ width: 180 }}
                        className="session-btn green-button"
                        disabled={
                            addedBySession.id
                                ? sessionName ===
                                  (addedBySession.name
                                      ? addedBySession.name
                                      : null)
                                : numToPost === 0
                        }
                        onClick={addedBySession.id ? onUpdate : onPost}
                    >
                        {addedBySession.id
                            ? `Rename session`
                            : `Add session with ${numToPost} files`}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        checkedFiles: filesToPost,
        start,
        end,
        addedBySession,
        commandFilter,
    } = state.data_session
    return {
        headers: state.auth,
        commandFilter,
        addedBySession,
        end,
        start,
        filesToPost,
    }
}

export default connect(mapStateToProps)(DataTableHeader)
