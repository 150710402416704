import _ from 'lodash'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Input,
    Whisper,
    Tooltip,
    IconButton,
    Icon,
    Loader,
} from 'rsuite'

import { JsonEditor, SortButton } from '../../UtilComponents'
import { sortTableData } from '../../../Utils'
import { getTaskList, deleteTask, updateTask } from '../../../Redux/Actions'

import './TaskList.css'

const NewTaskCard = ({
    task,
    handleDeleteTask,
    setDeleteOpen,
    deleteOpen,
    onFocus,
    isFocused,
    handleConfirm,
    focusedLoading,
}) => {
    const [taskJSON, setTaskJSON] = useState(task.task)
    const [taskError, setTaskError] = useState(null)

    useEffect(() => {
        !isFocused && !focusedLoading && setTaskJSON(task.task)
    }, [task.task, isFocused, focusedLoading])

    return (
        <div
            id={`${task.name}_${task.author}`}
            style={{
                overflow: 'hidden',
                height: 'max-content',
            }}
            className={
                'tasks-item-wrapper' + (isFocused ? ' focused-item' : '')
            }
        >
            <div className="task-item-header">
                <p>{`${task.name}`}</p>
                <p>{`(${task.author})`}</p>
            </div>
            {isFocused && focusedLoading && (
                <div
                    style={{
                        backgroundColor: 'white',
                        opacity: 0.6,
                        position: 'absolute',
                        zIndex: 10000,
                        height: '35vh',
                        width: '320px',
                    }}
                >
                    <div
                        style={{
                            marginTop: '15vh',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Loader size="lg" />
                    </div>
                </div>
            )}
            <JsonEditor
                onFocus={() => onFocus(task.name)}
                style={{
                    height: '35vh',
                    width: '320px',
                    overflow: 'hidden',
                }}
                switcher={taskJSON}
                value={taskJSON}
                onChange={(val) => setTaskJSON(val)}
                onError={(er) => setTaskError(er)}
            />

            <div className="task-item-footer">
                <Whisper
                    placement="left"
                    open={deleteOpen === task.name + task.author}
                    speaker={
                        <Tooltip size="lg">
                            <div>
                                <h6>Delete {task.name} task?</h6>
                            </div>
                            <div>
                                <Button
                                    onClick={(ev) => {
                                        ev.stopPropagation()
                                        handleDeleteTask(task)
                                    }}
                                    className="red-button delete-whisper-btn"
                                >
                                    Confirm
                                </Button>
                                <Button
                                    onClick={(ev) => {
                                        ev.stopPropagation()
                                        setDeleteOpen(null)
                                    }}
                                    className="delete-whisper-btn"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Tooltip>
                    }
                >
                    <IconButton
                        icon={<Icon icon="trash" />}
                        onClick={(ev) => {
                            ev.stopPropagation()
                            const { name, author } = task
                            setDeleteOpen(name + author)
                        }}
                        className="task-footer-btn red-button"
                    />
                </Whisper>
                {isFocused && (
                    <>
                        <IconButton
                            icon={<Icon icon="check" />}
                            disabled={
                                _.isEqual(task.task, taskJSON) || taskError
                            }
                            className="task-footer-btn green-button"
                            onClick={() => {
                                handleConfirm(task, taskJSON)
                            }}
                        ></IconButton>
                        <IconButton
                            disabled={_.isEqual(task.task, taskJSON)}
                            icon={<Icon icon="reload" />}
                            className="task-footer-btn green-button"
                            onClick={() => {
                                setTaskJSON(task.task)
                            }}
                        ></IconButton>
                    </>
                )}
            </div>
        </div>
    )
}

const NewTaskList = ({ tasks, dispatch }) => {
    const [nameFilter, setNameFilter] = useState('')
    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')
    const [deleteOpen, setDeleteOpen] = useState(null)
    const [focusedTask, setFocusedTask] = useState(null)
    const [focusedLoading, setFocusedLoading] = useState(false)

    useEffect(() => {
        dispatch(getTaskList())
        const interval = setInterval(() => {
            dispatch(getTaskList())
        }, 5000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const getData = () => {
        const data = tasks.filter((el) => {
            if (!nameFilter) {
                return true
            } else {
                return (
                    el.name.toLowerCase().includes(nameFilter.toLowerCase()) ||
                    el.author.toLowerCase().includes(nameFilter.toLowerCase())
                )
            }
        })

        if (sortColumn && sortType) {
            return sortTableData(data, sortColumn, sortType)
        } else {
            return sortTableData(data, 'name', 'asc')
        }
    }

    const handleDeleteTask = (data) => {
        setDeleteOpen(null)
        setNameFilter('')
        dispatch(deleteTask(data.author, data.name))
    }

    const handleConfirm = (task, json) => {
        setFocusedLoading(true)
        dispatch(updateTask(task.name, task.author, json)).then(() => {
            dispatch(getTaskList()).then(() => {
                setFocusedLoading(false)
            })
        })
    }

    return (
        <div className="task-list-container">
            <div className="task-controls">
                <Input
                    placeholder="Filter tasks..."
                    className="list-filter-input"
                    value={nameFilter}
                    onChange={setNameFilter}
                />
                <SortButton
                    className="task-sort-btn"
                    name="name"
                    onClick={() => {
                        setSortColumn('name')
                        setSortType(sortType === 'asc' ? 'desc' : 'asc')
                    }}
                    sortType={sortType}
                    sortColumn={sortColumn}
                    label="Name"
                />

                <SortButton
                    className="task-sort-btn"
                    name="author"
                    onClick={() => {
                        setSortColumn('author')
                        setSortType(sortType === 'asc' ? 'desc' : 'asc')
                    }}
                    sortType={sortType}
                    sortColumn={sortColumn}
                    label="Author"
                />

                <div className="spacer" />
                <Button
                    href="/tasks/create_task"
                    className="create-task-btn green-button"
                    onClick={(ev) => {
                        ev.preventDefault()
                        dispatch({
                            type: 'SET_TASK_CREATE_MODAL_OPEN',
                            payload: true,
                        })
                    }}
                >
                    Create task
                </Button>
            </div>

            <div className="task-list-wrapper">
                {getData().map((el) => (
                    <NewTaskCard
                        isFocused={focusedTask === el.name}
                        onFocus={(name) => setFocusedTask(name)}
                        {...{
                            focusedLoading,
                            handleDeleteTask,
                            setDeleteOpen,
                            deleteOpen,
                            handleConfirm,
                        }}
                        task={el}
                    />
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        tasks: state.task.tasks,
        isCreateOpen: state.task.isCreateOpen,
    }
}

export default connect(mapStateToProps)(NewTaskList)
