import { useState, useEffect, useRef } from 'react'
import { Icon, IconButton, Tooltip, Whisper } from 'rsuite'

import { urls } from '../../../Utils'

import './AudioOperator.css'

const concatTypedArrays = (a, b) => {
    var c = new a.constructor(a.length + b.length)
    c.set(a, 0)
    c.set(b, a.length)
    return c
}

const audioContext = new AudioContext()

const RobotAudio = ({ robot, token, removeSelection }) => {
    const [websocket, setWebsocket] = useState(undefined)
    const [sound, setSound] = useState(null)
    const [allowedToPlay, setAllowedToPlay] = useState(false)
    const micWs = useRef(null)

    const unpackData = (f32) => {
        const ab = audioContext.createBuffer(1, f32.length, 32000)
        ab.copyToChannel(f32, 0)
        const source = audioContext.createBufferSource()
        source.buffer = ab
        source.connect(audioContext.destination)
        return source
    }

    const connectWebsocket = () => {
        if (websocket !== undefined) {
            websocket.onopen = () => {
                let controlPacket = {
                    command: 'pull',
                    exchange: 'audio_to_world',
                    access_token: token,
                    robot_name: robot,
                }
                websocket.send(JSON.stringify(controlPacket))
            }

            let f32 = new Float32Array()

            websocket.onmessage = async (ev) => {
                if (ev.data !== 'ping') {
                    f32 = concatTypedArrays(
                        f32,
                        await ev.data
                            .arrayBuffer()
                            .then((arrBuff) => new Float32Array(arrBuff))
                    )
                    if (f32.length >= 4096) {
                        f32 = f32.slice(0, 4096)
                        setSound(unpackData(f32))
                        f32 = new Float32Array()
                    }
                } else {
                    setSound(null)
                }
            }

            websocket.onclose = (ev) => {
                console.log('Socket is closed. Reconnect will be attempted.', {
                    reason: ev.reason,
                })
                setAllowedToPlay(false)
                setSound(null)
                setWebsocket(new WebSocket(urls.webSocketHost))
                connectWebsocket()
            }

            websocket.onerror = (ev) => {
                console.log({ reason: ev.reason })
                websocket.close()
            }
        }
    }

    useEffect(() => {
        websocket && connectWebsocket()
        return () => {
            if (websocket) {
                websocket.onclose = () => {}
                websocket.close()
            }
        }
    }, [websocket])

    useEffect(() => {
        if (robot) {
            websocket && websocket.close()
            setAllowedToPlay(false)
        }
    }, [robot, token])

    useEffect(() => {
        setWebsocket(new WebSocket(urls.webSocketHost))
    }, [])

    useEffect(() => {
        if (allowedToPlay) {
            try {
                sound && sound.start()
            } catch (err) {
                console.log({ err })
            }
        }
    }, [sound, allowedToPlay])

    const [micOn, setMicOn] = useState(false)
    const [micStream, setMicStream] = useState(null)
    const [soundToSend, setSoundToSend] = useState(null)

    useEffect(() => {
        if (
            soundToSend &&
            soundToSend.slice(0, 10).filter((el) => el !== 0).length > 0 &&
            micWs.current.readyState === 1
        ) {
            micWs.current.send(soundToSend)
        }
    }, [soundToSend, micWs])

    useEffect(() => {
        if (micStream) {
            const track = micStream.getTracks()[0]
            if (micOn) {
                micWs.current = new WebSocket(urls.webSocketHost)
                micWs.current.onopen = () => {
                    micWs.current.send(
                        JSON.stringify({
                            command: 'push_loop',
                            exchange: 'audio_from_world',
                            access_token: token,
                            robot_name: robot,
                        })
                    )
                }
                micWs.current.onclose = () => {
                    console.log('CLOSED MIC WS')
                }
            }
            if (!micOn) {
                track.stop()
            }
            return () => {
                micWs.current.close()
            }
        }
    }, [micOn, micStream, micWs])

    const btnPush = (ev) => {
        if (ev.code === 'KeyF') {
            if (!micOn) {
                setMicOn(true)
            }
        }
    }
    const btnRelease = (ev) => {
        if (ev.code === 'KeyF') {
            if (micOn) {
                setMicOn(false)
            }
        }
    }

    useEffect(() => {
        if (micOn) {
            let f32 = new Float32Array()
            navigator.permissions.query({ name: 'microphone' }).then((val) => {
                if (val.state === 'granted') {
                    navigator.mediaDevices
                        .getUserMedia({
                            audio: true,
                        })
                        .then((stream) => {
                            setMicStream(stream)

                            const audioContext = new AudioContext({
                                sampleRate: 32000,
                                channelCount: 1,
                            })
                            const gainNode = audioContext.createGain()
                            gainNode.connect(audioContext.destination)
                            const micStream =
                                audioContext.createMediaStreamSource(stream)
                            audioContext.audioWorklet
                                .addModule('AudioProc.js')
                                .then(() => {
                                    let node = new AudioWorkletNode(
                                        audioContext,
                                        'mic_processor'
                                    )

                                    micStream.connect(node)

                                    node.port.onmessage = (ev) => {
                                        if (f32.length >= 2048) {
                                            f32 = f32.slice(0, 2048)
                                            setSoundToSend(f32)
                                            f32 = new Float32Array()
                                        } else {
                                            f32 = concatTypedArrays(
                                                f32,
                                                ev.data[0][0]
                                            )
                                        }
                                    }
                                })
                                .catch((err) => {
                                    console.log({
                                        location:
                                            'Occured in audioWorklet.addModule',
                                        err,
                                    })
                                })
                        })
                }
            })
        }
        window.addEventListener('keydown', btnPush, false)
        window.addEventListener('keyup', btnRelease, false)
        return () => {
            window.removeEventListener('keydown', btnPush, false)
            window.removeEventListener('keyup', btnRelease, false)
        }
    }, [micOn])

    return (
        <>
            {sound && (
                <div className="audio-container">
                    <IconButton
                        className="audio-btn"
                        style={{ marginRight: 7 }}
                        onClick={(ev) => {
                            removeSelection()
                            ev.stopPropagation()
                        }}
                        size="md"
                        circle
                        icon={<Icon icon="close" />}
                    />
                    <Whisper
                        placement="left"
                        speaker={
                            <Tooltip>
                                <h6>Press and hold "F" to talk</h6>
                            </Tooltip>
                        }
                    >
                        <IconButton
                            size="md"
                            className="audio-btn"
                            circle
                            style={{ marginRight: 7 }}
                            icon={
                                <Icon
                                    icon={
                                        micOn
                                            ? 'microphone'
                                            : 'microphone-slash'
                                    }
                                />
                            }
                        />
                    </Whisper>
                    <IconButton
                        className="audio-btn"
                        onClick={(ev) => {
                            setAllowedToPlay(!allowedToPlay)
                            ev.stopPropagation()
                        }}
                        size="md"
                        circle
                        icon={<Icon icon={allowedToPlay ? 'pause' : 'play'} />}
                    />
                </div>
            )}
        </>
    )
}

export default RobotAudio
