import { Button, Input, InputGroup, Modal } from 'rsuite'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { put } from '../../../Axios'

import { endpoints } from '../../../Utils'

import './PasswordModal.css'

const ChangePasswordModal = ({
    dispatch,
    isOpen,
    user,
    headers,
    currentUser,
}) => {
    const [old_password, setOldPassword] = useState('')
    const [new_password, setNewPassword] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        setOldPassword('')
        setNewPassword('')
        setError('')
    }, [isOpen])

    useState(() => {
        setError('')
    }, [old_password])

    const handleChangePassword = () => {
        let params = {}
        if (user) {
            params.user_id = user.id
        } else {
            params.user_id = currentUser.sub
        }
        put(
            endpoints.updatePassword,
            { old_password, new_password },
            params,
            headers,
            dispatch,
            false
        )
            .then(() =>
                dispatch({
                    type: 'SET_CHANGE_PASSWORD_OPEN',
                    payload: false,
                })
            )
            .catch(() => {
                setError('Failed to change password, try again')
            })
    }

    return (
        <Modal
            size="xs"
            show={isOpen}
            onHide={() =>
                dispatch({
                    type: 'SET_CHANGE_PASSWORD_OPEN',
                    payload: false,
                })
            }
        >
            <Modal.Header closeButton={false}>Change Password</Modal.Header>
            <Modal.Body className="change-password-modal-body">
                <InputGroup>
                    <InputGroup.Addon className="retraining-session-input-addon">
                        New Password
                    </InputGroup.Addon>
                    <Input
                        id="newPasswordInput"
                        value={new_password}
                        onChange={setNewPassword}
                        type="password"
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Addon className="retraining-session-input-addon">
                        Old Password
                    </InputGroup.Addon>
                    <Input
                        id="oldPasswordInput"
                        value={old_password}
                        onChange={setOldPassword}
                        type="password"
                    />
                </InputGroup>
                <div className="password-error-msg-wrapper">
                    {error && <p>{error}</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="green-button" onClick={handleChangePassword}>
                    Confirm
                </Button>
                <Button
                    onClick={() =>
                        dispatch({
                            type: 'SET_CHANGE_PASSWORD_OPEN',
                            payload: false,
                        })
                    }
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        isOpen: state.user.changePasswordOpen,
        user: state.user.userToEdit,
        currentUser: state.user.userInfo,
        headers: state.auth,
    }
}

export default connect(mapStateToProps)(ChangePasswordModal)
