import React, { useState, useCallback, useEffect } from 'react'
import { Controlled } from 'react-codemirror2'

import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript'
import './JsonEditor.css'

const listStringMarker = 'LISTSTRINGMARKER'

const objectMorpher = (obj) => {
    const morphedObj = {}

    Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key])) {
            morphedObj[key] = obj[key].map((el) => {
                if (Array.isArray(el)) {
                    return (
                        listStringMarker +
                        JSON.stringify(el)
                            .replace(/\n/g, '')
                            .replace(/  /g, ' ')
                            .replace(/,/g, ', ') +
                        listStringMarker
                    )
                } else {
                    return el
                }
            })
        } else if (
            typeof obj[key] === 'object' &&
            obj[key] !== null &&
            obj[key] !== undefined
        ) {
            morphedObj[key] = objectMorpher(obj[key])
        } else {
            morphedObj[key] = obj[key]
        }
    })
    return morphedObj
}

export const JsonEditor = (props) => {
    const {
        value = {},
        switcher,
        onChange = () => {},
        error = '',
        showInnerError = true,
        serializer = (value) => JSON.parse(value),
        deserializer = (json) => {
            let newJson = {}
            if (json) {
                newJson = objectMorpher(json)
            }
            const deserialized = JSON.stringify(newJson, null, '  ')
                .replace(new RegExp('"' + listStringMarker, 'g'), '')
                .replace(new RegExp(listStringMarker + '"', 'g'), '')
                .replace(/\\"/g, '"')

            return deserialized
        },
        onError = () => {},
        style = null,
        readonly = false,
        onFocus = () => {},
    } = props

    const [text, setText] = useState('')
    const [errorText, setErrorText] = useState(error)
    useEffect(() => {
        setErrorText(error)
    }, [error])

    useEffect(() => {
        onError(false)
        clearErrorText()
        setText(() => deserializer(value))
    }, [switcher])

    const clearErrorText = useCallback(() => {
        setErrorText('')
        onError(false)
    }, [setErrorText])

    const handleChange = useCallback(
        (v) => {
            setText(v)
            try {
                onChange(serializer(v), errorText)
                showInnerError && clearErrorText()
            } catch (e) {
                onError(true)
                showInnerError && setErrorText(`${e.name}:${e.message}`)
            }
        },

        [setText, serializer]
    )

    return (
        <div style={style} className="json-input-textarea">
            <Controlled
                onFocus={onFocus}
                value={text}
                onBeforeChange={(editor, data, value) => {
                    !readonly && handleChange(value)
                }}
                className="code-editor"
                options={{
                    lineWrapping: true,
                    lint: true,
                    mode: { name: 'javascript', json: true },
                    autocorrect: true,
                    lineNumbers: true,
                }}
            />

            {errorText && <div className="json-editor-error">{errorText}</div>}
        </div>
    )
}
