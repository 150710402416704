import { get } from '../../Axios'
import { endpoints } from '../../Utils'

export const getProcessors = () => (dispatch, getState) =>
    get(endpoints.api.processors, null, getState().auth, dispatch).then(
        ({ data }) => {
            dispatch({
                type: 'PROCESSORS',
                payload: data.answer,
            })
        }
    )

export const getConnections = () => (dispatch, getState) => {
    get(endpoints.api.wsConnections, null, getState().auth, dispatch).then(
        ({ data }) => {
            dispatch({
                type: 'GET_CONNECTIONS',
                payload: data.answer,
            })
        }
    )
}
