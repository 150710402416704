import { Button, Input, InputGroup, Modal } from 'rsuite'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { post, remove } from '../../../Axios'
import { urls } from '../../../Utils'
import axios from 'axios'

const AirflowAuthModal = ({
    isOpen,
    onClose,
    headers,
    dispatch,
    onSuccess,
}) => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const [address, setAddress] = useState('')

    useEffect(() => {
        setError(false)
    }, [login, password, address])

    const handleAirflowAuth = () =>
        post(
            '/sessions/train_user',
            { username: login, password },
            null,
            headers,
            dispatch,
            false
        ).then(() =>
            axios
                .get(urls.apiHost + '/sessions/train', {
                    params: { url: address + '/api/v1/dags' },
                    headers,
                })
                .then(() => {
                    localStorage.setItem('address', address)
                    onSuccess()
                })
                .catch((err) => {
                    if (err && err.response && err.response.status) {
                        setError(
                            err.response.status === 500
                                ? 'Incorrect airflow address'
                                : 'Incorrect login or/and password'
                        )
                    } else {
                        setError('Something went wrong, try again later')
                    }
                    remove(
                        '/sessions/train_user',
                        null,
                        headers,
                        dispatch,
                        false
                    )
                })
        )

    return (
        <Modal size="xs" show={isOpen} onHide={onClose}>
            <Modal.Header closeButton={false}>
                <h5>Retraining Authorization</h5>
            </Modal.Header>
            <Modal.Body
                style={{ gap: 7, display: 'flex', flexDirection: 'column' }}
            >
                <InputGroup>
                    <InputGroup.Addon className="airflow-input-addon">
                        Login
                    </InputGroup.Addon>
                    <Input value={login} onChange={setLogin} />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Addon className="airflow-input-addon">
                        Password
                    </InputGroup.Addon>
                    <Input
                        value={password}
                        onChange={setPassword}
                        type="password"
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Addon className="airflow-input-addon">
                        Address
                    </InputGroup.Addon>
                    <Input value={address} onChange={setAddress} />
                </InputGroup>
                <div className="password-error-msg-wrapper">
                    {error && <p>{error}</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="green-button"
                    disabled={!login || !password || !address || error}
                    onClick={handleAirflowAuth}
                >
                    Confirm
                </Button>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        headers: state.auth,
    }
}

export default connect(mapStateToProps)(AirflowAuthModal)
