import { Grid, Row, Col } from 'rsuite'

import CreateSession from './CreateSession'
import SessionList from './SessionList'
import SessionConfig from './SessionConfig'

import './RetrainingSession.css'

const TrainSession = () => {
    return (
        <>
            <div
                style={{ marginLeft: '0px' }}
                className="test-line system-line fixed"
            />
            <Grid className="main-layout-grid" fluid>
                <Row></Row>
                <Row className="main-layout-row retraining-row">
                    <Col xs={1} />
                    <Col xs={22}>
                        <SessionList />
                    </Col>
                    <Col xs={1} />
                </Row>
            </Grid>
            <CreateSession />
            <SessionConfig />
        </>
    )
}

export default TrainSession
