import { connect } from 'react-redux'

import './Info.css'
import { JsonEditor } from '../../../UtilComponents'

const Info = ({ currentCamera, currentState }) => {
    return (
        <div style={{ marginBottom: 20 }} className="watcher-container">
            <div className="watcher-sensor-container">
                <div className="watcher-sensor-subcontainer">
                    <h3>State</h3>
                    <JsonEditor
                        readonly={true}
                        style={{ height: '20vh', width: '95%' }}
                        switcher={currentState}
                        value={currentState.val ? currentState.val.status : {}}
                    />
                </div>
                <div className="watcher-sensor-subcontainer">
                    <h3>Camera0</h3>
                    <JsonEditor
                        switcher={currentCamera}
                        readonly={true}
                        style={{ height: '20vh', width: '95%' }}
                        value={
                            currentCamera.val ? currentCamera.val.status : {}
                        }
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { currentCamera, currentState } = state.slider
    return {
        currentCamera,
        currentState,
    }
}

export default connect(mapStateToProps)(Info)
