import { endpoints } from '../../Utils'
import { get } from '../../Axios'

export const setMarkerRobot = (robot) => (dispatch, getState) =>
    get(endpoints.api.config, { robot }, getState().auth, dispatch).then(
        ({ data }) => {
            dispatch({ type: 'SET_MARKER_ROBOT', payload: robot })
            dispatch({
                type: 'SET_MARKER_ROBOT_CONFIG',
                payload: JSON.parse(data.answer.value),
            })
        }
    )

export const setMarkerTask = (task) => ({
    type: 'SET_MARKER_TASK',
    payload: task,
})

export const setUserBin = (bin) => ({ type: 'SET_USER_BIN', payload: bin })

export const setUserBinCreation = (bool) => ({
    type: 'SET_USER_BIN_CREATION',
    payload: bool,
})

export const setUserBinParams = (params) => ({
    type: 'SET_USER_BIN_PARAMS',
    payload: params,
})
