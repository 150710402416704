import React from 'react'
import { SortButton, Checkbox } from '../SortButton'
import './StickyHeaderRow.css'

export const CustomHeaderRow = ({
    sortColumn = '',
    sortType = '',
    setSortColumn = () => {},
    setSortType = () => {},
    cells = [],
    paddingStyle = {},
    checkBox = null,
}) => {
    return (
        <div className="custom-header-row">
            <div style={paddingStyle}>
                {cells.map((cell) => {
                    if (cell.label) {
                        if (cell.name) {
                            return (
                                <SortButton
                                    className="custom-header-sort-button"
                                    name={cell.name}
                                    onClick={() => {
                                        setSortColumn(cell.name)
                                        setSortType(
                                            sortType === 'asc' ? 'desc' : 'asc'
                                        )
                                    }}
                                    sortType={sortType}
                                    sortColumn={sortColumn}
                                    label={cell.label}
                                />
                            )
                        } else {
                            return (
                                <div
                                    style={
                                        cell.width
                                            ? {
                                                  width: cell.width,
                                              }
                                            : { flexGrow: 1 }
                                    }
                                    className="custom-header-sort-button"
                                >
                                    {cell.label}
                                </div>
                            )
                        }
                    }
                })}
                {checkBox && (
                    <>
                        <div className="custom-header-sort-button">
                            {React.cloneElement(checkBox)}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export const StickyHeaderRow = ({
    sortColumn,
    sortType,
    setSortColumn,
    setSortType,
    sortableCells = [],
    nonSortableCells = [],
    top = 43,
    children,
}) => {
    return (
        <div className="sticky-table-header" style={{ top }}>
            <div className="header-content-wrapper">{children}</div>
            <div className="custom-header-row">
                <div>
                    {sortableCells.map((cell) => (
                        <SortButton
                            className="custom-header-sort-button"
                            name={cell.name}
                            onClick={() => {
                                setSortColumn(cell.name)
                                setSortType(sortType === 'asc' ? 'desc' : 'asc')
                            }}
                            sortType={sortType}
                            sortColumn={sortColumn}
                            label={cell.label}
                        />
                    ))}
                </div>
                {nonSortableCells.map((cell) => (
                    <div style={{ width: 100 }} className="non-sortable-custom">
                        {cell.label}
                    </div>
                ))}
            </div>
        </div>
    )
}
