import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    IconButton,
    Modal,
    Table,
    Icon,
    Button,
    Input,
    InputGroup,
} from 'rsuite'

import {
    addDataSession,
    editDataSessionWeight,
    getDataSessionList,
    removeDataSession,
} from '../../../../Redux/Actions'
import { sortTableData } from '../../../../Utils'
import { CustomCell, headerStyle } from '../../../UtilComponents'

const { Column, HeaderCell, Cell } = Table

const WeightInput = ({ data, onChange }) => {
    return (
        <input
            value={data.weight}
            onChange={(ev) => {
                const e = ev.target.value
                if (!isNaN(e)) {
                    if (e >= 0 && e <= 1) {
                        onChange(e)
                    }
                }
            }}
            style={{ width: 60 }}
            type="number"
            step={0.1}
            max={1}
            min={0}
        />
    )
}

const AddDataSessions = ({
    dispatch,
    sessionList,
    addedDataSessions,
    onNextStep,
    onComplete,
}) => {
    useEffect(() => {
        dispatch(getDataSessionList())
    }, [])

    useEffect(() => {
        const isDisabled =
            addedDataSessions.length === 0 ||
            addedDataSessions.filter((el) => !el.weight).length > 0
        setDisabled(isDisabled)
        onComplete(!isDisabled)
    }, [addedDataSessions])

    const [disabled, setDisabled] = useState(true)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState('')
    const [addingById, setAddingById] = useState(false)
    const [toAddId, setToAddId] = useState(null)
    const [tData, setTData] = useState([])
    const [firstUpd, setFirstUpd] = useState(true)

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSortColumn(sortColumn)
            setSortType(sortType)
        }, 500)
    }

    const getData = () => {
        let data = !addingById
            ? sessionList.filter((el) => {
                  if (filter) {
                      const name = el.name ? el.name : ''
                      return el.id.includes(filter) || name.includes(filter)
                  } else {
                      return true
                  }
              })
            : addedDataSessions.filter(
                  (el) =>
                      sessionList.findIndex((elem) => elem.id === el.id) === -1
              )
        if (sortColumn && sortType) {
            data = sortTableData(data, sortColumn, sortType)
        }
        setTData(data)
    }

    useEffect(() => {
        setTimeout(() => {
            getData()
        }, 300)
    }, [sessionList, sortType, sortColumn, addingById, addedDataSessions])

    return (
        <>
            <Modal.Body
                style={{ width: '35vw' }}
                className="custom-scrollbar add-sessions-modal-body"
            >
                <div className="table-tabs-wrapper">
                    <div className="add-session-filter-wrapper">
                        {!addingById ? (
                            <Input
                                value={filter}
                                onChange={setFilter}
                                className="add-session-filter"
                                placeholder="Data session filter ..."
                            />
                        ) : (
                            <InputGroup>
                                <Input
                                    id="SessionCreateIdInput"
                                    value={toAddId}
                                    onChange={setToAddId}
                                    placeholder="Session ID ..."
                                />
                                <InputGroup.Button
                                    onClick={() => {
                                        if (toAddId) {
                                            dispatch(
                                                addDataSession({ id: toAddId })
                                            )
                                            setToAddId(null)
                                        }
                                    }}
                                    className={
                                        toAddId
                                            ? 'green-button'
                                            : 'green-button-disabled'
                                    }
                                    disabled={!toAddId}
                                >
                                    <p className="negative-text">Add session</p>
                                </InputGroup.Button>
                            </InputGroup>
                        )}
                    </div>
                    <div>
                        <Button
                            disabled={!addingById}
                            onClick={() => setAddingById(false)}
                            className="table-tab"
                        >
                            Add from list
                        </Button>
                        <Button
                            disabled={addingById}
                            onClick={() => setAddingById(true)}
                            className="table-tab"
                        >
                            Add by ID
                        </Button>
                    </div>
                </div>

                <Table
                    onDataUpdated={() => {
                        if (firstUpd) {
                            setLoading(true)
                            setFirstUpd(false)
                            setTimeout(() => {
                                getData()
                                setLoading(false)
                            }, 500)
                        }
                    }}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    loading={loading}
                    autoHeight
                    data={tData}
                >
                    <Column width={60} align="center">
                        <HeaderCell style={headerStyle}>
                            {addedDataSessions.length + ' Added'}
                        </HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <div className="table-cell-wrapper">
                                    <IconButton
                                        onClick={(ev) => {
                                            addedDataSessions.findIndex(
                                                (el) => el.id === rowData.id
                                            ) === -1
                                                ? dispatch(
                                                      addDataSession(rowData)
                                                  )
                                                : dispatch(
                                                      removeDataSession(rowData)
                                                  )
                                        }}
                                        style={{
                                            backgroundColor:
                                                addedDataSessions.findIndex(
                                                    (el) => el.id === rowData.id
                                                ) === -1
                                                    ? 'var(--green)'
                                                    : 'var(--secondaryLight)',
                                        }}
                                        size="xs"
                                        icon={
                                            <Icon
                                                icon={
                                                    addedDataSessions.findIndex(
                                                        (el) =>
                                                            el.id === rowData.id
                                                    ) === -1
                                                        ? 'plus'
                                                        : 'minus'
                                                }
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </Cell>
                    </Column>
                    <Column align="center" sortable flexGrow={1}>
                        <HeaderCell style={headerStyle}>Name</HeaderCell>
                        <CustomCell dataKey="name" />
                    </Column>
                    <Column align="center" sortable flexGrow={1}>
                        <HeaderCell style={headerStyle}>ID</HeaderCell>
                        <CustomCell dataKey="id" />
                    </Column>
                    <Column align="center" flexGrow={1}>
                        <HeaderCell style={headerStyle}>Weight</HeaderCell>
                        <Cell>
                            {(rowData) => {
                                const idx = addedDataSessions.findIndex(
                                    (el) => el.id === rowData.id
                                )

                                if (idx !== -1) {
                                    return (
                                        <div className="table-cell-wrapper">
                                            <WeightInput
                                                data={addedDataSessions[idx]}
                                                onChange={(v) =>
                                                    dispatch(
                                                        editDataSessionWeight(
                                                            rowData.id,
                                                            v
                                                        )
                                                    )
                                                }
                                            />
                                        </div>
                                    )
                                } else {
                                    return <div />
                                }
                            }}
                        </Cell>
                    </Column>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        dispatch({
                            type: 'SET_CREATE_SESSION_OPEN',
                            payload: false,
                        })
                    }}
                >
                    Close
                </Button>
                <Button
                    onClick={() => onNextStep(1)}
                    disabled={disabled}
                    className={'green-button'}
                >
                    Next Step
                </Button>
            </Modal.Footer>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        sessionList: state.data_session.sessionList,
        addedDataSessions: state.retraining_session.addedDataSessions,
    }
}

export default connect(mapStateToProps)(AddDataSessions)
