const initialState = {
    processors: [],
    systemMonitoringRoute: 'processors',
    connections: [],
}

export default function ProcessorsReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case 'GET_CONNECTIONS':
            return {
                ...state,
                connections: payload,
            }
        case 'PROCESSORS':
            return {
                ...state,
                processors: payload,
            }
        case 'SET_SYSTEM_MONITORING_ROUTE':
            return {
                ...state,
                systemMonitoringRoute: payload,
            }
        default:
            return state
    }
}
