import { Grid, Row } from 'rsuite'

import TaskList from './TaskList'
import CreateTask from './CreateTask'

const Tasks = () => {
    return (
        <Grid fluid>
            <div className="test-line system-line fixed" />
            <Row className="fixed">
                <CreateTask />
            </Row>
            <Row className="main-layout-row">
                <TaskList />
            </Row>
        </Grid>
    )
}

export default Tasks
