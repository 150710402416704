import React from 'react'
import { connect } from 'react-redux'
import { Form, FormGroup, ControlLabel, ButtonToolbar, Button } from 'rsuite'

import { auth } from '../../Redux/Actions'

import './Auth.css'

class Auth extends React.Component {
    state = {
        isFormValid: true,
        formControls: {
            username: {
                value: '',
                name: 'username',
                label: 'Username',
            },
            password: {
                value: '',
                name: 'password',
                label: 'Password',
            },
        },
    }

    loginHandler = () => {
        this.props
            .auth(
                this.state.formControls.username.value,
                this.state.formControls.password.value
            )
            .then((resp) => {
                this.setState({ isFormValid: resp })
            })
    }

    onChangeHandler = (value, controlName) => {
        const formControls = { ...this.state.formControls }
        const control = { ...formControls[controlName] }

        control.value = value

        formControls[controlName] = control
        let isFormValid = true

        this.setState({
            formControls,
            isFormValid,
        })
    }

    renderInputs(style) {
        return Object.keys(this.state.formControls).map(
            (controlName, index) => {
                const control = this.state.formControls[controlName]
                return (
                    <FormGroup style={style} key={index}>
                        <ControlLabel>
                            <h4>{control.label}</h4>
                        </ControlLabel>
                        <input
                            type={
                                control.name === 'password'
                                    ? 'password'
                                    : 'text'
                            }
                            className="auth-inputs"
                            name={control.name}
                            value={control.value}
                            onChange={(ev) =>
                                this.onChangeHandler(
                                    ev.target.value,
                                    controlName
                                )
                            }
                        />
                    </FormGroup>
                )
            }
        )
    }

    render() {
        const styleMarginBot5 = { marginBottom: 5 }

        return (
            <div style={{ width: '20vw' }} className="login-page auth">
                <div className="auth-container">
                    <Form>
                        {this.renderInputs(styleMarginBot5)}
                        <FormGroup>
                            <ButtonToolbar>
                                <Button
                                    className="watchtower-player-button"
                                    onClick={this.loginHandler}
                                >
                                    <p style={{ color: 'var(--testNegative)' }}>
                                        Sign in
                                    </p>
                                </Button>
                            </ButtonToolbar>
                        </FormGroup>
                    </Form>
                </div>
                <div
                    className="auth-error-message"
                    style={{
                        visibility: this.state.isFormValid ? 'hidden' : 'unset',
                    }}
                >
                    Invalid username and/or password.
                    <br /> Try again please
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        auth: (username, password) =>
            dispatch(auth(username, password)).then(
                () => true,
                () => false
            ),
    }
}

export default connect(null, mapDispatchToProps)(Auth)
