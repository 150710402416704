import { Button, Icon, IconButton, Whisper, Tooltip } from 'rsuite'

export const TableDeleteElement = ({
    label,
    id,
    deleteOpen,
    onIconClick,
    onAccept,
    onDecline,
    className,
}) => {
    return (
        <Whisper
            placement="left"
            open={deleteOpen === id}
            speaker={
                <Tooltip size="lg">
                    <div>
                        <h6>{label}</h6>
                    </div>
                    <div>
                        <Button
                            onClick={(ev) => {
                                ev.stopPropagation()
                                onAccept()
                            }}
                            className="red-button delete-whisper-btn"
                        >
                            Confirm
                        </Button>
                        <Button
                            onClick={(ev) => {
                                ev.stopPropagation()
                                onDecline()
                            }}
                            className="delete-whisper-btn"
                        >
                            Cancel
                        </Button>
                    </div>
                </Tooltip>
            }
        >
            <div className={`table-cell-wrapper ${className}`}>
                <IconButton
                    onClick={(ev) => {
                        ev.stopPropagation()
                        onIconClick()
                    }}
                    size="xs"
                    icon={<Icon icon="trash" />}
                />
            </div>
        </Whisper>
    )
}
