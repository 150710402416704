const initialState = {
    isOpen: false,
    robotsToFollow: [],
    robotsToLightUp: [],
}

export default function NotificationReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case 'SET_NOTIFICATIONS_MODAL_OPEN':
            return {
                ...state,
                isOpen: payload,
            }
        case 'SET_ROBOTS_TO_FOLLOW':
            return {
                ...state,
                robotsToFollow: payload,
            }
        case 'SET_ROBOTS_TO_LIGHT_UP':
            return {
                ...state,
                robotsToLightUp: payload,
            }
        default:
            return state
    }
}
