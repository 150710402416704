import {
    TiArrowUnsorted,
    TiArrowSortedUp,
    TiArrowSortedDown,
} from 'react-icons/ti'

export const SortButton = ({
    sortColumn,
    sortType,
    label,
    onClick,
    name,
    ...rest
}) => {
    return (
        <div {...rest} onClick={onClick}>
            <p className="non-selectable">{label}</p>
            {sortColumn !== name ? (
                <TiArrowUnsorted />
            ) : sortType === 'asc' ? (
                <TiArrowSortedUp />
            ) : (
                <TiArrowSortedDown />
            )}
        </div>
    )
}
