import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

import { isRembrain } from '../../../index'
import LogoutModal from '../LogoutModal'
import logo from './aivero-logo.png'

import './Header.css'

const Header = ({ userInfo }) => {
    const [logoutModal, setLogoutModal] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        )
    }, [])

    return (
        <div className="header-spacer">
            <div className="header-container">
                {isRembrain ? (
                    <>
                        <h2 className="header-rem">Rem</h2>
                        <h2 className="header-brain">Brain</h2>
                    </>
                ) : (
                    <img
                        src={logo}
                        style={{ marginLeft: 50 }}
                        className="header-img-logo"
                    />
                )}

                <div className="spacer" />
                <LogoutModal
                    logoutModal={logoutModal}
                    setLogoutModal={setLogoutModal}
                />

                {isMobile ? (
                    <IconButton
                        className="logout-button"
                        size="lg"
                        onClick={() => setLogoutModal(true)}
                        icon={
                            <Icon
                                className="logout-icon"
                                size="4x"
                                icon="sign-out"
                            />
                        }
                        placement="right"
                    />
                ) : (
                    <IconButton
                        className="logout-button"
                        size="lg"
                        onClick={() => setLogoutModal(true)}
                        icon={
                            <Icon
                                className="logout-icon"
                                size="4x"
                                icon="sign-out"
                            />
                        }
                        placement="right"
                    >
                        {userInfo && userInfo.email}
                    </IconButton>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.user.userInfo,
    }
}

export default connect(mapStateToProps)(Header)
