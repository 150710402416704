import {
    SET_SLIDER_MAX,
    SET_SLIDER_MIN,
    SET_SLIDER_POSITION,
} from '../slider/action-types'
import {
    SET_FRAMES,
    SET_COMMANDS,
    CLEAN_SLIDER,
    SET_CAMERA_DEBUG,
} from './action-types'
import _ from 'lodash'
import { setSliderPos } from '../../actions'

export const setCameraDebug = (info) => ({
    type: SET_CAMERA_DEBUG,
    payload: info,
})

export const setFrames = (frames) => (dispatch) => {
    dispatch({ type: SET_FRAMES, payload: frames })
    if (frames.length > 0) {
        dispatch({
            type: SET_SLIDER_MAX,
            payload: frames[frames.length - 1].time,
        })
        dispatch({ type: SET_SLIDER_MIN, payload: frames[0].time })
        dispatch(setSliderPos(frames[0].time, true, false))
    } else {
        dispatch({ type: SET_SLIDER_POSITION, payload: 0 })

        dispatch({
            type: SET_SLIDER_MAX,
            payload: 0,
        })
        dispatch({ type: SET_SLIDER_MIN, payload: 0 })
        dispatch(setSliderPos(0, true, false))
        dispatch({ type: CLEAN_SLIDER })
        dispatch(setCameraDebug({ fps: 0, avDelay: 0 }))
    }
}

export const setCommands = (commandList) => (dispatch) => {
    let newCommands = commandList.map((command) => {
        const sameSecondCommands = commandList.filter(
            (el) => Math.floor(el.time) === Math.floor(command.time)
        )
        console.log(sameSecondCommands)
        if (sameSecondCommands.length > 1) {
            return { time: command.time, group: sameSecondCommands }
        } else {
            return command
        }
    })

    dispatch({
        type: SET_COMMANDS,
        payload: newCommands.filter(
            (obj, index, self) =>
                index ===
                self.findIndex(
                    (el) => Math.floor(el.time) === Math.floor(obj.time)
                )
        ),
    })
}
