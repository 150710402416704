import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'rsuite'

import {
    createRetrainingSession,
    editRetrainingSession,
} from '../../../../Redux/Actions'
import CreationFirstStep from './CreationFirstStep'
import CreationSecondStep from './CreationSecondStep'

import './SessionCreation.css'

const CreateSession = ({ isOpen, dispatch, editSessionId }) => {
    const [step, setStep] = useState(1)
    const [isFirstComplete, setFirstComplete] = useState(false)

    useEffect(() => {
        setStep(1)
    }, [isOpen])

    return (
        <Modal
            className="w-max-content margin-center-h"
            onHide={() => {
                dispatch({
                    type: 'SET_CREATE_SESSION_OPEN',
                    payload: false,
                })
            }}
            show={isOpen}
        >
            <Modal.Header closeButton={false}>
                <div className="retraining-step-wrapper margin-center-h">
                    {step === 1 ? (
                        <h4>Add Data Sessions</h4>
                    ) : (
                        <h4>Configure Retraining Session</h4>
                    )}
                </div>
            </Modal.Header>
            {step === 1 && (
                <CreationFirstStep
                    onComplete={setFirstComplete}
                    onNextStep={(v) => setStep(step + v)}
                />
            )}
            {step === 2 && (
                <CreationSecondStep
                    handleCreateSession={() => {
                        editSessionId
                            ? dispatch(editRetrainingSession())
                            : dispatch(createRetrainingSession())
                    }}
                    isFirstComplete={isFirstComplete}
                    onNextStep={(v) => setStep(step + v)}
                />
            )}
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        isOpen: state.retraining_session.createIsOpen,
        editSessionId: state.retraining_session.editSessionId,
    }
}

export default connect(mapStateToProps)(CreateSession)
