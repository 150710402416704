import { Pagination } from 'rsuite'

const DataTableFooter = ({
    dispatch,
    sessionPage,
    sessionPagesTotal,
    addedBySession,
}) => {
    return (
        <div className="table-footer table-addition">
            {addedBySession.files.length === 0 && (
                <Pagination
                    activePage={sessionPage}
                    pages={sessionPagesTotal}
                    maxButtons={5}
                    ellipsis={true}
                    boundaryLinks={true}
                    onSelect={(ev) =>
                        dispatch({ type: 'SET_PAGE', payload: ev })
                    }
                />
            )}
            <div className="spacer" />
        </div>
    )
}

export default DataTableFooter
