const initialState = {
    robots: [],
    robot: '',
    robotConfig: {},
    robotAllSensors: {},
    isCreateOpen: false,
    watchtowerRobots: [],
}

export default function TaskReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case 'GET_WATCHTOWER_ROBOT_LIST':
            return {
                ...state,
                watchtowerRobots: payload,
            }
        case 'CREATE_ROBOT_MODAL_OPEN':
            return {
                ...state,
                isCreateOpen: payload,
            }
        case 'ROBOTS':
            return {
                ...state,
                robots: payload,
            }
        case 'ROBOT_CONFIG':
            return {
                ...state,
                robotConfig: payload.config,
                robot: payload.robot,
            }
        case 'ROBOT_SENSORS_ALL':
            return {
                ...state,
                robotAllSensors: payload,
            }
        default:
            return state
    }
}
