import { urls } from '../Utils'
import {
    setCurrentPlayable,
    setWatcherRobot,
} from '../Components/Watchtower/actions'

import {
    getUserById,
    setMarkerRobot,
    getDataSessionFiles,
    getDataSessionList,
    setFileToView,
    getRetrainigSessionByID,
    setSessionStage,
    setRetrainingRobot,
    getTask,
    getTaskList,
    getRobotSensorData,
    getRobotConfig,
} from '../Redux/Actions'

export const validPathList = [
    'auth',
    'system_monitoring',
    'robots',
    'tasks',
    'retraining',
    'watchtower',
    'web_operators',
    'users',
    'robot_subscription',
]

export const appMapDispatchToProps = (dispatch) => {
    return {
        dispatch: (f) => dispatch(f),
        actions: {
            get_auth: () => {},
            get_system_monitoring: (path) => {
                const route = path[2]
                route &&
                    dispatch({
                        type: 'SET_SYSTEM_MONITORING_ROUTE',
                        payload: route,
                    })
            },
            get_robots: (path) => {
                const robot = path[2]
                if (robot === 'create_robot') {
                    dispatch({
                        type: 'CREATE_ROBOT_MODAL_OPEN',
                        payload: true,
                    })
                } else if (robot) {
                    dispatch(getRobotConfig(robot))
                    dispatch(getRobotSensorData(robot))
                } else {
                    dispatch({
                        type: 'ROBOT_CONFIG',
                        payload: { robot: '', robotConfig: {} },
                    })
                }
            },
            get_tasks: (path) => {
                const data = path[2]
                if (data === 'create_task') {
                    dispatch({
                        type: 'SET_TASK_CREATE_MODAL_OPEN',
                        payload: true,
                    })
                } else if (data) {
                    let [name, author] = data
                        .replace('name=', '')
                        .split('&author=')
                    author = author ? author : ''
                    dispatch(getTask(name, author))
                } else {
                    dispatch({
                        type: 'TASK',
                        payload: null,
                    })
                }
            },
            get_retraining: (path) => {
                const stage = path[2]
                if (stage) {
                    dispatch(setSessionStage(stage))
                    const data = {}
                    path.slice(3).forEach((el) => {
                        const eqIdx = el.indexOf('=')
                        data[el.slice(0, eqIdx)] = el.slice(eqIdx + 1)
                    })
                    if (stage === 'data_session') {
                        data.robot
                            ? dispatch(setRetrainingRobot(data.robot))
                            : dispatch(setRetrainingRobot(null))
                        data.id
                            ? dispatch(getDataSessionFiles(data.id))
                            : dispatch({
                                  type: 'ADD_FILES_FROM_SESSION',
                                  payload: { files: [] },
                              })

                        if (!data.robot && !data.id) {
                            dispatch({
                                type: 'FILES',
                                payload: {
                                    files: [],
                                    number_page: 1,
                                    total_pages: 0,
                                },
                            })
                        }

                        data.file
                            ? dispatch(
                                  setFileToView(
                                      data.file.replace(/%2f/g, '/'),
                                      data.id ? data.id : false
                                  )
                              )
                            : dispatch({
                                  type: 'SET_FILE_MODAL_OPEN',
                                  payload: false,
                              })
                    } else if (stage === 'retraining_session') {
                        if (
                            data.create_sessio &&
                            data.create_sessio === 'create_session'
                        ) {
                            dispatch({
                                type: 'SET_CREATE_SESSION_OPEN',
                                payload: true,
                            })
                        } else {
                            dispatch(getDataSessionList())
                            data.id
                                ? dispatch(getRetrainigSessionByID(data.id))
                                : dispatch({
                                      type: 'SET_TRAIN_SESSION',
                                      payload: null,
                                  })
                        }
                    }
                }
            },
            get_watchtower: (path) => {
                const [robot, file] = path.slice(2)
                if (robot) {
                    dispatch(setWatcherRobot(robot))
                    file
                        ? dispatch(
                              setCurrentPlayable({
                                  path: file.replace(/%2f/g, '/'),
                              })
                          )
                        : dispatch(setCurrentPlayable(null))
                } else {
                    dispatch(setWatcherRobot(null))
                }
            },
            get_web_operators: (path) => {
                const data = path[2]
                if (data) {
                    const [operator, robot] = data.split('&robot=')
                    if (operator === 'marker') {
                        dispatch(getTaskList())
                        dispatch(getRobotConfig(robot))
                    }
                    Promise.all([
                        dispatch(setMarkerRobot(robot)),
                        dispatch(getRobotConfig(robot)),
                    ]).then(() => {
                        dispatch({
                            type: 'SET_SELECTED_ROBOT',
                            payload: robot,
                        })

                        dispatch({
                            type: 'SET_WEB_OPERATOR',
                            payload: operator,
                        })
                    })
                } else {
                    dispatch({
                        type: 'SET_SELECTED_ROBOT',
                        payload: null,
                    })
                    dispatch({
                        type: 'SET_WEB_OPERATOR',
                        payload: null,
                    })
                }
            },
            get_users: (path) => {
                if (path[2] === 'create_user') {
                    dispatch({
                        type: 'CREATE_USER_OPEN',
                        payload: true,
                    })
                } else {
                    dispatch({
                        type: 'CREATE_USER_OPEN',
                        payload: false,
                    })
                }
                if (path[2] && path[2].split('&')[0] === 'edit_user') {
                    dispatch(getUserById(path[2].split('&')[1]))
                } else {
                    dispatch({
                        type: 'SET_USER_TO_EDIT',
                        payload: null,
                    })
                }
            },
        },
    }
}

export const appMapStateToProps = (state) => {
    const { fileToView, addedBySession, retrainingRobot, sessionStage } =
        state.data_session

    const { currentSession } = state.retraining_session
    const { selected, operator } = state.webOperator
    const { currentPlayable, robot } = state.playlist
    return {
        isAuthenticated: !!state.auth.Authorization && !!state.auth.Refresh,
        robot: state.robot.robot,
        task: state.task.task,
        isAdmin: state.user.isAdmin,
        trainStage: sessionStage,
        trainRobot: retrainingRobot,
        trainFile: fileToView ? fileToView.path : null,
        trainSession: currentSession ? currentSession.training_session : null,
        trainDataSet:
            addedBySession && addedBySession.id ? addedBySession.id : null,
        operator: selected && operator ? operator + '&robot=' + selected : null,
        watchtowerRobot: robot,
        watchtowerFile:
            currentPlayable && currentPlayable.path
                ? currentPlayable.path.replace(/\//g, '%2f')
                : null,
        createUser: state.user.createModalOpen,
        headers: state.auth,
        editUser: state.user.userToEdit,
        systemMonitoringRoute: state.processor.systemMonitoringRoute,
        isCreateOpenTask: state.task.isCreateOpen,
        isCreateOpenRobot: state.robot.isCreateOpen,
        isCreateOpenRetraining: state.retraining_session.createIsOpen,
    }
}

export const setEnvs = () => {
    if (window.REACT_APP_HTTP_GATE_URL === undefined) {
        window.REACT_APP_HTTP_GATE_URL = process.env.REACT_APP_HTTP_GATE_URL
        window.REACT_APP_WEB_SOCKET_GATE_URL =
            process.env.REACT_APP_WEB_SOCKET_GATE_URL
        window.SERVER_OFFSET = process.env.REACT_APP_SERVER_OFFSET
    }

    const apiEnv = window.REACT_APP_HTTP_GATE_URL
    const wsEnv = window.REACT_APP_WEB_SOCKET_GATE_URL

    if (apiEnv && wsEnv) {
        if (apiEnv.slice(0, 4) === 'int/') {
            urls.apiHost = apiEnv.slice(4, apiEnv.length)
        } else {
            urls.apiHost =
                'http://' +
                window.location.hostname +
                apiEnv.slice(4, apiEnv.length)
        }

        if (wsEnv.slice(0, 4) === 'int/') {
            urls.webSocketHost = wsEnv.slice(4, wsEnv.length)
        } else {
            urls.webSocketHost =
                'ws://' +
                window.location.hostname +
                wsEnv.slice(4, wsEnv.length)
        }
    }
}
