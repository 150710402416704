import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Input, InputGroup, Modal, InputPicker } from 'rsuite'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'

import { createUser } from '../../../Redux/Actions'
import { roleOptions } from '../UserDetails'
import './CreateUser.css'

const CreateUser = ({ dispatch, showModal }) => {
    const [role, setRole] = useState(null)
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setEmail('')
        setRole(null)
        setUsername('')
        setPassword('')
        setVisible(false)
    }, [showModal])

    const handleCreate = () => {
        const user = {
            username,
            password,
            email,
            role: role ? role.toLowerCase() : 'default',
        }

        dispatch(createUser(user)).then((resp) => {
            if (resp) {
                dispatch({
                    type: 'CREATE_USER_OPEN',
                    payload: false,
                })
            } else {
                setEmailError(true)
            }
        })
    }

    return (
        <Modal
            size="xs"
            show={showModal}
            onHide={() =>
                dispatch({
                    type: 'CREATE_USER_OPEN',
                    payload: false,
                })
            }
        >
            <Modal.Header>
                <h4>Registration</h4>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="create-user-input-group">
                    <InputGroup.Addon>Username :</InputGroup.Addon>
                    <Input
                        id="createUserUsername"
                        value={username}
                        onChange={(v) => setUsername(v)}
                    />
                </InputGroup>
                <InputGroup className="create-user-input-group">
                    <InputGroup.Addon>Password :</InputGroup.Addon>
                    <Input
                        id="createUserPassword"
                        value={password}
                        type={visible ? 'text' : 'password'}
                        onChange={(v) => setPassword(v)}
                    />
                    <InputGroup.Button
                        style={{ backgroundColor: 'var(--secondary)' }}
                        onClick={() => setVisible(!visible)}
                    >
                        {visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                    </InputGroup.Button>
                </InputGroup>
                <InputGroup className="create-user-input-group">
                    <InputGroup.Addon
                        className={emailError ? 'email-error-addon' : ''}
                    >
                        Email :
                    </InputGroup.Addon>
                    <Input
                        id="createUserEmail"
                        className={emailError ? 'email-error-input' : ''}
                        value={email}
                        onChange={(v) => {
                            setEmailError(false)
                            setEmail(v)
                        }}
                    />
                </InputGroup>
                <InputGroup className="create-user-input-group">
                    <InputGroup.Addon>Role :</InputGroup.Addon>
                    <InputPicker
                        id="createUserRole"
                        onChange={(v) => setRole(v)}
                        value={role}
                        menuClassName="input-picker-menu"
                        data={roleOptions}
                        className="role-input-picker"
                    />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={handleCreate}
                    className="green-button"
                    disabled={!username || !email || !password || emailError}
                >
                    Create
                </Button>
                <Button
                    onClick={() =>
                        dispatch({
                            type: 'CREATE_USER_OPEN',
                            payload: false,
                        })
                    }
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        showModal: state.user.createModalOpen,
    }
}

export default connect(mapStateToProps)(CreateUser)
