import { connect } from 'react-redux'
import { Grid, Row, Col } from 'rsuite'

import UserList from './UserList'
import CreateUser from './CreateUser'
import UserDetails from './UserDetails'

const Users = ({ isAdmin }) => {
    return (
        <div>
            {isAdmin ? (
                <>
                    <Grid fluid>
                        <div className="breadcrumb-spacer-wrapper">
                            <div className="breadcrumb-fixed-wrapper">
                                <div className="test-line system-line" />
                            </div>
                        </div>
                        <Row className="main-layout-row margin-center-h">
                            <Col xs={1}></Col>
                            <Col style={{ paddingBottom: 200 }} xs={22}>
                                <UserList />
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                    </Grid>
                    <CreateUser />
                    <UserDetails />
                </>
            ) : (
                <Grid className="main-layout-grid" fluid>
                    <Row>
                        <div className="not-allowed-wrapper margin-center-h">
                            <h3>Not enough rights for this path</h3>
                        </div>
                    </Row>
                </Grid>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    return { isAdmin: state.user.isAdmin }
}

export default connect(mapStateToProps)(Users)
