import _ from 'lodash'
import { Button } from 'rsuite'
import { connect } from 'react-redux'
import { useRef, useEffect, useState } from 'react'
import numbers from 'numbers'
import {
    getAverageDepth,
    vectorLength,
    pictureToRobot,
    robotToPicture,
    drawPoint,
    drawLine,
} from '../Utils/utils'
import { getRobotSensorData } from '../../../Redux/Actions'
import { notifyError } from '../../../Axios'

const { add, subtract, multiply, cross, det, divide, norm, transpose } =
    numbers.calculus

const quatToRotMatrix = ({ x, y, z, w }) => {
    return [
        [
            1 - 2 * y ** 2 - 2 * z ** 2,
            2 * x * y - 2 * z * w,
            2 * x * z + 2 * y * w,
        ],
        [
            2 * x * y + 2 * z * w,
            1 - 2 * x ** 2 - 2 * z ** 2,
            2 * y * z - 2 * x * w,
        ],
        [
            2 * x * z - 2 * y * 2,
            2 * y * z + 2 * x * w,
            1 - 2 * x ** 2 - 2 * y ** 2,
        ],
    ]
}

const Stream = ({
    image,
    position,
    depth,
    width,
    height,
    debugInfo,
    status,
    calibration,
    endPosition,
    robot,
    dispatch,
}) => {
    const canvRef = useRef(null)

    const [isLineDrawing, setIsLineDrawing] = useState(false)
    const [lineStartPoint, setLineStartPoint] = useState(null)
    const [lineEndPoint, setLineEndPoint] = useState(null)
    const [points, setPoints] = useState(null)
    const [grabNormal, setGrabNormal] = useState(null)
    const [startMatrix, setStartMatrix] = useState(null)

    const clearStates = () => {
        dispatch(getRobotSensorData(robot))

        setIsLineDrawing(null)
        setLineStartPoint(null)
        setLineEndPoint(null)
        setPoints(null)
        setGrabNormal(null)
        setStartMatrix(null)
    }

    const getMousePos = (ev) => {
        if (canvRef.current) {
            const rect = canvRef.current.getBoundingClientRect()
            return [ev.clientX - rect.left, ev.clientY - rect.top]
        }
    }

    const mouseUpHandler = (ev) => {
        const [x, y] = getMousePos(ev)
        const [z] = depth.crop({ x, y, width: 1, height: 1 }).getPixel(0)
        let lineEndWithDepth

        if (ev.which === 1) {
            setIsLineDrawing(false)
            if (isLineDrawing) {
                setIsLineDrawing(false)

                if (lineEndPoint) {
                    const avDepth = getAverageDepth([x, y], depth, 5, [
                        width,
                        height,
                    ])
                    if (avDepth !== 'err') {
                        lineEndWithDepth = [...lineEndPoint, avDepth]
                    } else {
                        notifyError({}, 'Failed to get point depth')
                        clearStates()
                        return
                    }
                } else {
                    clearStates()
                    return
                }

                const dx = lineEndWithDepth[0] - lineStartPoint[0]
                const dy = lineEndWithDepth[1] - lineStartPoint[1]

                const v0 = subtract(lineEndWithDepth, lineStartPoint)
                let v1 = [-dy, dx, 0]
                let v2 = [dy, -dx, 0]
                let v3 = [-dx, -dy, 0]

                let p1 = add(
                    multiply(vectorLength(v0) / 3 / vectorLength(v1), v1),
                    lineStartPoint
                )
                let p2 = add(
                    multiply(vectorLength(v0) / 3 / vectorLength(v2), v2),
                    lineStartPoint
                )
                let p3 = add(
                    multiply(vectorLength(v0) / vectorLength(v3), v3),
                    lineStartPoint
                )

                const p1Depth = getAverageDepth(p1, depth, 5, [width, height])
                const p2Depth = getAverageDepth(p2, depth, 5, [width, height])
                const p3Depth = getAverageDepth(p3, depth, 5, [width, height])

                if (
                    p1Depth !== 'err' &&
                    p3Depth !== 'err' &&
                    p2Depth !== 'err'
                ) {
                    p1[2] = p1Depth
                    p2[2] = p2Depth
                    p3[2] = p3Depth
                } else {
                    notifyError({}, 'Failed to get point depth')
                    clearStates()
                    return
                }
                const robotPoints = [lineEndWithDepth, p1, p2, p3].map((p) =>
                    pictureToRobot(p, status, calibration)
                )
                let ox = subtract(robotPoints[0], robotPoints[3])

                const Y = subtract(robotPoints[1], robotPoints[2])
                let n = cross(Y, ox)

                let oz = multiply(n, -1)
                let oy = cross(ox, oz)

                ox = divide(ox, norm(ox))
                oy = multiply(divide(oy, norm(oy)), -1)
                oz = divide(oz, norm(oz))

                const matrix = [
                    [ox[0], oy[0], oz[0]],
                    [ox[1], oy[1], oz[1]],
                    [ox[2], oy[2], oz[2]],
                ]
                setStartMatrix(matrix)

                setGrabNormal(
                    robotToPicture(
                        add(
                            pictureToRobot(lineStartPoint, status, calibration),
                            multiply(vectorLength(v0) / vectorLength(n), n)
                        ),
                        status,
                        calibration
                    )
                )

                setPoints([p1, p2, p3])
            }
        }

        redraw()
    }
    const mouseDownHandler = (ev) => {
        dispatch(getRobotSensorData(robot))
        if (ev.which === 1) {
            setGrabNormal(null)
            setStartMatrix(null)
            setPoints(null)
            setIsLineDrawing(true)
            const [x, y] = getMousePos(ev)
            const avDepth = getAverageDepth([x, y], depth, 5, [width, height])
            if (avDepth !== 'err') {
                setLineStartPoint([x, y, avDepth])
                setLineEndPoint(null)
                redraw()
            } else {
                notifyError({}, 'Failed to get point depth')
                clearStates()
            }
        }
    }
    const mouseMoveHandler = (ev) => {
        if (isLineDrawing) {
            const [x, y] = getMousePos(ev)
            setLineEndPoint([x, y])
        }
    }
    const contextMenuHandler = (ev) => {
        ev.preventDefault()
        if (ev.which == '3') {
            clearStates()
            redraw()
        }
    }

    const redraw = () => {
        const canvas = canvRef.current
        if (!canvas) return

        const context = canvas.getContext('2d')
        context.clearRect(0, 0, width, height)
        context.drawImage(image, 0, 0, width, height)

        points &&
            points.forEach((p) => {
                drawPoint(p, context, 'green', 3)
                if (lineStartPoint) {
                    drawLine(p, lineStartPoint, context, 'green')
                }
            })

        lineEndPoint && drawPoint(lineEndPoint, context, 'crimson', 2)
        lineStartPoint &&
            lineEndPoint &&
            drawLine(lineStartPoint, lineEndPoint, context, 'crimson')
        lineStartPoint && drawPoint(lineStartPoint, context, 'purple', 4)

        if (grabNormal && lineStartPoint) {
            drawLine(grabNormal, lineStartPoint, context, 'blue')
        }
        if (endPosition) {
            drawPoint(endPosition.start, context, 'blue', 5)
            for (let i = 0; i <= 4; i++) {
                drawLine(
                    endPosition.start,
                    endPosition.points[i],
                    context,
                    i < 4 ? 'purple' : 'blue'
                )
            }
        }
    }
    useEffect(() => {
        const canvas = canvRef.current
        canvas.width = width
        canvas.height = height
        canvas.addEventListener('mouseup', mouseUpHandler, false)
        canvas.addEventListener('mousedown', mouseDownHandler, false)
        canvas.addEventListener('mousemove', mouseMoveHandler, false)
        canvas.addEventListener('contextmenu', contextMenuHandler, false)
        redraw()
        return () => {
            canvas.removeEventListener('mouseup', mouseUpHandler, false)
            canvas.removeEventListener('mousedown', mouseDownHandler, false)
            canvas.removeEventListener('mousemove', mouseMoveHandler, false)
            canvas.removeEventListener('contextmenu', contextMenuHandler, false)
        }
    }, [
        depth,
        image,
        width,
        height,
        isLineDrawing,
        lineStartPoint,
        lineEndPoint,
        points,
        grabNormal,
    ])

    const handleSendCommand = () => {
        console.log({ initialPosMatrix: startMatrix, det: det(startMatrix) })
        console.log({
            quatRotMatrix: quatToRotMatrix(position.orientation),
            det: det(quatToRotMatrix(position.orientation)),
        })
        console.log({
            finalPosMatrix: endPosition.matrix,
            det: det(endPosition.matrix),
        })
    }

    const handleDeleteEndPosition = () => {
        dispatch({ type: 'SET_POINT_MARKER_END_POSITION', payload: null })
        clearStates()
    }
    const handleEndPosition = () => {
        dispatch({
            type: 'SET_POINT_MARKER_END_POSITION',
            payload: {
                matrix: startMatrix,
                start: lineStartPoint,
                points: [...points, lineEndPoint, grabNormal],
            },
        })
        clearStates()
    }

    return (
        <div className="box-marker-stream-container">
            <canvas ref={canvRef} />
            <div className="marker-bottom">
                {endPosition ? (
                    <Button
                        style={{ marginRight: 10 }}
                        onClick={handleDeleteEndPosition}
                    >
                        Delete end position
                    </Button>
                ) : (
                    <Button
                        disabled={!startMatrix}
                        style={{ marginRight: 10 }}
                        onClick={handleEndPosition}
                    >
                        Set end position
                    </Button>
                )}
                <Button
                    disabled={!startMatrix || !endPosition}
                    onClick={handleSendCommand}
                >
                    Send command
                </Button>
            </div>
            {debugInfo && (
                <div className="marker-legend">
                    <h4>Stream Info</h4>
                    <p>{`FPS: ${debugInfo.fps.toFixed(
                        1
                    )} | Average frame delay: ${debugInfo.avDelay.toFixed(
                        3
                    )} | Max frame delay: ${debugInfo.maxDelay.toFixed(3)}`}</p>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { markerRobotConfig } = state.marker

    const { calibration, push_down } = markerRobotConfig
    let invCalibration = calibration.map((el) => el.slice(0, 3))
    invCalibration = multiply(
        1 / det(invCalibration),
        transpose(invCalibration)
    )
    const normal = multiply(invCalibration, [0, 0, -1])

    return {
        calibration,
        pushdown: push_down ? push_down : 1,
        normal,
        position: state.robot.robotAllSensors
            ? state.robot.robotAllSensors.position
            : null,
        robot: state.marker.markerRobot,
        endPosition: state.webOperator.pointMarkerEndPos,
    }
}

export default connect(mapStateToProps)(Stream)
