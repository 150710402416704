const initEndPos = localStorage.getItem('pointMarkerEndPos')

const initialState = {
    selected: null,
    operator: null,
    pointMarkerEndPos: initEndPos ? JSON.parse(initEndPos) : null,
}

export default function WOReducer(state = initialState, action) {
    const { payload, type } = action
    switch (type) {
        case 'SET_POINT_MARKER_END_POSITION':
            payload
                ? localStorage.setItem(
                      'pointMarkerEndPos',
                      JSON.stringify(payload)
                  )
                : localStorage.removeItem('pointMarkerEndPos')

            return {
                ...state,
                pointMarkerEndPos: payload,
            }
        case 'SET_WEB_OPERATOR':
            return {
                ...state,
                operator: payload,
            }

        case 'SET_SELECTED_ROBOT':
            return {
                ...state,
                selected: payload,
            }
        default:
            return state
    }
}
