import axios from 'axios'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Whisper, Tooltip, Loader } from 'rsuite'
import { MdOndemandVideo } from 'react-icons/md'
import { AiFillBug, AiOutlineSound } from 'react-icons/ai'
import { TbSteeringWheel } from 'react-icons/'
import { FaList } from 'react-icons/fa'
import { BiBox } from 'react-icons/bi'
import { BiShapeTriangle } from 'react-icons/bi'
import { GiVrHeadset, GiShipWheel } from 'react-icons/gi'

import AidoOperator from '../AudioOperator'
import { getRobotSensorData, getRobotConfig } from '../../../Redux/Actions'
import { urls } from '../../../Utils'

import './OperatorPannel.css'
import { notifyError } from '../../../Axios'

const SoundOperatorBtn = ({ setOperator }) => {
    return (
        <div className="wo-btn-container">
            <a
                onClick={(ev) => {
                    ev.preventDefault()
                }}
            >
                <Whisper
                    trigger="hover"
                    placement="bottom"
                    speaker={
                        <Tooltip className="web-operator-tooltip">
                            Sound Operator
                        </Tooltip>
                    }
                >
                    <div
                        onClick={(event) => {
                            event.preventDefault()
                            setOperator('sound')
                            event.stopPropagation()
                        }}
                        className="wo-operator-btn"
                    >
                        <AiOutlineSound size="3em" />
                    </div>
                </Whisper>
            </a>
        </div>
    )
}

const VrOperatorBtn = ({ headers, robot }) => {
    return (
        <div className="wo-btn-container">
            <a
                onClick={(ev) => {
                    const url = `${urls.apiHost}/vr/?robot=${robot}&refresh=${headers.Refresh}&authorization=${headers.Authorization}`
                    axios
                        .get(url)
                        .then(() => window.open(url, '_blank'))
                        .catch((VrError) =>
                            notifyError(
                                { VrError },
                                'VR is unavailable for this robot for now'
                            )
                        )
                    ev.stopPropagation()
                }}
            >
                <Whisper
                    trigger="hover"
                    placement="bottom"
                    speaker={
                        <Tooltip className="web-operator-tooltip">
                            VR Operator
                        </Tooltip>
                    }
                >
                    <div className="wo-operator-btn">
                        <GiVrHeadset size="3em" />
                    </div>
                </Whisper>
            </a>
        </div>
    )
}

const OperatorPannel = ({
    robot,
    selected,
    setOperator,
    removeSelection,
    activeSoundRobot,
    dispatch,
    robotsToLightUp,
    isLoading,
    headers,
    setActiveSoundRobot,
    web_operator_list,
}) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const history = useHistory()

    const handleRedirect = () => {
        dispatch(getRobotConfig(robot.name))
        dispatch(getRobotSensorData(robot.name))
        history.push('/robots')
    }

    const handleResize = () => {
        const el = document.getElementById(`wo-item-${robot.name}`)
        if (el) {
            setWidth(el.offsetWidth)
            setHeight(el.offsetHeight)
        }
    }

    useEffect(() => {
        const el = document.getElementById(`wo-item-${robot.name}`)
        if (el) {
            setWidth(el.offsetWidth)
            setHeight(el.offsetHeight)
        }
        window.addEventListener('resize', handleResize, false)
        return () => {
            window.removeEventListener('resize', handleResize, false)
        }
    }, [robot])

    const Icon = ({ name }) => {
        switch (name) {
            case 'stream':
                return <MdOndemandVideo size="3em" />
            case 'driver':
                return <GiShipWheel size="3em" />
            case 'debug':
                return <AiFillBug size="3em" />
            case 'marker':
                return <BiBox size="3em" />
            case 'more':
                return <FaList size="3em" />
            case 'point-marker':
                return <BiShapeTriangle size="3em" />
            default:
                return null
        }
    }

    return (
        <>
            {selected === robot.name && (
                <div
                    onClick={removeSelection}
                    className="wo-backdrop"
                    style={{ width, height }}
                >
                    <div className="flex-icon-container">
                        {web_operator_list ? (
                            web_operator_list.map((name) => {
                                if (name === 'vr') {
                                    return (
                                        <VrOperatorBtn
                                            key={'vr_btn'}
                                            headers={headers}
                                            robot={robot.name}
                                        />
                                    )
                                } else if (name === 'sound') {
                                    return (
                                        <SoundOperatorBtn
                                            key={'sound_btn'}
                                            setOperator={setOperator}
                                        />
                                    )
                                } else {
                                    return (
                                        <div
                                            key={name + '_btn'}
                                            className="wo-btn-container"
                                        >
                                            <a
                                                onClick={(ev) => {
                                                    ev.preventDefault()
                                                }}
                                                href={
                                                    '/web_operators/' +
                                                    name +
                                                    '&robot=' +
                                                    robot.name
                                                }
                                            >
                                                <Whisper
                                                    trigger="hover"
                                                    placement="bottom"
                                                    speaker={
                                                        <Tooltip className="web-operator-tooltip">
                                                            {name
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                name.slice(1) +
                                                                ' Operator'}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div
                                                        onClick={(event) => {
                                                            event.preventDefault()
                                                            setOperator(name)
                                                            event.stopPropagation()
                                                        }}
                                                        className="wo-operator-btn"
                                                    >
                                                        <Icon name={name} />
                                                    </div>
                                                </Whisper>
                                            </a>
                                        </div>
                                    )
                                }
                            })
                        ) : (
                            <div className="empty-list-text">
                                <p>No operators in robot config</p>
                            </div>
                        )}
                        <div className="wo-btn-container">
                            <a
                                onClick={(ev) => {
                                    ev.preventDefault()
                                }}
                                href={'/robots/' + robot.name}
                            >
                                <Whisper
                                    trigger="hover"
                                    placement="bottom"
                                    speaker={
                                        <Tooltip className="web-operator-tooltip">
                                            {'Robot Details'}
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            handleRedirect()
                                        }}
                                        className="wo-operator-btn"
                                    >
                                        <Icon name="more" />
                                    </div>
                                </Whisper>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {activeSoundRobot === robot.name && (
                <AidoOperator
                    token={headers.Authorization}
                    robot={robot.name}
                    removeSelection={() => {
                        setActiveSoundRobot(null)
                    }}
                />
            )}
            {isLoading === robot.name && (
                <div
                    className="wo-pannel-loader-wrapper"
                    style={{ width, height }}
                >
                    <Loader size="md" />
                </div>
            )}
            {robotsToLightUp.indexOf(robot.name) !== -1 && (
                <div
                    className="attention-robot-div"
                    style={{ width, height }}
                />
            )}
            <div
                className="wo-item-name non-selectable"
                style={{ marginLeft: width / 2 - 100 }}
            >
                {robot.name}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { robotsToLightUp } = state.notification
    const { selected } = state.webOperator
    const { robotConfig } = state.robot
    return {
        headers: state.auth,
        selected,
        robotsToLightUp,
        web_operator_list:
            robotConfig && robotConfig.web_operator_list
                ? robotConfig.web_operator_list
                : [],
    }
}

export default connect(mapStateToProps)(OperatorPannel)
