export const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]

export const sortTableData = (data, sortColumn, sortType) =>
    data.sort((a, b) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (!x || x === undefined) {
            x = '0'
        }
        if (!y || y === undefined) {
            y = '0'
        }
        if (sortType === 'asc') {
            return x.localeCompare(y)
        } else {
            return -x.localeCompare(y)
        }
    })

export const getSearchObj = (search) => {
    const searchObj = {}
    const arr = search.split('&')
    arr[0] = arr[0].replace('?', '')
    arr.forEach((el) => {
        const [key, value] = el.split('=')
        searchObj[key] = value
    })
    return searchObj
}
export const assembleSearch = (obj) => {
    let searchString = '?'
    Object.keys(obj).forEach((key) => {
        key && obj[key] && (searchString += '&' + key + '=' + obj[key])
    })
    return searchString.replace('&', '')
}

export const convertDate = (datetime, offset = 0) => {
    const splittedBySpace = datetime.split(' ')
    const splitted = [
        ...splittedBySpace[0].split('-'),
        ...splittedBySpace[2].split('-'),
    ]
    return new Date(
        splitted[0],
        monthNames.indexOf(splitted[1]),
        splitted[2],
        parseInt(splitted[3]) + offset,
        splitted[4],
        splitted[5].slice(0, 2)
    )
}

export const getTimeWithOffset = (value, offset) => {
    const date = new Date(value)
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours() + offset,
        date.getUTCMinutes(),
        date.getUTCSeconds()
    )
}

export const formatDate = (date) => {
    const time = new Date(date)
    const optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric' }
    const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    }
    const newDate = time.toLocaleString('ru', optionsDate)
    const newTime = time.toLocaleString('ru', optionsTime)
    return newDate + '  ' + newTime
}

export const formTimeString = (date, toDisplay = false) => {
    const hours = date.getHours().toString()
    const minutes = date.getMinutes().toString()

    return `${date.getFullYear()}-${
        monthNames[date.getMonth()]
    }-${date.getDate()} : ${hours.length === 1 ? '0' + hours : hours}-${
        minutes.length === 1 ? '0' + minutes : minutes
    }${toDisplay ? '' : '-00.000000'}`
}
