import React, { useEffect, useState, useRef } from 'react'
import { WsHOC } from 'rembrain_streams'
import { Buffer } from 'buffer/'
import { Image as ImageJS } from 'image-js'

const StreamWrapper = (props) => {
    const [lastFrameDates, setLastFrameDates] = useState([])

    const newImage = useRef(new Image(100, 100))

    const [image, setImage] = useState({ img: new Image(100, 100), date: null })
    const [status, setStatus] = useState(null)
    const [depth, setDepth] = useState(null)
    const [debugInfo, setDebugInfo] = useState(null)

    useEffect(() => {
        if (props && props.data && props.data.length > 0) {
            const [dataImage, dataDepth, dataStatus] = props.data
            if (dataImage) {
                try {
                    newImage.current.src =
                        'data:image/jpg;base64,' +
                        Buffer.from(dataImage).toString('base64')
                } catch (e) {
                    console.log(e, 'image')
                }
            }
            if (dataDepth) {
                try {
                    ImageJS.load(dataDepth).then((depthResp) => {
                        const { width, height } = props
                        setDepth(
                            depthResp.resize({
                                width,
                                height,
                            })
                        )
                    })
                } catch (e) {
                    console.log(e, 'depth')
                }
            }
            if (dataStatus) {
                try {
                    setStatus(JSON.parse(dataStatus))
                } catch (e) {
                    console.log(e, 'data')
                }
            }
        }
    }, [props.data])

    useEffect(() => {
        if (
            lastFrameDates &&
            lastFrameDates.length > 0 &&
            image.date !== lastFrameDates[lastFrameDates.length - 1] &&
            image.date !== null
        ) {
            const timeInterval = image.date - lastFrameDates[0]
            if (timeInterval / 1000 >= 5) {
                const frames = [...lastFrameDates, image.date]
                const delayArr = []

                for (let i = 1; i < frames.length; i++) {
                    delayArr.push(
                        frames[i] - frames[i - 1] - timeInterval / frames.length
                    )
                }
                const maxDelay = delayArr.sort((a, b) => a - b).reverse()[0]
                const avDelay = delayArr.reduce((a, b) => a + b) / frames.length
                const fps = 1000 / (timeInterval / frames.length)

                setDebugInfo({ avDelay, fps, maxDelay })

                setLastFrameDates([])
            } else {
                setLastFrameDates([...lastFrameDates, image.date])
            }
        }
    }, [image, lastFrameDates])

    useEffect(() => {
        newImage.current.onload = () => {
            setImage({
                img: newImage.current,
                date: new Date(),
            })
        }
        return () => {
            newImage.current = new Image(100, 100)
        }
    }, [])

    return React.cloneElement(props.stream, {
        image: image.img,
        date: image.date,
        debugInfo,
        status,
        depth,
        width: props.width,
        height: props.height,
    })
}

export default WsHOC(StreamWrapper)
