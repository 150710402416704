const initialState = {
    files: [],
    sessionPage: 1,
    sessionPagesTotal: 0,
    checkedFiles: [],
    start: null,
    end: null,
    commandFilter: null,
    retrainingList: [],
    retrainingRobot: null,
    sessionList: [],
    addedBySession: { files: [] },
    fileToView: { path: null, image: null },
    sessionStage: 'data_session',
    fileModalOpen: false,
    fileJSON: null,
}

export default function DataSessionReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case 'SET_FILE_JSON':
            return {
                ...state,
                fileJSON: payload,
            }
        case 'SET_FILE_MODAL_OPEN':
            return {
                ...state,
                fileModalOpen: payload,
            }
        case 'SET_SESSION_STAGE':
            return {
                ...state,
                sessionStage: payload,
            }
        case 'SET_FILE_TO_VIEW':
            return {
                ...state,
                fileToView: payload,
            }
        case 'SET_COMMAND_FILTER':
            return { ...state, commandFilter: payload }
        case 'ADD_FILES_FROM_SESSION':
            return {
                ...state,
                addedBySession: payload,
            }
        case 'GOT_SESSION_LIST':
            return {
                ...state,
                sessionList: payload,
            }
        case 'SET_RETRAINING_LIST':
            return {
                ...state,
                retrainingList: payload,
            }
        case 'SET_RETRAINING_ROBOT':
            return {
                ...state,
                retrainingRobot: payload,
            }
        case 'FILES':
            return {
                ...state,
                files: payload.files,
                sessionPagesTotal: payload.total_pages,
            }
        case 'SET_COMMAND_FILTER':
            return {
                ...state,
                commandFilter: payload,
            }
        case 'SET_START_VALUE':
            return {
                ...state,
                start: payload,
            }
        case 'SET_END_VALUE':
            return {
                ...state,
                end: payload,
            }
        case 'SET_PAGE':
            return {
                ...state,
                sessionPage: payload,
            }
        case 'CHECKED_FILES':
            return {
                ...state,
                checkedFiles: payload,
            }
        default:
            return state
    }
}
