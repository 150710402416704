import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Icon,
    Input,
    InputGroup,
    InputPicker,
    Modal,
    Tooltip,
    Whisper,
} from 'rsuite'

import { editUser } from '../../../Redux/Actions'
import './UserDetails.css'

export const roleOptions = [
    { value: 'admin', label: 'admin' },
    { value: 'operator', label: 'operator' },
    { value: 'processor', label: 'processor' },
    { value: 'robot', label: 'robot' },
    { value: null, label: 'none' },
]

const EditUser = ({ dispatch, userToEdit, canChangePassword }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [user, setUser] = useState(null)
    const [emailError, setEmailError] = useState(false)

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (userToEdit) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
        setUser({
            ...userToEdit,
            vendor:
                userToEdit &&
                userToEdit.attributes &&
                userToEdit.attributes.vendor
                    ? userToEdit.attributes.vendor
                    : null,
            role:
                userToEdit && userToEdit.groups[0]
                    ? userToEdit.groups[0]
                    : null,
        })
        setIsEditing(false)
        setEmailError(false)
    }, [userToEdit])

    const handleConfirm = () => {
        dispatch(editUser(user)).then((resp) => {
            if (resp) {
                dispatch({
                    type: 'SET_USER_TO_EDIT',
                    payload: null,
                })
            } else {
                setEmailError(true)
            }
        })
    }

    return (
        <Modal
            show={isOpen}
            onHide={() => setIsOpen(false)}
            onExited={() =>
                dispatch({
                    type: 'SET_USER_TO_EDIT',
                    payload: null,
                })
            }
        >
            {userToEdit && (
                <Modal.Header>
                    <h4>{'Username: ' + userToEdit.username}</h4>
                </Modal.Header>
            )}
            {userToEdit && (
                <Modal.Body>
                    {!isEditing ? (
                        <>
                            <div className="user-info-item">
                                <p>
                                    Email:{' '}
                                    {userToEdit.email
                                        ? userToEdit.email
                                        : 'Unknown'}
                                </p>
                                <Whisper
                                    placement="right"
                                    trigger="hover"
                                    delayHide={3}
                                    speaker={
                                        <Tooltip>
                                            {`Email ${
                                                !userToEdit.emailVerified
                                                    ? 'not'
                                                    : ''
                                            } verified`}
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        className="email-icon"
                                        style={{
                                            backgroundColor:
                                                userToEdit.emailVerified
                                                    ? 'var(--green)'
                                                    : 'var(--red)',
                                        }}
                                    >
                                        <Icon
                                            className="negative-text"
                                            icon={
                                                userToEdit.emailVerified
                                                    ? 'check'
                                                    : 'close'
                                            }
                                        />
                                    </div>
                                </Whisper>
                            </div>
                            <>
                                <div className="user-info-item">
                                    <p>
                                        First name:{' '}
                                        {userToEdit.firstName
                                            ? userToEdit.firstName
                                            : 'Unknown'}
                                    </p>
                                </div>
                                <div className="user-info-item">
                                    <p>
                                        Last name:{' '}
                                        {userToEdit.lastName
                                            ? userToEdit.lastName
                                            : 'Unknown'}
                                    </p>
                                </div>
                                <div className="user-info-item">
                                    <p>
                                        Role:{' '}
                                        {userToEdit.groups &&
                                        userToEdit.groups[0]
                                            ? userToEdit.groups[0]
                                            : 'none'}
                                    </p>
                                </div>
                                <div className="user-info-item">
                                    <p>
                                        Vendor:{' '}
                                        {userToEdit.attributes &&
                                        userToEdit.attributes.vendor
                                            ? userToEdit.attributes.vendor
                                            : 'none'}
                                    </p>
                                </div>
                            </>
                        </>
                    ) : (
                        <>
                            <InputGroup className="create-user-input-group">
                                <InputGroup.Addon
                                    className={
                                        emailError ? 'email-error-addon' : ''
                                    }
                                >
                                    Email :
                                </InputGroup.Addon>
                                <Input
                                    id="userEditEmail"
                                    className={
                                        emailError ? 'email-error-input' : ''
                                    }
                                    value={user.email}
                                    onChange={(v) => {
                                        setEmailError(false)
                                        setUser({ ...user, email: v })
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="create-user-input-group">
                                <InputGroup.Addon>
                                    First Name :
                                </InputGroup.Addon>
                                <Input
                                    id="userEditFirstName"
                                    value={user.firstName}
                                    onChange={(v) =>
                                        setUser({ ...user, firstName: v })
                                    }
                                />
                            </InputGroup>
                            <InputGroup className="create-user-input-group">
                                <InputGroup.Addon>Last Name :</InputGroup.Addon>
                                <Input
                                    id="userEditLastName"
                                    value={user.lastName}
                                    onChange={(v) =>
                                        setUser({ ...user, lastName: v })
                                    }
                                />
                            </InputGroup>{' '}
                            <InputGroup className="create-user-input-group">
                                <InputGroup.Addon>Vendor :</InputGroup.Addon>
                                <Input
                                    id="userEditVendor"
                                    value={user.vendor}
                                    onChange={(v) =>
                                        setUser({ ...user, vendor: v })
                                    }
                                />
                            </InputGroup>
                            <InputGroup className="create-user-input-group">
                                <InputGroup.Addon>Role :</InputGroup.Addon>
                                <InputPicker
                                    id="userEditRole"
                                    onChange={(v) =>
                                        setUser({ ...user, role: v })
                                    }
                                    value={user.role}
                                    menuClassName="input-picker-menu"
                                    data={roleOptions}
                                    className="role-input-picker"
                                />
                            </InputGroup>
                        </>
                    )}
                </Modal.Body>
            )}
            <Modal.Footer>
                {!isEditing && canChangePassword && (
                    <Button
                        id="editUserChangePasswordBtn"
                        onClick={() =>
                            dispatch({
                                type: 'SET_CHANGE_PASSWORD_OPEN',
                                payload: true,
                            })
                        }
                    >
                        Change Password
                    </Button>
                )}
                <Button
                    onClick={
                        isEditing ? handleConfirm : () => setIsEditing(true)
                    }
                    className="green-button"
                    id="userEditConfirm"
                    disabled={isEditing ? emailError : false}
                >
                    {isEditing ? 'Confirm' : 'Edit'}
                </Button>

                <Button
                    onClick={
                        isEditing
                            ? () => setIsEditing(false)
                            : () => setIsOpen(false)
                    }
                >
                    {isEditing ? 'Cancel' : 'Close'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const allowChangePassword = (selectedUser, currentUser, currentUserIsAdmin) => {
    if (selectedUser && currentUser) {
        const selectedIsAdmin =
            selectedUser.groups && selectedUser.groups.indexOf('admin') !== -1

        const selectedIsCurrent = selectedUser.id === currentUser.sub

        if (selectedIsCurrent) {
            return true
        } else if (currentUserIsAdmin) {
            return !selectedIsAdmin
        } else {
            return false
        }
    } else {
        return false
    }
}

const mapStateToProps = (state) => {
    const { userToEdit, userInfo } = state.user

    return {
        userToEdit: state.user.userToEdit,
        canChangePassword: allowChangePassword(
            userToEdit,
            userInfo,
            state.user.isAdmin
        ),
    }
}

export default connect(mapStateToProps)(EditUser)
