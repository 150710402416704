import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Whisper, Tooltip } from 'rsuite'

import { endpoints } from '../../../Utils'
import { get } from '../../../Axios'
import { JsonEditor } from '../../UtilComponents'
import AirflowBody from './AirflowBody'
import AirflowAuthModal from './AirflowAuthModal'

const getConfigToDisplay = (trainSession) => {
    let obj = {}

    if (!trainSession) return obj
    Object.keys(trainSession).forEach((key) => {
        if (key !== 'nn_weights') {
            obj[key] = trainSession[key]
        }
    })
    return obj
}

const ViewSessionConfig = ({ currentSession, dispatch, headers }) => {
    const [show, setShow] = useState(false)
    const [configToDisplay, setConfigToDisplay] = useState({})
    const [mode, setMode] = useState('session')
    const [isAirflowLoginOpen, setIsAirflowLoginOpen] = useState(false)

    useEffect(() => {
        setShow(!!currentSession)
        setTimeout(() => {
            setConfigToDisplay(getConfigToDisplay(currentSession))
        }, 400)
    }, [currentSession])

    const handleEdit = () => {
        const respSessionList = currentSession.sessions.map((el) => el.__var)
        const { sessions, nn_weights, training_session, ...sessionConfig } =
            currentSession
        dispatch({
            type: 'SET_RETREAINING_SESSION_TO_EDIT',
            payload: {
                id: currentSession.training_session,
                respSessionList,
                sessionConfig,
            },
        })
        setShow(false)
    }

    const downloadWeight = (w) => {
        const url = endpoints.sessions.nnWeight
        const params = {
            nn_weight: w,
            session_train_id: currentSession.training_session,
        }
        get(url, params, headers, dispatch).then((resp) => {
            const link = document.createElement('a')
            link.href = resp.data
            link.click()
        })
    }

    return (
        <>
            <Modal
                className="retraining-config-modal margin-center-h"
                show={show}
                style={{
                    width: '70vw',
                    minWidth: 550,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                onHide={() => setShow(false)}
                onExited={() => {
                    setMode('session')
                    dispatch({
                        type: 'GET_RETRAINING_SESSION_BY_ID',
                        payload: null,
                    })
                }}
            >
                {currentSession &&
                    (mode === 'session' ? (
                        <>
                            <Modal.Header closeButton={false}>
                                <h4>Retraining Session</h4>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="json-stage-container">
                                    <Whisper
                                        placement="left"
                                        trigger="click"
                                        delayHide={3}
                                        speaker={
                                            <Tooltip>
                                                Session config JSON copied to
                                                your clipboard
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            className="json-btn"
                                            onClick={() => {
                                                if (navigator.clipboard) {
                                                    navigator.clipboard.writeText(
                                                        JSON.stringify(
                                                            getConfigToDisplay(
                                                                currentSession
                                                            ),
                                                            null,
                                                            '  '
                                                        )
                                                    )
                                                }
                                            }}
                                        >
                                            Copy to clipboard
                                        </Button>
                                    </Whisper>

                                    <JsonEditor
                                        style={{
                                            maxHeight: '300px',
                                            width: '100%',
                                        }}
                                        readonly={true}
                                        value={configToDisplay}
                                        switcher={configToDisplay}
                                    />
                                </div>
                                {currentSession.nn_weights &&
                                    currentSession.nn_weights.length > 0 &&
                                    currentSession.nn_weights !== undefined && (
                                        <h6 className="retraining-weights-header w-max-content margin-center-h">
                                            Download Weights
                                        </h6>
                                    )}

                                <div className="nn-weights-container">
                                    {currentSession.nn_weights !== undefined &&
                                        currentSession.nn_weights.map(
                                            (weight) => (
                                                <div
                                                    onClick={() =>
                                                        downloadWeight(weight)
                                                    }
                                                    className="nn-weights-item"
                                                >
                                                    {weight}
                                                </div>
                                            )
                                        )}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    onClick={() => {
                                        get(
                                            '/sessions/train_user',
                                            null,
                                            headers,
                                            dispatch
                                        ).then(({ data }) => {
                                            if (data.answer === true) {
                                                setMode('dag')
                                            } else {
                                                setIsAirflowLoginOpen(true)
                                            }
                                        })
                                    }}
                                    className="green-button"
                                >
                                    Start Retraining
                                </Button>
                                <Button
                                    onClick={handleEdit}
                                    className="green-button"
                                >
                                    Edit
                                </Button>
                                <Button onClick={() => setShow(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </>
                    ) : (
                        <AirflowBody
                            onClose={() => setShow(false)}
                            setMode={setMode}
                        />
                    ))}
            </Modal>
            <AirflowAuthModal
                isOpen={isAirflowLoginOpen}
                onSuccess={() => {
                    setIsAirflowLoginOpen(false)
                    setMode('dag')
                }}
                onClose={() => setIsAirflowLoginOpen(false)}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        currentSession: state.retraining_session.currentSession,
        headers: state.auth,
    }
}

export default connect(mapStateToProps)(ViewSessionConfig)
