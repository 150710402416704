import React, { useEffect, useState } from 'react'
import { Grid, Row } from 'rsuite'
import { connect } from 'react-redux'

import {
    deleteDataSession,
    getDataSessionFilesByRobot,
    getDataSessionRobotList,
    setRetrainingRobot,
} from '../../../Redux/Actions'
import { NavList } from '../../UtilComponents'
import DataTable from './DataTable'
import SessionList from './SessionList'
import FileViewModal from './FileViewModal'

import './DataSession.css'

const DataSession = ({
    dispatch,
    retrainingList,
    retrainingRobot,
    sessionPage,
    fileToView,
}) => {
    const [showWarning, setShowWarning] = useState(false)
    const [sessionName, setSessionName] = useState('')

    useEffect(() => {
        dispatch(getDataSessionRobotList())
    }, [])

    useEffect(() => {
        fileToView.image &&
            dispatch({ type: 'SET_FILE_MODAL_OPEN', payload: true })
    }, [fileToView])

    const handleDelete = (force = false, id) => {
        dispatch(deleteDataSession(id, force)).then((resp) => {
            if (!resp) {
                dispatch(
                    getDataSessionFilesByRobot(sessionPage, retrainingRobot)
                )
                dispatch({
                    type: 'ADD_FILES_FROM_SESSION',
                    payload: { files: [] },
                })
                setSessionName('')
                dispatch({ type: 'CHECKED_FILES', payload: [] })
                setShowWarning(false)
            } else {
                setShowWarning(id)
            }
        })
    }

    return (
        <Grid className="main-layout-grid" fluid>
            <div className="breadcrumb-spacer-wrapper">
                <div className="breadcrumb-fixed-wrapper">
                    <Row>
                        <NavList
                            item_list={retrainingList}
                            placeholder="Retrainable robot list"
                            label_addition="robot"
                            curr_selected={retrainingRobot}
                            onSelectItem={(el) =>
                                dispatch(setRetrainingRobot(el.name))
                            }
                        />
                    </Row>
                </div>
            </div>
            <Row className="main-layout-row retraining-row">
                <div className="flexbox-retraining">
                    <div>
                        <DataTable
                            setSessionName={setSessionName}
                            sessionName={sessionName}
                            showWarning={showWarning}
                            handleDelete={handleDelete}
                            setShowWarning={setShowWarning}
                        />
                    </div>
                    <div>
                        <SessionList handleDelete={handleDelete} />
                    </div>
                </div>
            </Row>

            <FileViewModal />
        </Grid>
    )
}

const mapStateToProps = (state) => {
    const { retrainingList, retrainingRobot, fileToView, sessionPage } =
        state.data_session
    return {
        retrainingList,
        retrainingRobot,
        fileToView,
        sessionPage,
    }
}

export default connect(mapStateToProps)(DataSession)
