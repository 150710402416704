import { useState } from 'react'
import { connect } from 'react-redux'
import { JsonEditor } from '../../../UtilComponents'
import { formTime } from '../Slider'

const EventGroup = ({ item, position, leftPartWidth, layout }) => {
    const [isOpened, setIsOpened] = useState(false)

    return (
        <div id={`group${item.time}`}>
            <div
                onClick={() => setIsOpened(!isOpened)}
                className={`watcher-event-item-header active-header ${
                    item.time <= position
                        ? 'watcher-event-item-active'
                        : 'watcher-event-item-inactive'
                }`}
            >
                {`Group: ${formTime(item.time.toFixed(0))}`}
            </div>

            <div
                style={!isOpened ? { maxHeight: 150 } : {}}
                className="watcher-item-group-body custom-scrollbar"
                onClick={() => setIsOpened(true)}
            >
                {item.group.map((el) => (
                    <div>
                        <div className="watcher-event-item-body">
                            <JsonEditor
                                style={{
                                    width:
                                        layout !== 'mobile'
                                            ? leftPartWidth - 250
                                            : '55vw',
                                }}
                                readonly={true}
                                switcher={el}
                                value={el.val.status}
                            />
                        </div>
                        <div
                            className="separator"
                            style={{
                                width:
                                    layout !== 'mobile'
                                        ? leftPartWidth - 200
                                        : '60vw',
                            }}
                        ></div>
                    </div>
                ))}
            </div>

            <div
                onClick={() => setIsOpened(!isOpened)}
                className={`watcher-event-item-footer active-header ${
                    item.time <= position
                        ? 'watcher-event-item-active'
                        : 'watcher-event-item-inactive'
                }`}
            ></div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return { position: state.slider.position }
}
export default connect(mapStateToProps)(EventGroup)
