import { get, post, put, remove } from '../../Axios'
import { getTimeWithOffset, formTimeString, endpoints, urls } from '../../Utils'

export const setSessionStage = (payload) => ({
    type: 'SET_SESSION_STAGE',
    payload,
})

export const getDataFileJSON = (file) => (dispatch, getState) =>
    get(
        endpoints.sessions.commandJson,
        { file },
        getState().auth,
        dispatch
    ).then(({ data }) => {
        dispatch({ type: 'SET_FILE_JSON', payload: data })
    })

export const setFileToView =
    (path, sessionId = false) =>
    (dispatch, getState) => {
        dispatch(getDataFileJSON(path))
        return fetch(
            urls.apiHost + endpoints.sessions.imageFile(path, sessionId),
            {
                headers: getState().auth,
            }
        ).then((resp) =>
            resp.blob().then((data) => {
                const urlCreator = window.URL || window.webkitURL
                dispatch({
                    type: 'SET_FILE_TO_VIEW',
                    payload: { path, image: urlCreator.createObjectURL(data) },
                })
                return true
            })
        )
    }

export const updateDataSessionFIles = (id, name) => (dispatch, getState) =>
    put(
        endpoints.sessions.sessionData(id),
        name ? { name } : {},
        null,
        getState().auth,
        dispatch
    ).then(() => {
        dispatch(getDataSessionList())
    })

export const deleteDataSession =
    (id, force = false) =>
    (dispatch, getState) =>
        remove(
            endpoints.sessions.sessionData(id),
            { force },
            getState().auth,
            dispatch
        ).then(({ data }) => {
            if (data.answer === false) {
                return true
            } else {
                dispatch(getDataSessionList())
                return false
            }
        })

export const setRetrainingRobot = (robot) => ({
    type: 'SET_RETRAINING_ROBOT',
    payload: robot,
})

export const setDataSessionFilter = (name, value) => (dispatch) => {
    name === 'start' && dispatch({ type: 'SET_START_VALUE', payload: value })
    name === 'end' && dispatch({ type: 'SET_END_VALUE', payload: value })
    name === 'command' &&
        dispatch({
            type: 'SET_COMMAND_FILTER',
            payload: value,
        })
}

export const getDataSessionFiles = (id) => (dispatch, getState) => {
    if (id === undefined) return
    get(
        endpoints.sessions.sessionData(id),
        null,
        getState().auth,
        dispatch
    ).then(({ data }) => {
        dispatch({
            type: 'ADD_FILES_FROM_SESSION',
            payload: data.answer,
        })
    })
}

export const getDataSessionList = () => (dispatch, getState) =>
    get(endpoints.sessions.sessionData(), null, getState().auth, dispatch).then(
        ({ data }) => {
            dispatch({ type: 'GOT_SESSION_LIST', payload: data.answer })
        }
    )

export const getDataSessionRobotList = () => (dispatch, getState) =>
    get(
        endpoints.sessions.getRobotInArchiveCommand,
        null,
        getState().auth,
        dispatch
    )
        .then(({ data }) => {
            dispatch({
                type: 'SET_RETRAINING_LIST',
                payload: data.map((el) => ({ name: el })),
            })
        })
        .catch(() => {
            dispatch({ type: 'SET_RETRAINING_LIST', payload: [] })
        })

export const getDataSessionFilesByRobot =
    (page_num, robot, sortType = null, sortColumn = null) =>
    (dispatch, getState) => {
        if (!robot) return

        const { start, end, commandFilter } = getState().data_session
        let stringParams = `?robot=${robot}&page_num=${page_num ? page_num : 1}`
        let params = null
        if (commandFilter && commandFilter.length > 0) {
            commandFilter.forEach((element) => {
                stringParams += '&command=' + element
            })
        }
        if (start) {
            stringParams +=
                '&start=' +
                formTimeString(
                    getTimeWithOffset(start, parseInt(window.SERVER_OFFSET))
                ).replace(/ /g, '+')
        }
        if (end) {
            stringParams +=
                '&end=' +
                formTimeString(
                    getTimeWithOffset(end, parseInt(window.SERVER_OFFSET))
                ).replace(/ /g, '+')
        }

        if (sortType && sortColumn) {
            params = {
                sort: sortColumn,
                sort_direction: sortType === 'asc' ? 1 : -1,
            }
        }

        get(
            endpoints.sessions.listArchiveFiles(stringParams),
            params,
            getState().auth,
            dispatch
        ).then(({ data }) => {
            dispatch({
                type: 'FILES',
                payload: data.answer,
            })
        })
    }

export const postDataSessionFiles = (files, name) => (dispatch, getState) => {
    const filesToPost = files.map(({ path, command, robot }) => ({
        file: path,
        command: command,
        robot: robot,
    }))

    const data = { files: filesToPost }
    if (name) {
        data.name = name
    }

    post(endpoints.sessions.sessionData(), data, null, getState().auth)
        .then(() => {
            dispatch(getDataSessionList())
        })
        .catch((error) => {
            dispatch({
                type: 'POST_ANSWER',
                payload: `Failed to post, error: ${error}`,
            })
        })
}
