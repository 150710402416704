import { Notification } from 'rsuite'
import { v4 as uuidv4 } from 'uuid'

import { get, put, post, remove } from '../../Axios'
import { endpoints, urls } from '../../Utils'
import { checkLoginAndRefreshOrLogout } from './AuthAction'

export const getRobotList = () => (dispatch, getState) =>
    new Promise((resolve) => {
        get(endpoints.api.robots, null, getState().auth, dispatch).then(
            ({ data }) => {
                if (data) {
                    const robots = data.answer
                    const robotsArray = robots.map((robotName) => {
                        return {
                            ...robotName,
                            enabled: robotName.enabled.toString(),
                            emailVerified: robotName.emailVerified.toString(),
                            name: robotName.username,
                        }
                    })
                    dispatch({
                        type: 'ROBOTS',
                        payload: robotsArray,
                    })
                    resolve(robotsArray)
                } else {
                    resolve([])
                }
            }
        )
    })

export const getRobotConfig = (robot) => (dispatch, getState) =>
    get(endpoints.api.config, { robot }, getState().auth, dispatch)
        .then(({ data }) => {
            const { answer } = data
            dispatch({
                type: 'ROBOT_CONFIG',
                payload: {
                    config:
                        answer && answer.value ? JSON.parse(answer.value) : {},
                    robot,
                },
            })
        })
        .catch(() => {
            dispatch({
                type: 'ROBOT_CONFIG',
                payload: { config: {}, robot: '' },
            })
        })

export const pushRobotCommand =
    (robot, commandJSON, src = 'robots') =>
    async (dispatch, getState) => {
        const headers = getState().auth

        let websocket = new WebSocket(urls.webSocketHost)
        let commandToPost = {}
        let authToken = headers.Authorization
        const { exp } = JSON.parse(atob(authToken.split('.')[1]))
        //some weird shit going on here, investigate later
        /*if (new Date().valueOf() > new Date(exp * 1000 - 60000).valueOf()) {
            const newHeaders = await dispatch(
                checkLoginAndRefreshOrLogout(headers)
            )
            authToken = newHeaders.Authorization
        }*/

        Object.keys(commandJSON).forEach((key) => {
            if (key !== 'use_source') {
                commandToPost[key] = commandJSON[key]
            }
        })

        if (commandJSON.use_source) {
            commandToPost.source = `client ${authToken}`
        }

        const controlPacket = {
            command: 'push',
            exchange: 'commands',
            robot_name: robot,
            message: commandToPost,
            access_token: authToken,
        }

        websocket.onopen = () => {
            websocket.send(JSON.stringify(controlPacket))
        }

        websocket.onerror = (err) => {
            if (src !== 'robots') {
                dispatch({
                    type: 'MARKER_PUSH_RESULT',
                    payload: { text: `Error: ${err}`, id: uuidv4() },
                })
            }

            websocket.close()
        }

        websocket.onmessage = (msg) => {
            Notification.success({
                placement: 'bottomEnd',
                duration: 5000,
                key: uuidv4(),
                title: msg.data,
            })
            websocket.close()
        }

        websocket.onclose = () => {
            console.log('WEBSOCKET_CLOSED')
        }
    }

export const getRobotSensorData = (robot) => (dispatch, getState) =>
    get(
        endpoints.api.extractSensors(robot),
        null,
        getState().auth,
        dispatch,
        false
    )
        .then(({ data }) => {
            dispatch({
                type: 'ROBOT_SENSORS_ALL',
                payload: data.answer,
            })
        })
        .catch(() => {
            dispatch({
                type: 'ROBOT_SENSORS_ALL',
                payload: {
                    Error: `Failed to get robot sensors`,
                },
            })
        })

export const updateRobotConfig = (robot, content) => (dispatch, getState) =>
    put(
        endpoints.api.config,
        { value: content },
        { robot: robot },
        getState().auth,
        dispatch
    )
        .then(() => {
            Notification.success({
                placement: 'bottomEnd',
                duration: 5000,
                key: uuidv4(),
                title: 'Config updated successfully',
            })
            dispatch(getRobotConfig(robot))
        })
        .catch(() => {
            Notification.error({
                placement: 'bottomStart',
                duration: 5000,
                key: uuidv4(),
                title: 'Failed to update config',
            })
        })

export const createRobot = (name, password) => (dispatch, getState) =>
    post(
        endpoints.api.robots,
        { name: name, password: password },
        null,
        getState().auth,
        dispatch
    ).then(() => dispatch(getRobotList()))

export const deleteRobot = (name) => (dispatch, getState) =>
    remove(endpoints.api.robots, { name }, getState().auth, dispatch).then(() =>
        dispatch(getRobotList())
    )

export const getWatcthowerRobotList = () => (dispatch, getState) =>
    get(
        endpoints.stream.robotArchiveStream,
        null,
        getState().auth,
        dispatch
    ).then(({ data }) => {
        dispatch({
            type: 'GET_WATCHTOWER_ROBOT_LIST',
            payload: data.map((el) => ({
                name: el,
            })),
        })
    })
