import {
    SET_CURRENT_PLAYABLE,
    SET_PLAYLIST,
    SET_PLAYLIST_END_DATE,
    SET_PLAYLIST_START_DATE,
    SET_TOTAL_PAGES,
    SET_WATCHER_ROBOT,
    SET_WATCHER_PAGE,
    SET_IS_LOADING,
} from '../actions'

const initialState = {
    playlist: [],
    startDate: null,
    endDate: null,
    currentPlayable: null,
    robot: null,
    page: { page: 1, play: false },
    total_pages: 1,
    isLoading: false,
}

export const playlist = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: payload,
            }
        case SET_TOTAL_PAGES:
            return {
                ...state,
                total_pages: payload,
            }
        case SET_WATCHER_PAGE:
            return {
                ...state,
                page: payload,
            }
        case SET_WATCHER_ROBOT:
            return {
                ...state,
                robot: payload,
            }
        case SET_PLAYLIST:
            return {
                ...state,
                playlist: payload,
            }
        case SET_PLAYLIST_END_DATE:
            return {
                ...state,
                endDate: payload,
            }
        case SET_PLAYLIST_START_DATE:
            return {
                ...state,
                startDate: payload,
            }
        case SET_CURRENT_PLAYABLE:
            return {
                ...state,
                currentPlayable: payload,
            }
        default:
            return state
    }
}
