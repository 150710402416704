import './UtilStyles.css'

export * from './CustomCell'
export * from './JsonEditor'
export * from './NavList'
export * from './SortButton'
export * from './StickyHeaderRow'
export * from './TableDeleteElement'

export const headerStyle = {
    backgroundColor: 'var(--secondaryLight)',
    color: 'var(--primary)',
    fontWeight: 'bold',
}
