import { Grid, Row, Breadcrumb } from 'rsuite'
import { connect } from 'react-redux'

import {
    setCurrentPlayable,
    setWatcherRobot,
    SET_PLAYLIST,
    SET_TOTAL_PAGES,
    SET_WATCHER_PAGE,
} from './actions'

import RobotList from '../Robots/RobotList'
import { FileList, MainLayout } from './components'

const NewWatchtower = ({ dispatch, robot, file }) => {
    const renderBreadcrumbItems = () => {
        const items = [
            <Breadcrumb.Item
                key="breadcrumb_main"
                onClick={() => {
                    dispatch(setWatcherRobot(null))
                    dispatch({
                        type: SET_WATCHER_PAGE,
                        payload: { page: 1, play: false },
                    })
                    dispatch({
                        type: SET_TOTAL_PAGES,
                        payload: 1,
                    })
                    dispatch({ type: SET_PLAYLIST, payload: [] })
                }}
                active={!robot}
            >
                <p
                    className={`breadcrumb-item ${
                        !robot ? 'breadcrumb-active' : ''
                    }`}
                >
                    Robots
                </p>
            </Breadcrumb.Item>,
        ]
        if (robot) {
            items.push(
                <Breadcrumb.Item
                    key="breadcrumb_robot"
                    onClick={() => dispatch(setCurrentPlayable(null))}
                    active={robot && !file}
                >
                    <p
                        className={`breadcrumb-item ${
                            robot && !file ? 'breadcrumb-active' : ''
                        }`}
                    >
                        {robot}
                    </p>
                </Breadcrumb.Item>
            )
        }

        if (file) {
            items.push(
                <Breadcrumb.Item key="breadcrumb_file" active={true}>
                    <p
                        className={`breadcrumb-item ${
                            true ? 'breadcrumb-active' : ''
                        }`}
                    >
                        {file.path}
                    </p>
                </Breadcrumb.Item>
            )
        }
        return items
    }

    return (
        <Grid fluid style={{ paddingBottom: 100 }}>
            <div className="breadcrumb-spacer-wrapper">
                <div className="breadcrumb-fixed-wrapper">
                    <div className="test-line new" />
                    <Row className="breadcrumb-row">
                        <div className="test-line breadline" />
                        <Breadcrumb className="breadcrumb">
                            {renderBreadcrumbItems()}
                        </Breadcrumb>
                    </Row>
                </div>
            </div>

            <Row style={{ marginTop: 20 }} className="main-layout-row">
                {!robot ? (
                    <RobotList isWatchtower={true} />
                ) : !file ? (
                    <FileList />
                ) : (
                    <MainLayout />
                )}
            </Row>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        robot: state.playlist.robot,
        file: state.playlist.currentPlayable,
    }
}

export default connect(mapStateToProps)(NewWatchtower)
