import { useState } from 'react'
import { Nav, Dropdown, Input, Icon } from 'rsuite'

import './NavList.css'

const Lines = ({ operator }) => {
    if (operator === null) {
        return (
            <>
                <div className="test-line one" />
                <div className="test-line two" />
                <div className="test-line system-line" />
                <div className="test-line retraining-line" />
            </>
        )
    } else if (operator === 'stream' || operator === 'debug') {
        return (
            <>
                <div className="test-line three" />
                <div className="test-line two" />
                <div className="test-line five" />
            </>
        )
    } else if (operator === 'marker' || operator === 'point-marker') {
        return (
            <>
                <div className="test-line three" />
            </>
        )
    } else if (operator === 'marker' || operator === 'point-marker') {
        return (
            <>
                <div className="test-line two" />
                <div className="test-line five" />
            </>
        )
    } else {
        return null
    }
}

export const NavList = ({
    onSelectItem,
    curr_selected,
    item_list,
    placeholder = 'Item List',
    label_addition = '',
    operator = null,
}) => {
    const [searchItem, setSearchItem] = useState('')
    const [dropdownOpen, setDropdownOpen] = useState(false)
    return (
        <div className="nav-list-container-container">
            <Lines operator={operator} />
            <Dropdown
                trigger={'click'}
                open={dropdownOpen}
                onToggle={() => {
                    setDropdownOpen(!dropdownOpen)
                }}
                toggleComponentClass={() => (
                    <div
                        onClick={() => setDropdownOpen(true)}
                        className="dropdown-toggle"
                    >
                        <p>{!curr_selected ? placeholder : curr_selected}</p>
                        <Icon icon="down" className="dropdown-icon" />
                    </div>
                )}
                className="sidenav-dropdown"
            >
                <Nav.Item className="navlist-input">
                    <Input
                        className="marker-message-input"
                        placeholder={`Search for ${label_addition}...`}
                        value={searchItem}
                        onChange={setSearchItem}
                    />
                </Nav.Item>

                <div className="navlist-dropdown">
                    {item_list
                        .filter((el) => el.name.includes(searchItem))
                        .map((el) => (
                            <Dropdown.Item
                                style={{ padding: 0 }}
                                className={`nav-list-container-item ${
                                    el.name === curr_selected
                                        ? 'selected-item'
                                        : ''
                                }`}
                                key={el.name}
                                onSelect={() => {
                                    setDropdownOpen(false)
                                    setSearchItem('')
                                    onSelectItem(el)
                                }}
                            >
                                <p>{el.name}</p>
                            </Dropdown.Item>
                        ))}
                </div>
            </Dropdown>
        </div>
    )
}
