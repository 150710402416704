import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Notification } from 'rsuite'

import { get } from '../../Axios'
import { endpoints } from '../../Utils'
import { setRobotsToLightUp, getRobotConfig } from '../../Redux/Actions'

let interval = null

const Notifyer = ({
    robotsToFollow,
    dispatch,
    headers,
    history,
    robotsToLightUp,
}) => {
    const [notified, setNotified] = useState([])

    useEffect(() => {
        let url = endpoints.api.currentUser
        interval = setInterval(() => {
            get(url, null, headers, dispatch).then((resp) => {
                const robots = resp.data.answer.subscription_robot
                //robots[0] = { ...robots[0], state: 'NEED_OPERATOR' }
                robots.forEach((robot) => {
                    if (
                        robot.state === 'NEED_OPERATOR' &&
                        notified.indexOf(robot.name) === -1
                    ) {
                        dispatch(
                            setRobotsToLightUp([...robotsToLightUp, robot.name])
                        )
                        Notification.warning({
                            placement: 'bottomEnd',
                            duration: 0,
                            key: robot.name,
                            description: (
                                <div className="notification-btn-cont">
                                    <Button
                                        onClick={() => {
                                            dispatch(getRobotConfig(robot.name))
                                            dispatch({
                                                type: 'SET_SELECTED_ROBOT',
                                                payload: robot.name,
                                            })
                                            history.push({
                                                pathname: '/web_operators',
                                            })
                                            Notification.close(robot.name)
                                        }}
                                    >
                                        Go to robot
                                    </Button>
                                </div>
                            ),
                            title: `Robot ${robot.name} needs operator attention`,
                            onClose: () => {
                                const idx = robotsToLightUp.indexOf(robot.name)
                                dispatch(
                                    setRobotsToLightUp([
                                        ...robotsToLightUp.slice(0, idx),
                                        ...robotsToLightUp.slice(idx + 1),
                                    ])
                                )

                                setTimeout(() => {
                                    const idx = notified.indexOf(robot.name)
                                    setNotified([
                                        ...notified.slice(0, idx),
                                        ...notified.slice(
                                            idx + 1,
                                            notified.length
                                        ),
                                    ])
                                }, 30000)
                            },
                        })
                        setNotified([...notified, robot.name])
                    }
                })
            })
        }, 3000)
        return () => {
            interval && clearInterval(interval)
        }
    }, [robotsToFollow, notified, robotsToLightUp])
    return null
}

const mapStateToProps = (state) => {
    return {
        robotsToFollow: state.notification.robotsToFollow.map((el) => el.name),
        headers: state.auth,
        robotsToLightUp: state.notification.robotsToLightUp,
    }
}

export default connect(mapStateToProps)(withRouter(Notifyer))
