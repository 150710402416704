import { Button, Loader, Modal } from 'rsuite'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { get } from '../../../Axios'

const DagRunsModal = ({ dispatch, viewDagRuns, headers, onClose }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [dagRuns, setDagRuns] = useState({
        queued: <Loader />,
        running: <Loader />,
        success: <Loader />,
        failed: <Loader />,
    })

    const getDagRuns = () => {
        get(
            '/sessions/train',
            {
                url:
                    localStorage.getItem('address') +
                    '/api/v1/dags/' +
                    viewDagRuns +
                    '/dagRuns',
            },
            headers,
            dispatch
        ).then(({ data }) => {
            let runs = {
                queued: 0,
                running: 0,
                success: 0,
                failed: 0,
            }
            data.answer.dag_runs.forEach((run) => {
                runs[run.state] += 1
            })
            setDagRuns(runs)
        })
    }

    useEffect(() => {
        if (viewDagRuns) {
            getDagRuns()
            setIsOpen(true)
        } else {
            setDagRuns({
                queued: <Loader />,
                running: <Loader />,
                success: <Loader />,
                failed: <Loader />,
            })
        }
    }, [viewDagRuns])

    const getColor = (type) => {
        switch (type) {
            case 'success':
                return 'green'
            case 'failed':
                return 'crimson'
            case 'running':
                return 'var(--secondaryLight)'
            default:
                return 'dimgrey'
        }
    }

    return (
        <Modal
            size="xs"
            show={isOpen}
            onHide={() => setIsOpen(false)}
            onExited={onClose}
        >
            <Modal.Header closeButton={false}>
                <h4>{(viewDagRuns ? viewDagRuns : '') + ' dag runs'}</h4>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5,
                    }}
                >
                    {Object.keys(dagRuns).map((key) => {
                        return (
                            <div style={{ width: '100%' }}>
                                <div
                                    style={{
                                        flexGrow: 1,
                                        fontSize: '3em',
                                        textAlign: 'center',
                                        borderBottom: `3px solid ${getColor(
                                            key
                                        )}`,
                                        color: getColor(key),
                                    }}
                                >
                                    {dagRuns[key]}
                                </div>
                                <div
                                    style={{
                                        flexGrow: 1,
                                        fontSize: '1.1em',
                                        textAlign: 'center',
                                        color: getColor(key),
                                    }}
                                >
                                    {key}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        headers: state.auth,
    }
}

export default connect(mapStateToProps)(DagRunsModal)
