import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Table } from 'rsuite'

import { getConnections } from '../../../Redux/Actions'
import { sortTableData } from '../../../Utils'
import { CustomCell, StickyHeaderRow } from '../../UtilComponents'

const { Column, HeaderCell } = Table

const ConnectionList = ({ dispatch, connections }) => {
    useEffect(() => {
        dispatch(getConnections())
        const interval = setInterval(() => {
            dispatch(getConnections())
        }, 2000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const [nameFilter, setNameFilter] = useState('')
    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')
    const [loading, setLoading] = useState(false)

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSortColumn(sortColumn)
            setSortType(sortType)
        }, 500)
    }

    const getData = () => {
        const data = connections.filter((el) => {
            if (!nameFilter) {
                return true
            } else {
                if (el.username) {
                    return el.username.includes(nameFilter)
                } else {
                    return false
                }
            }
        })
        if (sortColumn && sortType) {
            return sortTableData(data, sortColumn, sortType)
        }
        return data
    }

    return (
        <>
            <StickyHeaderRow
                {...{ sortColumn, sortType, setSortColumn, setSortType }}
                sortableCells={[
                    { name: 'username', label: 'Username' },
                    { name: 'exchange', label: 'Exchange' },
                    { name: 'command', label: 'Command' },
                ]}
            >
                <Input
                    placeholder="Filter by username..."
                    className="list-filter-input"
                    value={nameFilter}
                    onChange={setNameFilter}
                />
                <div className="spacer" />
            </StickyHeaderRow>
            <Table
                showHeader={false}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                autoHeight
                data={getData()}
            >
                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell dataKey="username" />
                </Column>

                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell dataKey="exchange" />
                </Column>

                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell dataKey="command" />
                </Column>
            </Table>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        connections: state.processor.connections,
    }
}

export default connect(mapStateToProps)(ConnectionList)
