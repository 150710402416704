import { connect } from 'react-redux'
import { Button, Icon, Modal, Tooltip, Whisper } from 'rsuite'
import { remove } from '../../../Axios'

import { logout } from '../../../Redux/Actions'

const LogoutModal = ({
    dispatch,
    setLogoutModal,
    logoutModal,
    user,
    headers,
}) => {
    return (
        <Modal show={logoutModal} onHide={() => setLogoutModal(false)}>
            <Modal.Header closeButton={false}>
                <h3 className="margin-center-h w-max-content">User info</h3>
            </Modal.Header>
            <Modal.Body>
                <div className="user-info-item">
                    <p>Email: {user.email ? user.email : 'Unknown'}</p>
                    <Whisper
                        placement="right"
                        trigger="hover"
                        delayHide={3}
                        speaker={
                            <Tooltip>
                                {`Email ${
                                    !user.emailVerified ? 'not' : ''
                                } verified`}
                            </Tooltip>
                        }
                    >
                        <div
                            className="email-icon"
                            style={{
                                backgroundColor: user.emailVerified
                                    ? 'var(--green)'
                                    : 'var(--red)',
                            }}
                        >
                            <Icon
                                className="negative-text"
                                icon={
                                    user.emailVeremoverified ? 'check' : 'close'
                                }
                            />
                        </div>
                    </Whisper>
                </div>

                <div className="user-info-item">
                    <p>
                        First name:{' '}
                        {user.given_name ? user.given_name : 'Unknown'}
                    </p>
                </div>
                <div className="user-info-item">
                    <p>
                        Last name:{' '}
                        {user.family_name ? user.family_name : 'Unknown'}
                    </p>
                </div>
                <div className="user-info-item">
                    <p>
                        Role:{' '}
                        {user.groups && user.groups[0]
                            ? user.groups[0]
                            : 'none'}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() =>
                        dispatch({
                            type: 'SET_CHANGE_PASSWORD_OPEN',
                            payload: true,
                        })
                    }
                >
                    Change Password
                </Button>
                <Button
                    onClick={() => {
                        remove('/sessions/train_user', null, headers, dispatch)
                        setLogoutModal(false)
                    }}
                >
                    Retraining Logout
                </Button>
                <Button
                    className="red-button"
                    onClick={() => dispatch(logout())}
                >
                    Log out
                </Button>
                <Button onClick={() => setLogoutModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return { user: state.user.userInfo, headers: state.auth }
}

export default connect(mapStateToProps)(LogoutModal)
