import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, InputGroup, Input, Modal } from 'rsuite'

import { createTask } from '../../../Redux/Actions'

import './CreateTask.css'

const CreateTaskModal = ({ dispatch, isCreateOpen }) => {
    const [taskName, setTaskName] = useState('')
    const [taskAuthor, setTaskAuthor] = useState('')

    useEffect(() => {
        setTaskAuthor('')
        setTaskName('')
    }, [isCreateOpen])

    return (
        <Modal
            size="xs"
            show={isCreateOpen}
            onHide={() =>
                dispatch({
                    type: 'SET_TASK_CREATE_MODAL_OPEN',
                    payload: false,
                })
            }
        >
            <Modal.Header closeButton={false}>
                <h5>Create Task</h5>
            </Modal.Header>
            <Modal.Body className="create-task-modal-body">
                <InputGroup>
                    <InputGroup.Addon style={{ width: '5em' }}>
                        Name
                    </InputGroup.Addon>
                    <Input
                        id="createTaskNameInput"
                        value={taskName}
                        onChange={setTaskName}
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Addon style={{ width: '5em' }}>
                        Author
                    </InputGroup.Addon>
                    <Input
                        id="createTaskAuthorInput"
                        value={taskAuthor}
                        onChange={setTaskAuthor}
                    />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        dispatch(createTask(taskName, taskAuthor))
                        dispatch({
                            type: 'SET_TASK_CREATE_MODAL_OPEN',
                            payload: false,
                        })
                    }}
                    className="green-button"
                >
                    Create
                </Button>
                <Button
                    onClick={() =>
                        dispatch({
                            type: 'SET_TASK_CREATE_MODAL_OPEN',
                            payload: false,
                        })
                    }
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        isCreateOpen: state.task.isCreateOpen,
    }
}

export default connect(mapStateToProps)(CreateTaskModal)
