const initialState = {
    Authorization: null,
    Refresh: null,
}

export default function AuthReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case 'AUTH_AND_REFRESH':
            return {
                ...state,
                Authorization: payload.access_token,
                Refresh: payload.refresh_token,
            }
        case 'LOGOUT':
            return {
                ...state,
                Authorization: null,
                Refresh: null,
            }
        default:
            return state
    }
}
