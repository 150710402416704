import { useEffect } from 'react'
import { connect } from 'react-redux'
import ReactSlider from 'react-rangeslider'

import {
    setSliderPos,
    setPlayerState,
    previous,
    increaseSlider,
} from '../../actions'

import './RangeSlider.css'
import './Slider.css'

export const formTime = (sec) => {
    let minutes = Math.floor(sec / 60)
    let seconds = sec - minutes * 60
    minutes = minutes.toString()
    seconds = seconds.toString()
    seconds.length <= 1 && (seconds = '0' + seconds)
    minutes.length <= 1 && (minutes = '0' + minutes)
    return minutes + ' : ' + seconds
}

const Slider = ({
    position,
    dispatch,
    player_state,
    sliderMax,
    sliderMin,
    disabledPrev,
}) => {
    const handleEnd = () => {
        dispatch(setSliderPos(position, true, false))
    }
    const handleChange = (value) => {
        dispatch(setSliderPos(value, true, true))
    }

    const onKeyPress = (ev) => {
        if (ev.key === ' ') {
            player_state === 'pause' && dispatch(setPlayerState('play'))
            player_state === 'play' && dispatch(setPlayerState('pause'))
        }
    }

    const onKeyDown = (ev) => {
        switch (ev.key) {
            case 'ArrowRight':
                dispatch(setSliderPos(position + 1, true, false))
                return
            case 'ArrowLeft':
                if (position <= 0) {
                    !disabledPrev && dispatch(previous())
                } else {
                    dispatch(setSliderPos(position - 1, true, false))
                }
                return
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown, false)
        return () => {
            window.removeEventListener('keydown', onKeyDown, false)
        }
    }, [position])

    useEffect(() => {
        window.addEventListener('keypress', onKeyPress, false)

        return () => {
            window.removeEventListener('keypress', onKeyPress, false)
        }
    }, [player_state])

    useEffect(() => {
        let interval
        if (sliderMax > 0) {
            if (player_state === 'play') {
                interval = setInterval(() => {
                    dispatch(increaseSlider())
                }, 100)
            }
        } else {
            dispatch(setPlayerState('empty'))
            clearInterval(interval)
        }
        return () => {
            clearInterval(interval)
        }
    }, [player_state, sliderMax])

    return (
        <div className="watcher-slider-wrapper">
            <div className="watcher-slider-time">
                {formTime(position.toFixed(0))}
            </div>
            <div className="watcher-slider-container">
                <ReactSlider
                    min={sliderMin}
                    step={0.01}
                    format={() => position.toFixed(1)}
                    max={sliderMax}
                    value={position}
                    onChange={handleChange}
                    onChangeComplete={handleEnd}
                    tooltip={false}
                />
            </div>
            <div className="watcher-slider-time">
                {formTime(sliderMax.toFixed(0))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { position, player_state, sliderMax, sliderMin } = state.slider
    const { page, playlist, currentPlayable } = state.playlist

    return {
        position,
        player_state,
        sliderMax,
        sliderMin,
        disabledPrev:
            playlist.length > 0 && currentPlayable
                ? playlist[0].path === currentPlayable.path && page.page === 1
                : true,
    }
}

export default connect(mapStateToProps)(Slider)
