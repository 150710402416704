import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'rsuite'

import {
    getCurrentUser,
    setModalOpen,
    setRobotsToFollow,
    getRobotList,
} from '../../Redux/Actions'
import Notifyer from './Notifyer'

import './RobotSubscriptions.css'

const NotificationsModal = ({ isOpen, dispatch, robots, robotsToFollow }) => {
    const [toAdd, setToAdd] = useState([])
    const [toRemove, setToRemove] = useState([])

    useEffect(() => {
        dispatch(getRobotList())
        dispatch(getCurrentUser())
    }, [isOpen])

    return (
        <>
            <Notifyer />
            <Modal show={isOpen} onHide={() => dispatch(setModalOpen(false))}>
                <h3>Subscription settings</h3>
                <h5>All available robots</h5>
                <div className="notifications-robot-container">
                    {robots
                        .filter((el) => robotsToFollow.indexOf(el) === -1)
                        .map((robot) => (
                            <div
                                key={robot + '_all'}
                                className={`notification-robot-item ${
                                    toAdd.indexOf(robot) !== -1
                                        ? 'selected-robot-all-item'
                                        : ''
                                }`}
                                onClick={() => {
                                    const idx = toAdd.indexOf(robot)
                                    if (idx === -1) {
                                        setToAdd([...toAdd, robot])
                                    } else {
                                        setToAdd([
                                            ...toAdd.slice(0, idx),
                                            ...toAdd.slice(
                                                idx + 1,
                                                toAdd.length
                                            ),
                                        ])
                                    }
                                }}
                            >
                                {robot}
                            </div>
                        ))}
                </div>
                <h5>Robots you subscribed to</h5>
                <div className="notifications-robot-container">
                    {robotsToFollow.map((robot) => (
                        <div
                            key={robot + '_selected'}
                            className={`notification-robot-item ${
                                toRemove.indexOf(robot) !== -1
                                    ? 'selected-robot-watch-item'
                                    : ''
                            }`}
                            onClick={() => {
                                const idx = toRemove.indexOf(robot)
                                if (idx === -1) {
                                    setToRemove([...toRemove, robot])
                                } else {
                                    setToRemove([
                                        ...toRemove.slice(0, idx),
                                        ...toRemove.slice(
                                            idx + 1,
                                            toRemove.length
                                        ),
                                    ])
                                }
                            }}
                        >
                            {robot}
                        </div>
                    ))}
                </div>
                <p className="robot-notifications-p">
                    You will be notified if one of the robots you are subscribed
                    to needs the operator's attention. Also these robots will be
                    available in the Web Operators tab
                </p>
                <div className="notifications-modal-btn-container">
                    <Button
                        className="green-button"
                        onClick={() => {
                            dispatch(
                                setRobotsToFollow(
                                    [
                                        ...robotsToFollow.filter(
                                            (el) => toRemove.indexOf(el) === -1
                                        ),
                                        ...toAdd,
                                    ],
                                    robotsToFollow
                                )
                            )
                            setToRemove([])
                            setToAdd([])
                            dispatch(setModalOpen(false))
                            dispatch(getCurrentUser())
                        }}
                        disabled={toAdd.length === 0 && toRemove.length === 0}
                    >
                        Accept
                    </Button>
                    <Button
                        onClick={() => {
                            dispatch(setModalOpen(false))
                            dispatch(getCurrentUser())
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    const { isOpen, robotsToFollow } = state.notification
    const info = state.user.userInfo
    return {
        isAdmin:
            info &&
            info.groups !== undefined &&
            info.groups.indexOf('admin') !== -1,
        isOpen,
        robotsToFollow: robotsToFollow.map((el) => el.name),
        robots: state.robot.robots.map((el) => el.name),
    }
}

export default connect(mapStateToProps)(NotificationsModal)
