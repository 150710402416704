import _ from 'lodash'

import {
    SET_SLIDER_POSITION,
    SET_PLAYER_STATE,
    SET_CURRENT_STATE,
    SET_CURRENT_CAMERA,
    SET_CURRENT_COMMAND,
} from './action-types'

export const increaseSlider = () => (dispatch, getState) => {
    const { position, sliderMax } = getState().slider
    if (position + 0.1 <= sliderMax) {
        dispatch(setSliderPos(position + 0.1, false, false))
    } else {
        dispatch(setPlayerState('pause'))
    }
}

export const setSliderPos =
    (pos, doneByUser, isDragging) => (dispatch, getState) => {
        let element
        const { allowSliding } = getState().utils
        const { commands } = getState().data
        const { currentCommand } = getState().slider
        const pastCommands = commands.filter(({ time }) => time <= pos)
        const currCommand = pastCommands[pastCommands.length - 1]
        if (currCommand) {
            element = document.getElementById(currCommand.time)
            dispatch({
                type: SET_CURRENT_COMMAND,
                payload: currCommand,
            })
        }

        if (element && !_.isEqual(currCommand, currentCommand)) {
            if (
                doneByUser ||
                (allowSliding && getState().utils.layout === 'default')
            ) {
                element.scrollIntoView({
                    block: 'center',
                    inline: 'nearest',
                })
            }
        }
        dispatch({ type: SET_SLIDER_POSITION, payload: pos })
        if (!isDragging) {
            const { currentState, currentCamera } = getState().slider
            const { frames } = getState().data
            const filteredState = frames
                .filter((frame) => frame.exchange === 'state')
                .filter((frame) => frame.time <= pos)
            const camera0Frames = frames.filter(
                (frame) => frame.exchange === 'camera0'
            )
            const filteredCamera0 = camera0Frames.filter(
                (frame) => frame.time <= pos
            )
            const lastStateFrame = filteredState[filteredState.length - 1]
            const lastCamera0Frame = filteredCamera0[filteredCamera0.length - 1]
            if (lastStateFrame) {
                if (!_.isEqual(lastStateFrame, currentState)) {
                    dispatch({
                        type: SET_CURRENT_STATE,
                        payload: lastStateFrame,
                    })
                }
            }
            if (lastCamera0Frame) {
                if (!_.isEqual(lastCamera0Frame, currentCamera)) {
                    if (lastCamera0Frame) {
                        dispatch({
                            type: SET_CURRENT_CAMERA,
                            payload: lastCamera0Frame,
                        })
                    }
                }
            } else {
                dispatch({ type: SET_CURRENT_CAMERA, payload: { time: 0 } })
            }
        }
    }

export const setPlayerState = (playerState) => (dispatch) => {
    dispatch({ type: SET_PLAYER_STATE, payload: playerState })
}
