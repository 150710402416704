import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Icon, Button } from 'rsuite'
import {
    ReactResponsiveRgbStream,
    OperatorCanvas,
    OperatorDebug,
} from 'rembrain_streams'

import {
    setMarkerRobot,
    setMarkerTask,
    getTaskList,
    getRobotConfig,
} from '../../Redux/Actions'
import { urls } from '../../Utils'
import OperatorPannel from './OperatorPannel'
import MarkerOperator from './MarkerOperator'
import DriverOperator from './DriverOperator'
import MetalPartsOperator from './MetalPartsOperator'
import { NavList } from '../UtilComponents'

import './WebOperators.css'
import './Switcher.css'

const PageSwitcher = ({ options, value, onChange }) => {
    return (
        <Row>
            <div className="switcher-wrapper">
                <div className="switcher-line" />
                {options.map((opt) => (
                    <Button
                        key={opt.value}
                        onClick={() => onChange(opt)}
                        disabled={opt.value === value}
                        className={`${
                            opt.value === value
                                ? 'switcher-disabled-btn'
                                : 'switcher-btn'
                        }`}
                    >
                        {opt.label}
                    </Button>
                ))}
            </div>
        </Row>
    )
}

const NewWO = ({
    headers,
    subscribedRobots,
    dispatch,
    tasks,
    task,
    selected,
    operator,
    allRobots,
}) => {
    const [activeSoundRobot, setActiveSoundRobot] = useState(null)
    const [robotsType, setRobotsType] = useState('subscribed')
    const [robots, setRobots] = useState([])
    const [isLoading, setIsLoading] = useState(null)

    useEffect(() => {
        dispatch(getTaskList())
    }, [operator])

    useEffect(() => {
        if (robotsType === 'subscribed') {
            setRobots(subscribedRobots)
        } else if (robotsType === 'all') {
            setRobots(allRobots)
        }
    }, [subscribedRobots, robotsType])

    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [robots])

    return (
        <Grid fluid>
            <div>
                <div className="test-line system-line fixed" />
                <div className="switcher-container-wrapper">
                    {!operator && (
                        <PageSwitcher
                            value={robotsType}
                            onChange={(e) => setRobotsType(e.value)}
                            options={[
                                { label: 'View all robots', value: 'all' },
                                {
                                    label: 'View subscribed',
                                    value: 'subscribed',
                                },
                            ]}
                        />
                    )}
                </div>
            </div>

            {operator && (
                <Row className="operator-top-row">
                    <div
                        onClick={() =>
                            dispatch({
                                type: 'SET_WEB_OPERATOR',
                                payload: null,
                            })
                        }
                        className={
                            operator !== 'marker'
                                ? 'operator-back-btn-cut'
                                : 'operator-back-btn'
                        }
                    >
                        <div className="test-line btn-line" />
                        <div className="test-line back-one" />
                        <div className="test-line back-two" />

                        <Icon
                            size="3x"
                            className="operator-back-icon"
                            icon="angle-double-left"
                        />
                    </div>
                    {operator === 'marker' && (
                        <NavList
                            operator={operator}
                            item_list={tasks}
                            curr_selected={task ? task.name : null}
                            placeholder="Task list"
                            label_addition="task"
                            filter_addition={(el) =>
                                el.task.bin && el.task.bin !== undefined
                            }
                            onSelectItem={(el) => dispatch(setMarkerTask(el))}
                        />
                    )}
                </Row>
            )}
            {operator === 'marker' && <MarkerOperator />}
            {operator === 'driver' && <DriverOperator />}
            {operator === 'point-marker' && <MetalPartsOperator />}

            {(operator === 'stream' || operator === 'debug') && (
                <Row className="main-layout-row">
                    <div className="web-operator-container">
                        <div className="stream-container">
                            {operator === 'stream' ? (
                                <OperatorCanvas
                                    className="web-operator"
                                    dataWSUrl={urls.webSocketHost}
                                    robotName={selected}
                                    accessToken={headers.Authorization}
                                />
                            ) : (
                                <OperatorDebug
                                    className="web-operator"
                                    dataWSUrl={urls.webSocketHost}
                                    robotName={selected}
                                    accessToken={headers.Authorization}
                                />
                            )}
                        </div>
                    </div>
                </Row>
            )}
            {!operator && (
                <Row className="main-layout-row">
                    <div className="web-operator-container">
                        {robots.length > 0 ? (
                            robots.map((robot) => (
                                <div
                                    key={'web_operator_' + robot.name}
                                    id={`wo-item-${robot.name}`}
                                    onClick={() => {
                                        if (selected !== robot.name) {
                                            setIsLoading(robot.name)
                                            Promise.all([
                                                dispatch(
                                                    setMarkerRobot(robot.name)
                                                ),
                                                dispatch(
                                                    getRobotConfig(robot.name)
                                                ),
                                            ]).then(() => {
                                                dispatch({
                                                    type: 'SET_SELECTED_ROBOT',
                                                    payload: robot.name,
                                                })
                                                setIsLoading(null)
                                            })
                                        }
                                    }}
                                    className="web-operator-item"
                                >
                                    <OperatorPannel
                                        {...{
                                            robot,
                                            activeSoundRobot,
                                            isLoading,
                                            setActiveSoundRobot,
                                        }}
                                        setOperator={(val) => {
                                            if (val === 'sound') {
                                                setActiveSoundRobot(robot.name)
                                            } else {
                                                dispatch({
                                                    type: 'SET_WEB_OPERATOR',
                                                    payload: val,
                                                })
                                            }
                                        }}
                                        removeSelection={() => {
                                            dispatch({
                                                type: 'SET_SELECTED_ROBOT',
                                                payload: null,
                                            })
                                        }}
                                    />
                                    <div
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <ReactResponsiveRgbStream
                                            minWidth={240}
                                            maxWidth={1000}
                                            aspectRatio={16 / 9}
                                            exchange={'rgbjpeg'}
                                            token={headers.Authorization}
                                            websocketURL={urls.webSocketHost}
                                            robotName={robot.name}
                                            handleError={(ev) => {
                                                console.log(
                                                    'Oh, no! Websocket error occured ',
                                                    ev
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h1 className="on-empty non-selectable no-available-robot">
                                {robotsType === 'all'
                                    ? 'No robots available'
                                    : 'You are not subscribed to any robots yet'}
                            </h1>
                        )}
                    </div>
                </Row>
            )}
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        headers: state.auth,
        subscribedRobots: state.notification.robotsToFollow,
        tasks: state.task.tasks,
        task: state.task.task,
        selected: state.webOperator.selected,
        operator: state.webOperator.operator,
        allRobots: state.robot.robots,
    }
}

export default connect(mapStateToProps)(NewWO)
