import { combineReducers } from 'redux'

import AuthReducer from './AuthReducer'
import UserReducer from './UserReducer'
import ProcessorsReducer from './ProcessorsReducer'
import TaskReducer from './TaskReducer'
import RobotReducer from './RobotReducer'
import MarkerReducer from './BoxMarker'
import NotificationReducer from './Notifications'
import WOReducer from './WOReducer'
import DataSessionReducer from './DataSessionReducer'
import RetrainingSessionReducer from './RetrainingSessionReducer'

import {
    slider,
    utils,
    data,
    playlist,
} from '../../Components/Watchtower/reducer'

export default combineReducers({
    webOperator: WOReducer,
    auth: AuthReducer,
    user: UserReducer,
    processor: ProcessorsReducer,
    task: TaskReducer,
    robot: RobotReducer,
    data_session: DataSessionReducer,
    retraining_session: RetrainingSessionReducer,
    marker: MarkerReducer,
    notification: NotificationReducer,
    slider,
    utils,
    data,
    playlist,
})
