import React from 'react'
import { connect } from 'react-redux'

import { next, previous, setPlayerState } from '../../actions'
import { Icon, IconButton } from 'rsuite'

import './PlayerControls.css'

const PlayerControls = ({
    player_state,
    dispatch,
    currentPlayable,
    playlist,
    page,
    total_pages,
    isLoading,
}) => {
    const handlePlay = () => {
        dispatch(setPlayerState('play'))
    }
    const handlePause = () => {
        dispatch(setPlayerState('pause'))
    }

    return (
        <>
            <IconButton
                className="watchtower-player-button"
                disabled={
                    isLoading ||
                    (playlist.length > 0 && currentPlayable
                        ? playlist[0].path === currentPlayable.path &&
                          page === 1
                        : true)
                }
                icon={<Icon icon="arrow-left" />}
                onClick={() => dispatch(previous())}
            />

            {player_state === 'play' && (
                <IconButton
                    className="watchtower-player-button"
                    icon={<Icon icon="pause" onClick={handlePause} />}
                />
            )}
            {player_state === 'pause' && (
                <IconButton
                    className="watchtower-player-button"
                    icon={<Icon icon="play" />}
                    onClick={handlePlay}
                />
            )}
            {player_state === 'empty' && (
                <IconButton
                    className="watchtower-player-button"
                    icon={<Icon icon="stop" />}
                    disabled={true}
                />
            )}

            <IconButton
                className="watchtower-player-button"
                icon={<Icon icon="arrow-right" />}
                onClick={() => dispatch(next())}
                disabled={
                    isLoading ||
                    (playlist.length > 0 && currentPlayable
                        ? playlist[playlist.length - 1].path ===
                              currentPlayable.path && page === total_pages
                        : true)
                }
            />
        </>
    )
}

const mapStateToProps = (state) => {
    const { currentPlayable, page, total_pages, playlist, isLoading } =
        state.playlist
    return {
        player_state: state.slider.player_state,
        playlist,
        currentPlayable,
        page: page.page,
        total_pages,
        isLoading,
    }
}

export default connect(mapStateToProps)(PlayerControls)
