const initialState = {
    userInfo: {},
    userList: [],
    createModalOpen: false,
    userToEdit: null,
    changePasswordOpen: false,
    isAdmin: false,
}

export default function UserReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case 'SET_CHANGE_PASSWORD_OPEN':
            return {
                ...state,
                changePasswordOpen: payload,
            }
        case 'SET_USER_TO_EDIT': {
            return {
                ...state,
                userToEdit: payload,
            }
        }
        case 'CREATE_USER_OPEN': {
            return {
                ...state,
                createModalOpen: payload,
            }
        }
        case 'SET_USER_STAGE': {
            return {
                ...state,
                userStage: payload,
            }
        }
        case 'GET_USER_LIST': {
            return {
                ...state,
                userList: payload,
            }
        }
        case 'USER_INFO':
            return {
                ...state,
                userInfo: payload,
                isAdmin:
                    payload &&
                    payload.groups !== undefined &&
                    payload.groups.indexOf('admin') !== -1,
            }
        default:
            return state
    }
}
