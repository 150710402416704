import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Table } from 'rsuite'

import { getProcessors } from '../../../Redux/Actions'
import { sortTableData } from '../../../Utils'
import { CustomCell, StickyHeaderRow } from '../../UtilComponents'

const { Column, HeaderCell } = Table

const ProcessorsPanel = ({ getProcessors, processors }) => {
    useEffect(() => {
        getProcessors()
        const interval = setInterval(() => {
            getProcessors()
        }, 2000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    const [nameFilter, setNameFilter] = useState('')
    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState('asc')
    const [loading, setLoading] = useState(false)

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setSortColumn(sortColumn)
            setSortType(sortType)
        }, 500)
    }

    const getData = () => {
        const data = nameFilter
            ? processors.filter((el) =>
                  el.associated_robot.includes(nameFilter)
              )
            : processors
        if (sortColumn && sortType) {
            return sortTableData(data, sortColumn, sortType)
        }
        return data
    }

    return (
        <>
            <StickyHeaderRow
                {...{ sortColumn, sortType, setSortColumn, setSortType }}
                sortableCells={[
                    { name: 'id', label: 'ID' },
                    {
                        name: 'associated_robot',
                        label: 'Assiciated Robot',
                    },
                    {
                        name: 'template_type',
                        label: 'Template Type',
                    },
                    { name: 'active', label: 'Active' },
                ]}
            >
                <Input
                    placeholder="Filter by associated robot..."
                    className="list-filter-input"
                    value={nameFilter}
                    onChange={setNameFilter}
                />
            </StickyHeaderRow>
            <Table
                showHeader={false}
                rowClassName="non-selectable table-row"
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                autoHeight
                data={getData()}
            >
                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell dataKey="id" />
                </Column>

                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell dataKey="associated_robot" />
                </Column>

                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell dataKey="template_type" />
                </Column>

                <Column sortable align="center" flexGrow={1}>
                    <HeaderCell />
                    <CustomCell dataKey="active" />
                </Column>
            </Table>
        </>
    )
}

function mapStateToProps(state) {
    return {
        processors: state.processor.processors,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProcessors: () => dispatch(getProcessors()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessorsPanel)
