import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, IconButton, Icon } from 'rsuite'

import { getDataSessionFiles, getDataSessionList } from '../../../Redux/Actions'
import {
    headerStyle,
    TableDeleteElement,
    CustomHeaderRow,
} from '../../UtilComponents'

const { Column, HeaderCell, Cell } = Table

const CustomDataCell = ({ rowData, dataKey, addedBySession, ...props }) => {
    return (
        <Cell className="session-cell" {...props}>
            <div
                className={`table-cell-wrapper ${
                    dataKey === 'name' ? 'data-session-name-cell-content' : ''
                } ${addedBySession.id === rowData.id ? 'custom-cell' : ''}`}
            >
                <p>{rowData[dataKey]}</p>
            </div>
        </Cell>
    )
}

const SessionList = ({
    dispatch,
    sessionList,
    addedBySession,
    handleDelete,
}) => {
    const [deleteOpen, setDeleteOpen] = useState(null)

    useEffect(() => {
        let interval

        if (addedBySession.id && addedBySession.id !== undefined) {
            interval = setInterval(() => {
                dispatch(getDataSessionFiles(addedBySession.id))
            }, 5000)
        } else {
            clearInterval(interval)
        }
        return () => {
            interval && clearInterval(interval)
        }
        //eslint-disable-next-line
    }, [addedBySession])

    useEffect(() => {
        dispatch(getDataSessionList())
        const interval = setInterval(() => {
            dispatch(getDataSessionList())
        }, 5000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className="session-table-list">
            <div className="table-header table-addition">
                <p>Session List</p>
                <div className="spacer" />
                {addedBySession.id && (
                    <IconButton
                        className="close-added-session-btn"
                        onClick={() =>
                            dispatch({
                                type: 'ADD_FILES_FROM_SESSION',
                                payload: { files: [] },
                            })
                        }
                        icon={<Icon icon="close" />}
                    />
                )}
            </div>
            <CustomHeaderRow
                cells={[{ label: 'Name' }, { label: 'Id' }]}
                paddingStyle={{ paddingLeft: 50 }}
            />
            <div
                className="custom-scrollbar"
                style={{
                    height: 600,
                    backgroundColor: 'var(--primary)',
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    overflowY: 'auto',
                }}
            >
                <Table
                    autoHeight
                    showHeader={false}
                    style={{ width: '100%' }}
                    rowClassName="table-row"
                    onRowClick={(rowData) => {
                        if (addedBySession.id !== rowData.id) {
                            dispatch(getDataSessionFiles(rowData.id))
                        } else {
                            dispatch({
                                type: 'ADD_FILES_FROM_SESSION',
                                payload: { files: [] },
                            })
                        }
                    }}
                    data={sessionList}
                >
                    <Column
                        className="non-selectable"
                        width={50}
                        align="center"
                    >
                        <HeaderCell style={headerStyle} />
                        <Cell>
                            {(rowData) => (
                                <TableDeleteElement
                                    className={`table-cell-wrapper ${
                                        addedBySession.id === rowData.id
                                            ? 'custom-cell'
                                            : ''
                                    }`}
                                    label={`Delete this session?`}
                                    id={rowData.id}
                                    onAccept={() => {
                                        handleDelete(false, rowData.id)
                                        setDeleteOpen(null)
                                    }}
                                    onDecline={() => setDeleteOpen(null)}
                                    onIconClick={() =>
                                        setDeleteOpen(rowData.id)
                                    }
                                    deleteOpen={deleteOpen}
                                />
                            )}
                        </Cell>
                    </Column>
                    <Column
                        flexGrow={1}
                        align="center"
                        className="non-selectable"
                    >
                        <HeaderCell style={headerStyle}>Name</HeaderCell>
                        <CustomDataCell
                            addedBySession={addedBySession}
                            dataKey="name"
                        />
                    </Column>
                    <Column align="center" flexGrow={1}>
                        <HeaderCell style={headerStyle}>Id</HeaderCell>
                        <CustomDataCell
                            addedBySession={addedBySession}
                            dataKey="id"
                        />
                    </Column>
                </Table>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        sessionList: state.data_session.sessionList,
        addedBySession: state.data_session.addedBySession,
    }
}

export default connect(mapStateToProps)(SessionList)
