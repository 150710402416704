import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'rsuite'
import { connect } from 'react-redux'
import { JsonEditor } from '../../../UtilComponents'

import { colors } from '../../../../Utils'
import './FileViewModal.css'

const DataSession = ({ dispatch, fileModalOpen, fileToView, fileJSON }) => {
    const [viewJson, setViewJson] = useState(false)

    useEffect(() => {
        setViewJson(false)
    }, [fileModalOpen])

    return (
        <Modal
            className="file-view-modal margin-center-h"
            size="lg"
            show={fileModalOpen}
            onHide={() =>
                dispatch({
                    type: 'SET_FILE_MODAL_OPEN',
                    payload: false,
                })
            }
            onExited={() => {
                dispatch({
                    type: 'SET_FILE_TO_VIEW',
                    payload: { path: null, image: null },
                })
            }}
        >
            <Modal.Header closeButton={false}>
                <h4>File: {fileToView.path}</h4>
            </Modal.Header>
            <Modal.Body className="file-view-modal-body margin-center-h">
                {!viewJson ? (
                    <img
                        src={fileToView.image}
                        id="fileImage"
                        className="file-image"
                        width={850}
                        height={480}
                        alt={'File image'}
                    />
                ) : (
                    <JsonEditor
                        value={fileJSON}
                        switcher={fileJSON}
                        readonly={true}
                        style={{ width: 850 }}
                    />
                )}
            </Modal.Body>
            <div className="notifications-modal-btn-container">
                <Button onClick={() => setViewJson(!viewJson)}>
                    {viewJson ? 'Show Image' : 'Show JSON'}
                </Button>
                <Button
                    onClick={() =>
                        dispatch({
                            type: 'SET_FILE_MODAL_OPEN',
                            payload: false,
                        })
                    }
                >
                    Close
                </Button>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { fileToView, fileModalOpen, fileJSON } = state.data_session
    return {
        fileToView,
        fileModalOpen,
        fileJSON,
    }
}

export default connect(mapStateToProps)(DataSession)
