import React, { useState, useRef, useEffect } from 'react'
import { JsonEditor } from '../../UtilComponents'
import { Button, Input, InputGroup } from 'rsuite'
import _ from 'lodash'
import { connect } from 'react-redux'
import { urls } from '../../../Utils'
import { drawLine } from '../Utils/utils'
import { ReactRgbStream } from 'rembrain_streams'

const capitalize = (string) => {
    return string[0].toUpperCase() + string.substring(1)
}

const encoder = new TextEncoder('UTF-8')

const toBytes = (text) => {
    return encoder.encode(text)
}

const Stream = ({ image, date, width, height, robot, token }) => {
    const canvRef = useRef(null)
    const pushWs = useRef(null)
    const interval = useRef(null)

    const [keypoint, setKeyPoint] = useState('0')
    const [state, setState] = useState(false)
    const [position, setPosition] = useState({
        X: 0,
        Y: 0,
        alpha: 0,
    })

    const sendPing = () => {
        if (pushWs && pushWs.current.readyState === 1) {
            pushWs.current.send(JSON.stringify({ command: 'ping' }))
        }
    }

    const handlePushCommand = (command) => {
        if (pushWs && pushWs.current.readyState === 1) {
            if (interval.current) {
                clearInterval(interval.current)
            }
            interval.current = setInterval(() => sendPing(), 1000)
            pushWs.current.send(toBytes(JSON.stringify(command)))
        }
    }

    const handleTurn = (dir) =>
        handlePushCommand({
            op: 'move',
            alpha1: position.alpha + dir * -1,
            dist: 0,
            source: 'operator',
        })

    const handleGoTo = (ev) => {
        const [pixel_x, pixel_y] = getMousePos(ev)
        if (ev.button == 2) {
            handlePushCommand({
                op: 'go_xy',
                pixel_x,
                pixel_y,
                keypoint,
                source: 'operator',
            })
        }
        if (ev.button == 0) {
            handlePushCommand({
                op: 'go_dir',
                pixel_x,
                pixel_y,
                source: 'operator',
            })
        }
    }

    const handleSendOP = (op) =>
        handlePushCommand({
            op,
            source: 'operator',
        })

    const handlePauseContinue = () => {
        handleSendOP(state ? 'pause' : 'continue')
        setState(!state)
    }

    const getMousePos = (ev) => {
        if (canvRef.current) {
            const rect = canvRef.current.getBoundingClientRect()
            return [
                (ev.clientX - rect.left) / width,
                (ev.clientY - rect.top) / height,
            ]
        }
    }

    const keyPressHandler = ({ code }) => {
        let d = 0
        code === 'KeyE' && (d = 0.08)
        code === 'KeyQ' && (d = -0.08)
        code === 'Space' && handlePauseContinue()
        d !== 0 && handleTurn(d)
    }

    const redraw = () => {
        const canvas = canvRef.current
        if (!canvas) return

        const context = canvas.getContext('2d')
        context.clearRect(0, 0, width, height)
        context.drawImage(image, 0, 0, width, height)
        const points = [
            [width / 2 - 60, height / 1.5],
            [width / 2 - 190, height / 1.5 + 200],
            [width / 2 + 190, height / 1.5 + 200],
            [width / 2 + 60, height / 1.5],
        ]

        points.forEach((p, idx, arr) =>
            idx === points.length - 1
                ? drawLine(points[idx], points[0], context)
                : drawLine(points[idx], points[idx + 1], context)
        )
    }

    useEffect(() => {
        const { current } = canvRef
        current.addEventListener(
            'contextmenu',
            (e) => e.preventDefault(),
            false
        )
        current.addEventListener('mousedown', handleGoTo, false)
        window.addEventListener('keypress', keyPressHandler, false)

        return () => {
            current.removeEventListener('mousedown', handleGoTo, false)
            current.removeEventListener(
                'contextmenu',
                (e) => e.preventDefault(),
                false
            )
            window.removeEventListener('keypress', keyPressHandler, false)
        }
    }, [state, width, height, position, keypoint])

    useEffect(() => {
        const canvas = canvRef.current
        canvas.width = width
        canvas.height = height

        redraw()
    }, [image, width, height, date])

    const connectWS = (ws) => {
        ws = new WebSocket(urls.webSocketHost)
        ws.onopen = () => {
            const controlPacket = {
                command: 'pull',
                exchange: 'state',
                robot_name: robot,
                access_token: token,
            }
            ws.send(JSON.stringify(controlPacket))
        }
        ws.onerror = () => {
            ws.close()
        }
        ws.onmessage = ({ data }) => {
            if (data instanceof Blob) {
                data.text().then((resp) => {
                    let data = null
                    try {
                        data = JSON.parse(resp)
                    } catch {}
                    setPosition(data.pos)
                })
            }
        }
        ws.onclose = (ev) => {
            if (ev.reason !== 'stay down') {
                connectWS(ws)
            }
        }
    }

    const openPushLoop = () => {
        pushWs.current = new WebSocket(urls.webSocketHost)
        pushWs.current.onopen = () => {
            const controlPacket = {
                command: 'push_loop',
                exchange: 'commands',
                robot_name: robot,
                access_token: token,
            }
            pushWs.current.send(JSON.stringify(controlPacket))
        }
        pushWs.current.onerror = (err) => {
            console.log({ err })
            pushWs.current.close()
        }
        pushWs.current.onclose = (ev) => {
            if (ev.reason !== 'stay down') {
                openPushLoop()
            }
        }
    }

    useEffect(() => {
        let stateWs = null
        pushWs.current = null
        interval.current = setInterval(() => sendPing(), 1000)
        openPushLoop()
        connectWS(stateWs)
        return () => {
            if (interval.current) {
                clearInterval(interval.current)
                interval.current = null
            }

            stateWs && stateWs.close(1000, 'stay down')
            pushWs.current && pushWs.current.close(1000, 'stay down')
        }
    }, [robot])

    return (
        <div className="box-marker-stream-container">
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <canvas
                    style={{
                        backgroundColor: 'lightgray',
                    }}
                    ref={canvRef}
                />
            </div>
            <div className="driver-bottom">
                <div
                    style={{ width: 600, flexWrap: 'wrap' }}
                    className="driver-bottom"
                >
                    {[
                        'clear_odometry',
                        'stop',
                        'pause',
                        'continue',
                        'raise_arm',
                        'lower_arm',
                        'release_arm',
                        'pause_arm',
                    ].map((el) => (
                        <Button
                            key={el}
                            className="marker-button"
                            onClick={() => handleSendOP(el)}
                        >
                            {capitalize(el.replace(/_/g, ' '))}
                        </Button>
                    ))}
                    <Button
                        className="marker-button"
                        onClick={() => handleTurn(-1.57)}
                    >
                        Rotate left 90
                    </Button>
                    <Button
                        className="marker-button"
                        onClick={() => handleTurn(-0.08)}
                    >
                        Rotate left
                    </Button>
                    <Button
                        className="marker-button"
                        onClick={() => handleTurn(0.08)}
                    >
                        Rotate right
                    </Button>

                    <Button
                        className="marker-button"
                        onClick={() => handleTurn(1.57)}
                    >
                        Rotate right 90
                    </Button>
                </div>
                <InputGroup
                    style={{
                        width: 300,
                        marginBottom: 'auto',
                        marginTop: 8,
                    }}
                >
                    <InputGroup.Addon>Keypoint :</InputGroup.Addon>
                    <Input
                        id="keypoint"
                        value={keypoint}
                        onChange={(v) => setKeyPoint(v)}
                    />
                </InputGroup>
            </div>
            <div style={{ paddingTop: 15 }}>
                <JsonEditor
                    style={{
                        maxHeight: 400,
                    }}
                    switcher={position}
                    readonly={true}
                    value={{
                        pos:
                            position |
                            {
                                X: 0,
                                Y: 0,
                                alpha: 0,
                            },
                    }}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="marker-legend">
                    <h4>Legend</h4>
                    <h5>Controls</h5>
                    <p>- Left click on stream to point where to go</p>
                    <p>- Q and E keys to rotate</p>
                    <p>- Space to "pause" and "continue"</p>
                </div>
                <div style={{ marginLeft: 30, marginTop: 20, width: '100%' }}>
                    <ReactRgbStream
                        exchange="camera1"
                        token={token}
                        placeholderText="No Image"
                        websocketURL={urls.webSocketHost}
                        robotName={robot}
                        handleError={(ev) => {
                            console.log('Oh, no! Websocket error occured ', ev)
                        }}
                        width={250}
                        height={250}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    robot: state.marker.markerRobot,
    token: state.auth.Authorization,
})

export default connect(mapStateToProps)(Stream)
