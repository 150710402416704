import _ from 'lodash'
import { Button, Input, Toggle } from 'rsuite'
import { connect } from 'react-redux'
import { useRef, useEffect, useState } from 'react'

import numbers from 'numbers'

import {
    pictureToCamera,
    normalizeToRectangle,
    getAverageDepth,
    completeBin,
    cameraToPicture,
    vectorLength,
    robotToCamera,
    pictureToRobot,
    cameraToRobot,
    drawPolygon,
    drawBox,
    drawPoint,
} from '../Utils/utils'

import { notifyError } from '../../../Axios'
import {
    setUserBin,
    setUserBinCreation,
    setUserBinParams,
    pushRobotCommand,
} from '../../../Redux/Actions'

const { det, cross, transpose, divide, norm, multiply, subtract, dot, add } =
    numbers.calculus

const binFillParams = [32, 32, 64]

const Stream = ({
    image,
    status,
    userBin,
    depth,
    bin,
    normal,
    calibration,
    width,
    height,
    robotName,
    dispatch,
    markerRes,
    debugInfo,
    userBinCreation,
    userBinParams,
    pushdown,
}) => {
    const canvRef = useRef(null)

    const [userDepth, setUserDepth] = useState(0)

    const [polygon, setPolygon] = useState(new Array(4).fill(null))
    const [second, setSecond] = useState(new Array(4).fill(null))
    const [intersectionPoints, setIntersectionPoints] = useState(null)
    const [boxParams, setBoxParams] = useState(null)

    const [binInPicture, setBinInPicture] = useState(null)
    const [binInCamera, setBinInCamera] = useState(null)
    const [binFillPoints, setBinFillPoints] = useState([])

    const [offset, setOffset] = useState([0, 0])
    const [mode, setMode] = useState(false)
    const [offsetStep, setOffsetStep] = useState([1, 1, 1])
    const [boxInBin, setBoxInBin] = useState(null)
    const [finalBox, setFinalBox] = useState(null)

    const [robotBox, setRobotBox] = useState(null)
    const [finalRobotBox, setFinalRobotBox] = useState(null)

    const [pictureUserBin, setPictureUserBin] = useState(null)
    //
    const [useGrabPoint, setUseGrabPoint] = useState(false)
    const [firstGrabPoint, setFirstGrabPoint] = useState(null)
    //
    const clearStates = () => {
        setUserDepth(0)
        setPolygon(new Array(4).fill(null))
        setSecond(new Array(4).fill(null))
        setIntersectionPoints(null)
        setBoxParams(null)
        setFirstGrabPoint(null)
        setOffset([0, 0])
        setMode(false)
        setOffsetStep[(1, 1, 1)]
        setBoxInBin(null)
        setFinalBox(null)
        setRobotBox(null)
        setFinalRobotBox(null)
        setPictureUserBin(null)
    }

    const getMousePos = (ev) => {
        if (canvRef.current) {
            const rect = canvRef.current.getBoundingClientRect()
            return [ev.clientX - rect.left, ev.clientY - rect.top]
        }
    }

    const keyPressHandler = (ev) => {
        if (ev.key === ' ') {
            ev.preventDefault()
        }
        if (boxParams) {
            const [x, y] = offset
            const { w, l } = boxParams
            let border

            switch (ev.code) {
                case 'KeyQ':
                    setMode(!mode)
                    setOffset([0, 0])
                    return
                case 'KeyW':
                    y - offsetStep[1] >= 0 && setOffset([x, y - offsetStep[1]])
                    return
                case 'KeyS':
                    border = mode
                        ? y + w + offsetStep[1]
                        : y + l + offsetStep[1]
                    border <=
                        vectorLength(
                            subtract(binInCamera[1], binInCamera[0])
                        ) && setOffset([x, y + offsetStep[1]])
                    return
                case 'KeyA':
                    x - offsetStep[0] >= 0 && setOffset([x - offsetStep[0], y])
                    return
                case 'KeyD':
                    border = mode
                        ? x + l + offsetStep[0]
                        : x + w + offsetStep[0]
                    border <=
                        vectorLength(
                            subtract(binInCamera[3], binInCamera[0])
                        ) && setOffset([x + offsetStep[0], y])
                    return
                case 'Space':
                    handleBoxPlace()
                    return
            }
        } else {
            if (
                ev.code === 'KeyQ' ||
                ev.code === 'Space' ||
                ev.code === 'KeyD' ||
                ev.code === 'KeyS' ||
                ev.code === 'KeyA' ||
                ev.code === 'KeyW'
            ) {
                if (second[0] && bin) {
                    handleBoxPlace()
                    binHandler()
                }
            }
        }
    }

    const mouseUpHandler = (ev) => {
        if (ev.which === 1) {
            if (calibration && calibration !== undefined) {
                if (calibration.length === 3 && calibration[0].length === 4) {
                    //
                    const currPointIdx = polygon.findIndex((el) => el === null)
                    const [x, y] = getMousePos(ev)
                    const [z] = depth
                        .crop({ x, y, width: 1, height: 1 })
                        .getPixel(0)

                    polygon[currPointIdx] = [x, y, z]
                    setPolygon(polygon)

                    if (
                        currPointIdx !== -1 &&
                        currPointIdx === polygon.length - 1
                    ) {
                        if (!userBinCreation) {
                            let sumX = 0
                            let sumY = 0

                            polygon.forEach((p) => {
                                sumX += p[0]
                                sumY += p[1]
                            })

                            const avDepth = getAverageDepth(
                                [sumX / 4, sumY / 4],
                                depth,
                                10,
                                [width, height]
                            )
                            if (avDepth === 'err') {
                                clearStates()
                                notifyError({}, 'Failed to get point depth')
                                redraw()
                                return
                            }
                            let newMedian = [sumX / 4, sumY / 4, avDepth]
                            newMedian = pictureToCamera(newMedian, status)
                            const { ppx, ppy, fx, fy } = status

                            const newDirs = polygon.map((point) => {
                                const [x, y] = point
                                const v = [(x - ppx) / fx, (y - ppy) / fy, 1]
                                return divide(v, norm(v))
                            })

                            const newCurvParamArr = newDirs.map(
                                (dir) =>
                                    dot(normal, newMedian) / dot(normal, dir)
                            )

                            let intPoints = []
                            for (let i = 0; i < 4; i++) {
                                intPoints.push(
                                    newDirs[i].map((comp) =>
                                        multiply(comp, newCurvParamArr[i])
                                    )
                                )
                            }

                            intPoints = normalizeToRectangle(intPoints, normal)

                            setIntersectionPoints(intPoints)
                            setSecond(
                                intPoints.map((point) =>
                                    cameraToPicture(
                                        point,
                                        status,
                                        normal,
                                        userDepth
                                    )
                                )
                            )
                            setPolygon(
                                intPoints.map((point) =>
                                    cameraToPicture(point, status, normal)
                                )
                            )
                        } else {
                            userBinHandler()
                        }
                    } else if (polygon[3] && useGrabPoint && !firstGrabPoint) {
                        const [x, y] = getMousePos(ev)
                        const avZ = getAverageDepth([x, y], depth, 7, [
                            width,
                            height,
                        ])
                        if (avZ === 'err') {
                            clearStates()
                            notifyError({}, 'Failed to get point depth')
                            redraw()
                            return
                        }
                        setFirstGrabPoint([x, y, avZ])
                    }
                } else {
                    notifyError({}, 'Malformed calibration in config')
                }
            } else {
                notifyError({}, 'No calibration in robot config')
            }
        }
        redraw()
    }

    const scrollHandler = (ev) => {
        if (second[0]) {
            ev.preventDefault()
            const newUserDepth = ev.deltaY > 0 ? userDepth + 15 : userDepth - 15
            newUserDepth > 0 && setUserDepth(newUserDepth)
        }
    }

    const mouseDownHandler = (ev) => {
        ev.preventDefault()
        if (ev.which === 3) {
            if (useGrabPoint && firstGrabPoint) {
                setFirstGrabPoint(null)
            } else {
                clearStates()
                redraw()

                const [x, y] = getMousePos(ev)
                const z = getAverageDepth([x, y], depth, 10, [width, height])
                if (z === 'err') {
                    clearStates()
                    notifyError({}, 'Failed to get point depth')
                } else {
                    console.log(
                        'Robot:',
                        pictureToRobot([x, y, z], status, calibration)[2]
                    )
                }
            }
        }
    }

    const redraw = () => {
        const canvas = canvRef.current
        if (!canvas) return

        const context = canvas.getContext('2d')

        context.drawImage(image, 0, 0, width, height)

        pictureUserBin && drawBox(pictureUserBin, context, 'green')
        firstGrabPoint && drawPoint(firstGrabPoint, context, 'purple', 7)
        binInPicture &&
            !userBinCreation &&
            drawBox(binInPicture, context, 'green')

        if (boxInBin && boxParams) {
            drawPolygon(boxInBin, context, finalBox ? 'grey' : 'crimson')
        }

        finalBox && drawBox(finalBox, context, 'blue')

        if (polygon[0] && !second[0]) {
            !pictureUserBin && drawPolygon(polygon, context, 'red')
        } else if (polygon[0] && second[0]) {
            drawBox([...second, ...polygon], context, 'crimson')
        }
    }

    const userBinHandler = () => {
        const { ppx, ppy, fx, fy } = status
        let binPoint = robotToCamera([0, 0, userBinParams.top], calibration)

        const newDirs = polygon.map((point) => {
            const [x, y] = point
            const v = [(x - ppx) / fx, (y - ppy) / fy, 1]
            return divide(v, norm(v))
        })
        const newCurvParamArr = newDirs.map(
            (dir) => dot(normal, binPoint) / dot(normal, dir)
        )

        let intPoints = []
        for (let i = 0; i < 4; i++) {
            intPoints.push(
                newDirs[i].map((comp) => multiply(comp, newCurvParamArr[i]))
            )
        }

        intPoints = normalizeToRectangle(intPoints, normal)

        const binBottom = intPoints.map((point) =>
            cameraToPicture(
                point,
                status,
                normal,
                userBinParams.top - userBinParams.bottom
            )
        )

        const binTop = intPoints.map((point) =>
            cameraToPicture(point, status, normal)
        )

        setPictureUserBin([...binTop, ...binBottom])
    }

    const handleUserBinAccept = () => {
        let sortedTop = [...pictureUserBin.slice(0, 4)].sort(
            (a, b) => a[0] - b[0]
        )
        sortedTop = [
            sortedTop.slice(0, 2).sort((a, b) => a[1] - b[1]),
            sortedTop.slice(2).sort((a, b) => a[1] - b[1]),
        ]

        let sortedBottom = [...pictureUserBin.slice(4, 8)].sort(
            (a, b) => a[0] - b[0]
        )
        sortedBottom = [
            sortedBottom.slice(0, 2).sort((a, b) => a[1] - b[1]),
            sortedBottom.slice(2).sort((a, b) => a[1] - b[1]),
        ]
        const newBin = [
            sortedBottom[0][0],
            sortedBottom[0][1],
            sortedBottom[1][0],
            sortedTop[0][0],
        ].map((p) => pictureToRobot(p, status, calibration))
        dispatch(setUserBin(newBin))
    }

    const handleBoxPlace = () => {
        const polygonCamera = polygon.map((point) =>
            pictureToCamera(point, status)
        )

        const l1 = vectorLength(subtract(polygonCamera[1], polygonCamera[0]))
        const l2 = vectorLength(subtract(polygonCamera[2], polygonCamera[1]))
        const l3 = vectorLength(
            subtract(polygonCamera[0], pictureToCamera(second[0], status))
        )

        let sortedFirst = [...polygon].sort((a, b) => a[0] - b[0])
        sortedFirst = [
            sortedFirst.slice(0, 2).sort((a, b) => a[1] - b[1]),
            sortedFirst.slice(2).sort((a, b) => a[1] - b[1]),
        ]

        let sortedSecond = [...second].sort((a, b) => a[0] - b[0])
        sortedSecond = [
            sortedSecond.slice(0, 2).sort((a, b) => a[1] - b[1]),
            sortedSecond.slice(2).sort((a, b) => a[1] - b[1]),
        ]

        setRobotBox(
            [
                sortedFirst[0][0],
                sortedFirst[0][1],
                sortedFirst[1][0],
                sortedSecond[0][0],
            ].map((p) => pictureToRobot(p, status, calibration))
        )
        setOffset([0, 0])
        const params = {
            l: l1 >= l2 ? l1 : l2,
            w: l1 >= l2 ? l2 : l1,
            h: l3,
            isLong:
                vectorLength(subtract(sortedFirst[0][0], sortedFirst[0][1])) >=
                vectorLength(subtract(sortedFirst[0][0], sortedFirst[1][0])),
        }
        setBoxParams(params)
    }

    const getFinalBox = (box) => {
        let z = bin[0][2]
        let possibleToPlace = true
        let boxPoints = new Array(10)
        while (boxPoints.length >= 10) {
            z += offsetStep[2]
            if (z + boxParams.h >= bin[3][2]) {
                possibleToPlace = false
                break
            }
            boxPoints = binFillPoints.filter((point) => {
                const [px, py, pz, rz, pd] = point

                let p0 = [
                    box[0][0] + normal[0] * (pz - box[0][2]),
                    box[0][1] + normal[1] * (pz - box[0][2]),
                    pz,
                ]

                let p1 = [
                    box[1][0] + normal[0] * (pz - box[1][2]),
                    box[1][1] + normal[1] * (pz - box[1][2]),
                    pz,
                ]

                let p2 = [
                    box[2][0] + normal[0] * (pz - box[2][2]),
                    box[2][1] + normal[1] * (pz - box[2][2]),
                    pz,
                ]

                if (
                    p0[0] < px &&
                    px < p2[0] &&
                    py > p0[1] &&
                    py < p1[1] &&
                    rz >= z
                ) {
                    return pz >= pd
                } else {
                    return false
                }
            })
        }
        z -= offsetStep[2]
        if (possibleToPlace) {
            const bottomPart = box.map((point) =>
                cameraToPicture(point, status, normal, bin[0][2] - z)
            )
            const topPart = box.map((point) =>
                cameraToPicture(
                    point,
                    status,
                    normal,
                    bin[0][2] - boxParams.h - z
                )
            )

            if (mode === !boxParams.isLong) {
                setFinalRobotBox(
                    [topPart[0], topPart[1], topPart[3], bottomPart[0]].map(
                        (p) => pictureToRobot(p, status, calibration)
                    )
                )
            } else {
                setFinalRobotBox(
                    [topPart[3], topPart[0], topPart[2], bottomPart[3]].map(
                        (p) => pictureToRobot(p, status, calibration)
                    )
                )
            }

            setFinalBox([...bottomPart, ...topPart])
        } else {
            setFinalBox(null)
            setFinalRobotBox(null)
        }
    }

    const setBoxBottom = () => {
        const { l, w } = boxParams
        const [x1, y1] = offset

        let y2 = mode ? w : l
        let x2 = mode ? l : w

        let oy = subtract(binInCamera[1], binInCamera[0])
        let ox = subtract(binInCamera[3], binInCamera[0])

        const p0 = add(
            binInCamera[0],
            add(
                multiply(y1 / vectorLength(oy), oy),
                multiply(x1 / vectorLength(ox), ox)
            )
        )
        const points = [
            p0,
            add(p0, multiply(y2 / vectorLength(oy), oy)),
            add(
                p0,
                add(
                    multiply(y2 / vectorLength(oy), oy),
                    multiply(x2 / vectorLength(ox), ox)
                )
            ),
            add(p0, multiply(x2 / vectorLength(ox), ox)),
        ]
        setBoxInBin(points.map((p) => cameraToPicture(p, status, normal)))
        getFinalBox(points)
    }

    const handleCommand = () => {
        const robotEndTop = (
            finalBox[0][2] >= finalBox[4][2]
                ? finalBox.slice(4, 8)
                : finalBox.slice(0, 4)
        ).map((p) => pictureToCamera(p, status))

        const robotStartTop = (
            polygon[0][2] > second[0][2] ? second : polygon
        ).map((p) => pictureToCamera(p, status))

        let pos1
        let pos2

        let ox1 = subtract(robotBox[2], robotBox[0])
        let oy1 = subtract(robotBox[1], robotBox[0])
        let oz1 = subtract(robotBox[3], robotBox[0])
        dot(cross(ox1, oy1), oz1) < 0 && (oy1 = subtract([0, 0, 0], oy1))
        ox1 = divide(ox1, norm(ox1))
        oy1 = divide(oy1, norm(oy1))
        oz1 = divide(oz1, norm(oz1))
        let rot1 = transpose([ox1, oy1, oz1])

        let ox2 = subtract(finalRobotBox[2], finalRobotBox[0])
        let oy2 = subtract(finalRobotBox[1], finalRobotBox[0])
        let oz2 = subtract(finalRobotBox[3], finalRobotBox[0])
        dot(cross(ox2, oy2), oz2) < 0 && (oy2 = subtract([0, 0, 0], oy2))
        ox2 = divide(ox2, norm(ox2))
        oy2 = divide(oy2, norm(oy2))
        oz2 = divide(oz2, norm(oz2))
        let rot2 = transpose([ox2, oy2, oz2])

        if (!useGrabPoint) {
            const [ps1, ps2, ps3] = robotStartTop
            const [pe1, pe2, pe3] = robotEndTop
            const ev = add(subtract(pe1, pe2), subtract(pe3, pe2))
            const sv = add(subtract(ps1, ps2), subtract(ps3, ps2))
            pos1 = multiply(calibration, [...add(ps2, multiply(0.5, sv)), 1.0])
            pos2 = multiply(calibration, [...add(pe2, multiply(0.5, ev)), 1.0])
        } else if (firstGrabPoint) {
            const zeroToGrabVector = multiply(
                rot2,
                multiply(
                    rot1,
                    subtract(
                        pictureToRobot(firstGrabPoint, status, calibration),
                        robotBox[0]
                    )
                )
            )
            pos1 = pictureToRobot(firstGrabPoint, status, calibration)
            pos2 = add(zeroToGrabVector, finalRobotBox[0])
        } else {
            notifyError(
                {},
                'Please, specify tool grab point on top of your object'
            )
            return
        }

        const command = {
            op: 'pickup_putdown',
            pos1: add(pos1, multiply(rot1, [0, 0, pushdown])),
            pos2,
            box: robotBox.slice(0, 4),
            calibration,
            rot1,
            rot2,
            source: 'operator',
        }

        dispatch(pushRobotCommand(robotName, command, 'marker'))
        clearStates()
    }

    const binHandler = () => {
        const binInCamera = completeBin(bin).map((point) => {
            return robotToCamera(point, calibration)
        })

        let newBin = binInCamera.map((p) => cameraToPicture(p, status, normal))

        let arr = []

        setOffsetStep([
            vectorLength(subtract(binInCamera[3], binInCamera[0])) /
                binFillParams[0],
            vectorLength(subtract(binInCamera[1], binInCamera[0])) /
                binFillParams[1],
            vectorLength(subtract(binInCamera[4], binInCamera[0])) /
                binFillParams[2],
        ])

        const Z1 = subtract(binInCamera[0], binInCamera[4])
        const Z2 = subtract(binInCamera[1], binInCamera[5])
        const Z3 = subtract(binInCamera[2], binInCamera[6])
        const Z4 = subtract(binInCamera[3], binInCamera[7])

        for (let i = 0; i < binFillParams[2]; i++) {
            const z1 = add(
                binInCamera[4],
                multiply(1 - i / binFillParams[2], Z1)
            )
            const z2 = add(
                binInCamera[5],
                multiply(1 - i / binFillParams[2], Z2)
            )
            const z3 = add(
                binInCamera[6],
                multiply(1 - i / binFillParams[2], Z3)
            )
            const z4 = add(
                binInCamera[7],
                multiply(1 - i / binFillParams[2], Z4)
            )

            const Y1 = subtract(z1, z2)
            const Y2 = subtract(z4, z3)

            for (let j = 1; j <= binFillParams[1]; j++) {
                const x1 = add(z2, multiply(1 - j / binFillParams[1], Y1))
                const x2 = add(z3, multiply(1 - j / binFillParams[1], Y2))
                const X = subtract(x2, x1)

                for (let k = 1; k <= binFillParams[0]; k++) {
                    const point = add(x1, multiply(1 - k / binFillParams[0], X))
                    const avDepth = getAverageDepth(
                        cameraToPicture(point, status, normal),
                        depth,
                        3,
                        [width, height]
                    )
                    if (avDepth !== 'err') {
                        arr.push([
                            ...point,
                            cameraToRobot(point, calibration)[2],
                            avDepth,
                        ])
                    }
                }
            }
        }
        setBinInPicture(newBin)
        setBinInCamera(binInCamera)
        setBinFillPoints(arr)
    }

    useEffect(() => {
        if (userBinCreation && polygon.indexOf(null) === -1) {
            userBinHandler()
        }
    }, [polygon, userBinParams, userBinCreation])

    useEffect(() => {
        boxParams && setBoxBottom()
    }, [offset, boxParams, mode])

    useEffect(() => {
        bin && binHandler()
    }, [bin])

    useEffect(() => {
        if (intersectionPoints) {
            setSecond(
                intersectionPoints.map((point) =>
                    cameraToPicture(point, status, normal, userDepth)
                )
            )
        }
    }, [userDepth])

    useEffect(() => {
        const canvas = canvRef.current
        canvas.width = width
        canvas.height = height
        canvas.addEventListener('mouseup', mouseUpHandler, false)
        canvas.addEventListener('contextmenu', mouseDownHandler, false)
        canvas.addEventListener('wheel', scrollHandler, false)
        window.onkeypress = keyPressHandler
        redraw()
        return () => {
            canvas.removeEventListener('mouseup', mouseUpHandler, false)
            canvas.removeEventListener('contextmenu', mouseDownHandler, false)
            canvas.removeEventListener('wheel', scrollHandler, false)
        }
    }, [
        depth,
        image,
        userBinCreation,
        polygon,
        second,
        userDepth,
        binFillPoints,
        pictureUserBin,
        boxParams,
        offset,
        mode,
        boxInBin,
        offsetStep,
        width,
        useGrabPoint,
        firstGrabPoint,
        height,
        bin,
    ])

    return (
        <div className="box-marker-stream-container">
            <canvas ref={canvRef} />
            <div className="marker-bottom">
                <h4>{firstGrabPoint && JSON.stringify(firstGrabPoint)}</h4>
                <Button
                    className="marker-button"
                    onClick={handleCommand}
                    disabled={!finalBox}
                >
                    Send command
                </Button>
                <div className="marker-toggle-container">
                    <p>Use grab point</p>

                    <Toggle
                        className={useGrabPoint ? 'marker-toggle-active' : ''}
                        checked={useGrabPoint}
                        onChange={() => {
                            setUseGrabPoint(!useGrabPoint)
                        }}
                    />
                </div>
                {!userBinCreation && (
                    <div className="user-bin-inputs" style={{ marginLeft: 10 }}>
                        Height:
                        <Input
                            className="marker-message-input"
                            style={{ marginRight: 10, marginLeft: 10 }}
                            placeholder="Bin top"
                            value={userDepth}
                            onChange={(value) => {
                                !isNaN(value) &&
                                    value > 0 &&
                                    setUserDepth(value)
                            }}
                        />
                    </div>
                )}

                {!userBin ? (
                    <Button
                        className="marker-button"
                        onClick={() => {
                            dispatch(setUserBinCreation(!userBinCreation))
                            clearStates()
                            redraw()
                        }}
                    >
                        {userBinCreation
                            ? 'Cancel bin creation'
                            : 'Create new bin'}
                    </Button>
                ) : (
                    <Button
                        className="marker-button"
                        onClick={() => {
                            dispatch(setUserBin(null))
                            clearStates()
                            redraw()
                        }}
                    >
                        Delete created bin
                    </Button>
                )}

                {userBinCreation && (
                    <>
                        <div className="user-bin-inputs">
                            Top:
                            <Input
                                className="marker-message-input"
                                style={{ marginRight: 10 }}
                                placeholder="Bin top"
                                value={userBinParams.top}
                                onChange={(value) => {
                                    !isNaN(value) &&
                                        dispatch(
                                            setUserBinParams({
                                                ...userBinParams,
                                                top: value,
                                            })
                                        )
                                }}
                            />
                            Bottom:
                            <Input
                                className="marker-message-input"
                                placeholder="Bin bottom"
                                value={userBinParams.bottom}
                                onChange={(value) => {
                                    !isNaN(value) &&
                                        dispatch(
                                            setUserBinParams({
                                                ...userBinParams,
                                                bottom: value,
                                            })
                                        )
                                }}
                            />
                        </div>
                        <Button
                            disabled={!pictureUserBin}
                            className="marker-button"
                            onClick={() => {
                                handleUserBinAccept()
                                clearStates()
                                dispatch(setUserBinCreation(false))
                                dispatch(
                                    setUserBinParams({ top: 0, bottom: 0 })
                                )
                            }}
                        >
                            Accept Bin
                        </Button>
                    </>
                )}
                {!userBinCreation && (
                    <div className="marker-res_output">{markerRes.text}</div>
                )}
            </div>
            <div className="marker-legend">
                <h4>Box info</h4>
                <p>{`- Box height: ${userDepth}`}</p>
                <p>{`- Position Height: ${
                    finalRobotBox ? finalRobotBox[3][2] - bin[0][2] : 'null'
                }`}</p>
            </div>
            {debugInfo && (
                <div className="marker-legend">
                    <h4>Stream Info</h4>
                    <p>{`FPS: ${debugInfo.fps.toFixed(
                        1
                    )} | Average frame delay: ${debugInfo.avDelay.toFixed(
                        3
                    )} | Max frame delay: ${debugInfo.maxDelay.toFixed(3)}`}</p>
                </div>
            )}
            <div className="marker-legend">
                <h4>Legend</h4>
                <h5>Markup initial box position</h5>
                <p>- Left click to point 4 top corners of the box</p>
                <p>- Scroll mousewheel to determine box height</p>
                <p>- Press "wasd" or "space"</p>
                <h5>Determine final box position inside the bin</h5>
                <p>- "WASD" keys to move around</p>
                <p>- "Q" or "Rotate box" to rotate</p>
                <p>
                    - red rectangle on the bottom of the bin means it does not
                    fit inside
                </p>
                <p>
                    - When final blue box exists and in desired position press
                    "space"
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {
        userBinCreation,
        markerRes,
        userBinParams,
        userBin,
        markerRobotConfig,
        markerTask,
    } = state.marker

    const { calibration, push_down } = markerRobotConfig
    let invCalibration = calibration.map((el) => el.slice(0, 3))
    invCalibration = multiply(
        1 / det(invCalibration),
        transpose(invCalibration)
    )
    const normal = multiply(invCalibration, [0, 0, -1])

    let bin = null
    if (markerTask && markerTask.task) {
        bin = markerTask.task.bin
    }
    return {
        bin: userBin ? userBin : bin ? bin : null,
        markerRes,
        userBinCreation,
        userBinParams,
        userBin,
        robotName: state.marker.markerRobot,
        calibration,
        pushdown: push_down ? push_down : 1,
        normal,
    }
}

export default connect(mapStateToProps)(Stream)
