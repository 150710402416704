const initialState = {
    retrainingSessions: [],
    createIsOpen: false,
    addedDataSessions: [],
    sessionConfig: {
        learning_rate: 0.001,
        max_iterations: 16000,
        batch_size: 16,
        weight: 'zero',
        name: '',
    },
    currentSession: null,
    editSessionId: null,
}

export default function RetrainingSessionReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case 'SET_RETREAINING_SESSION_TO_EDIT':
            return {
                ...state,
                editSessionId: payload.id,
                sessionConfig: payload.sessionConfig,
                addedDataSessions: payload.respSessionList,
                createIsOpen: true,
            }
        case 'GET_RETRAINING_SESSION_BY_ID':
            return {
                ...state,
                currentSession: payload,
            }
        case 'RESET_CREATION':
            return initialState

        case 'EDIT_SESSION_CONFIG':
            let newConfig = { ...state.sessionConfig }
            newConfig[payload.field] = payload.value
            return {
                ...state,
                sessionConfig: newConfig,
            }
        case 'EDIT_DATA_SESSION_WEIGHT':
            const { addedDataSessions: old } = state
            const idx = old.findIndex((el) => el.id === payload.id)

            return {
                ...state,
                addedDataSessions: [
                    ...old.slice(0, idx),
                    { ...old[idx], weight: payload.weight },
                    ...old.slice(idx + 1),
                ],
            }
        case 'SET_ADDED_DATA_SESSIONS':
            return {
                ...state,
                addedDataSessions: payload,
            }
        case 'SET_CREATE_SESSION_OPEN':
            return {
                ...state,
                createIsOpen: payload,
            }
        case 'GET_RETRAINING_SESSIONS':
            return {
                ...state,
                retrainingSessions: payload,
            }
        default:
            return state
    }
}
