export const endpoints = {
    login: '/login',
    refresh: '/refresh',
    deleteUser: (id) => '/delete?user_id=' + id,
    updateUser: (id) => '/update?user_id=' + id,
    user: '/user',
    register: '/register',
    updatePassword: '/update_password',
    web_api: {
        taskToRobot: (task, robot) =>
            '/web_api/task_to_robot/' + robot + '/' + task,
    },
    stream: {
        archiveStream: '/stream/archive_stream',
        robotArchiveStream: '/stream/robot_archive_streams',
        listArchiveStream: '/stream/list_archive_stream',
    },
    api: {
        users: '/api/users',
        config: '/api/config',
        subscriptionRobot: '/api/subscription_robot',
        currentUser: '/api/current_user',
        processors: '/api/processors',
        wsConnections: '/api/websocket_connections',
        robots: '/api/robots',
        extractSensors: (robot) => '/api/extract/sensors/' + robot,
        tasks: '/api/tasks',
        task: '/api/task',
    },
    sessions: {
        commandJson: '/sessions/command_json',
        imageFile: (path, sessionID) =>
            '/sessions/img_file?file=' +
            path +
            (sessionID ? '&session_id=' + sessionID : ''),
        getListCommand: '/sessions/get_list_command',
        nnWeight: '/sessions/nn_weight',
        sessionData: (id = null) =>
            '/sessions/sessions_data' + (id ? '/' + id : ''),
        getRobotInArchiveCommand: '/sessions/get_robot_in_archive_command',
        listArchiveFiles: (params) => '/sessions/list_archive_files' + params,
        sessionsTrain: (id = null) =>
            '/sessions/sessions_train' + (id ? '/' + id : ''),
    },
}
