import { Modal, Button } from 'rsuite'

const DeleteSession = ({ showWarning, setShowWarning, handleDelete }) => {
    return (
        <Modal show={showWarning} onHide={() => setShowWarning(null)}>
            <Modal.Header>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>This data session is used in the training session</h6>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="red-button"
                    onClick={() => handleDelete(true, showWarning)}
                >
                    Delete
                </Button>
                <Button
                    onClick={() => {
                        setShowWarning(null)
                    }}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteSession
